import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "@shared/v2/Modal";
import { PhoneSolidV6 } from "@shared/v2/Icomoon";
import BaseEvent from "./BaseEvent";

const DirectToTimelineEvent = ({ event }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={showModal}
      onKeyDown={(e) => {
        if (["Enter", " "].includes(e.key)) {
          e.preventDefault();
          showModal();
        }
      }}
    >
      <BaseEvent
        icon={<PhoneSolidV6 />}
        title={
          <div>
            <span className="tw-font-semibold">Event:</span> Callingly Call
          </div>
        }
        interactedAt={event.interacted_at_absolute}
      >
        <div className="timeline-description-text">
          <div className="details-row">
            <span>{event.timeline_fields.event}</span>
          </div>
        </div>
      </BaseEvent>
      <Modal
        show={isModalOpen}
        onHide={hideModal}
        dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
        contentClassName="tw-max-w-[700px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
      >
        <div className="timeline-event timeline-event-modal">
          <Modal.Header title="Profile Updated" closeButton />
          <Modal.Body>
            <div className="bold-detail details-row details-header">Details:</div>
            <div>
              <div className="details-row">
                <span>{event.timeline_fields.event}</span>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

DirectToTimelineEvent.propTypes = {
  event: PropTypes.shape().isRequired,
};

export default DirectToTimelineEvent;
