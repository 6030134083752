import React from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import colors from "./colors.module.css";
import css from "./person-detail-styles.module.css";
import ViewWebActivityModal from "./modals/ViewWebActivityModal";
import { valueOrZero, lastVisit, visits } from "../../shared/WebActivityHelpers";
import * as actions from "../actions/personDetailActionCreators";
import Loading from "../../Goals/components/Loader";
import CollapsibleElement from "./CollapsibleElement";

const WebActivity = ({ openPlatformCTA }) => {
  const dispatch = useDispatch();
  const featureFlags = useSelector((state) => state.layout.featureFlags);
  const person = useSelector((state) => state.personDetail.person);
  const webActivityModalOpen = useSelector((state) => state.personDetail.webActivityModalOpen);

  const webActivityButtonText = featureFlags.platformAccount ? "VIEW" : "LEARN MORE";
  const webActivityButtonClick = featureFlags.platformAccount
    ? () => dispatch(actions.openWebActivityModal())
    : () => openPlatformCTA;

  const openWebActivityModal = () => dispatch(actions.openWebActivityModal());
  const closeWebActivityModal = () => dispatch(actions.closeWebActivityModal());

  const noActivity = (
    { idx_visits, idx_last_visit, idx_property_views, idx_favorites, idx_average_price } = {
      idx_visits: null,
      idx_last_visit: null,
      idx_property_views: null,
      idx_favorites: null,
      idx_average_price: null,
    },
  ) => {
    return (
      idx_visits === null &&
      idx_last_visit === null &&
      idx_property_views === null &&
      idx_favorites === null &&
      idx_average_price === null
    );
  };

  const formatPrice = (price) => {
    if (price === null) {
      return 0;
    }

    return (
      "$" +
      (price > 1000000 ? parseFloat(price / 1000000).toFixed(2) : parseInt(Math.floor(price / 1000))) +
      (price > 1000000 ? "M" : "K")
    );
  };

  const renderCTA = () => (
    <Row>
      <Col xs={12}>
        <div className={css.personDetailEmptyStateText}>
          See when a contact visits your Brivity website and which homes they view.
        </div>
      </Col>
    </Row>
  );

  const renderWebActivity = ({
    idx_visits,
    idx_last_visit,
    idx_property_views,
    idx_favorites,
    idx_average_price,
  }) => (
    <div id="web-activity">
      <Row style={{ marginBottom: "18px", paddingLeft: "10px", paddingRight: "10px" }}>
        <Col xs={12} style={{ fontSize: "10px" }}>
          <div className="pull-left">
            <span className={css.brivityPersonDetailsLabel}>VISITS:</span>
            <strong className={css.inlineLabelValue} style={{ fontSize: "12px" }}>
              {visits(idx_visits)}
            </strong>
          </div>
          <div className="pull-right">
            <span className={css.brivityPersonDetailsLabel}>LAST VISIT:</span>
            <strong className={css.inlineLabelValue} style={{ fontSize: "12px" }}>
              {lastVisit(idx_last_visit)}
            </strong>
          </div>
        </Col>
      </Row>
      <div className={css.materialDesignBox}>
        <Row style={{ marginBottom: "8px" }}>
          <Col xs={12} style={{ fontSize: "10px", display: "inline-flex", justifyContent: "space-between" }}>
            <strong className={css.brivityPersonDetailsLabel}>VIEWS</strong>
            <strong className={css.brivityPersonDetailsLabel}>FAVORITES</strong>
            <strong className={css.brivityPersonDetailsLabel}>AVG. PRICE</strong>
          </Col>
        </Row>
        <Row>
          <Col className={`${colors.brivityBlue} links`} xs={12}>
            <a className={`${colors.brivityBlue} visits text-center`} onClick={openWebActivityModal}>
              <span>
                <strong>{valueOrZero(idx_property_views)}</strong>
              </span>
            </a>
            <a className={`${colors.brivityBlue} favorites text-center`} onClick={openWebActivityModal}>
              <span>
                <strong>{valueOrZero(idx_favorites)}</strong>
              </span>
            </a>
            <a className={`${colors.brivityBlue} price text-center`} onClick={openWebActivityModal}>
              <span>
                <strong>{formatPrice(idx_average_price)}</strong>
              </span>
            </a>
          </Col>
        </Row>
      </div>
    </div>
  );

  const personAttributes = person.data?.attributes;

  return (
    <CollapsibleElement
      id="pdp-web-activity"
      title="Web Activity"
      buttonText={webActivityButtonText}
      buttonClickFunction={webActivityButtonClick}
    >
      {!person.data && <Loading />}
      <div id="person-web-activity-card">
        {noActivity(personAttributes) ? renderCTA() : renderWebActivity(personAttributes)}
        {webActivityModalOpen && (
          <ViewWebActivityModal
            closeWebActivityModal={closeWebActivityModal}
            uuid={personAttributes?.uuid}
            visits={visits(personAttributes?.idx_visits)}
            lastVisit={lastVisit(personAttributes?.idx_last_visit)}
            views={valueOrZero(personAttributes?.idx_property_views)}
            isFavorite={false}
          />
        )}
      </div>
    </CollapsibleElement>
  );
};

export default WebActivity;
