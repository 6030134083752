import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import { filter } from "lodash";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";

import { LABEL_VALUE_STATES } from "@shared/constants";
import DropdownWithMultiCtrlledRemovable from "@shared/DropdownWithMultiCtrlledRemovable";

const FilterStatesModal = ({ show, onHide, onSubmit, filteredStates }) => {
  const useSpecificStatesAsOptions = useMemo(
    () =>
      filter(
        LABEL_VALUE_STATES,
        (state) => !["American Samoa", "Nevada", "New York", "Utah"].includes(state.value),
      ),
    [],
  );

  const [modalfilteredStates, setModalfilteredStates] = useState(filteredStates);

  return (
    <Modal
      show={show}
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-w-[616px]"
      onHide={onHide}
      closeButton
    >
      <Modal.Header title="Filter Lenders" className="tw-mb-32px" closeButton />
      <Modal.Body className="tw-min-h-[112px]">
        <DropdownWithMultiCtrlledRemovable
          options={useSpecificStatesAsOptions}
          label="Licensed in"
          placeHolderTxt=" states selected"
          isRequired
          dropdownMultiValueClassName="!tw-text-[11d] !tw-font-bold"
          placeholderNoSelected="Select states"
          selectedOptions={modalfilteredStates}
          onChange={setModalfilteredStates}
        />
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-flex-row tw-justify-between tw-mt-[32px]">
        <Button size="medium" schema="tertiary" onClick={onHide}>
          Cancel
        </Button>
        <Button
          size="medium"
          schema="primary"
          onClick={() => {
            onSubmit(modalfilteredStates);
            onHide();
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

FilterStatesModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  filteredStates: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default FilterStatesModal;
