import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { arrayOf, bool, string } from "prop-types";
import { useEventListenerAction } from "@shared/hooks/useEventListenerAction";
import DropdownWithMultiCtrlledRemovable from "@shared/DropdownWithMultiCtrlledRemovable";
import { CLEAR_MODAL_FILTER_ID } from "../../shared/constants";

const AutoPlanNameSelect = ({ isDisabled, previousSelectedPlanIds }) => {
  const [autoPlans, setAutoPlans] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);

  const options = useMemo(
    () => autoPlans.map(({ name, id }) => ({ label: name, value: `${id}` })),
    [autoPlans],
  );

  useEventListenerAction(CLEAR_MODAL_FILTER_ID, () => setSelectedNames([]));

  useEffect(() => {
    if (!isDisabled) {
      axios
        .get("/auto_plans/auto_plans_no_actions.json?order=asc")
        .then((results) => {
          let temphashOptions = {};
          results.data.forEach(({ id, name }) => {
            setAutoPlans((aps) => [...aps, { id, name }]);

            temphashOptions = { ...temphashOptions, [id]: name };
          });

          setSelectedNames(
            previousSelectedPlanIds?.map((id) => ({ label: temphashOptions[id], value: id })) || [],
          );
        })
        .catch(console.log);
    }
  }, [isDisabled, previousSelectedPlanIds]);

  return (
    <div>
      {autoPlans.length > 0 && (
        <DropdownWithMultiCtrlledRemovable
          label="Auto Plan Name"
          options={options}
          selectedOptions={selectedNames}
          removablePrefix=""
          placeHolderTxt=" auto plans selected"
          onChange={(option) => setSelectedNames(option)}
          ctrlName="leads_filter[selected_auto_plan_name_filters][ids][]"
          id="auto_plan_name_filter"
        />
      )}
      {selectedNames.map((option) => (
        <input
          type="hidden"
          name="leads_filter[selected_auto_plan_name_filters][names][]"
          value={option.label}
          key={option.value}
        />
      ))}
      <hr />
    </div>
  );
};

export default AutoPlanNameSelect;

AutoPlanNameSelect.propTypes = {
  isDisabled: bool,
  previousSelectedPlanIds: arrayOf(string),
};

AutoPlanNameSelect.defaultProps = {
  isDisabled: false,
  previousSelectedPlanIds: [],
};
