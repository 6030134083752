import React, { createContext, forwardRef, useCallback, useContext, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingList,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";
import { createPortal } from "react-dom";
import { Transition } from "react-transition-group";
import Checkbox from "../Checkbox";

const div = document.createElement("div");
div.id = "popover-container";
document.body.appendChild(div);

const usePopover = ({
  initialOpen = false,
  placement = "bottom",
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  offset: offsetProp = 5,
  maxHeight,
} = {}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const [labelId, setLabelId] = useState();
  const [descriptionId, setDescriptionId] = useState();
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(offsetProp),
      flip({
        crossAxis: placement.includes("-"),
        fallbackAxisSideDirection: "end",
        padding: 5,
      }),
      size({
        apply({ availableHeight, elements }) {
          // eslint-disable-next-line no-param-reassign
          elements.floating.style.maxHeight = `${maxHeight && maxHeight < availableHeight ? maxHeight : availableHeight}px`;
        },
      }),
      shift({ padding: 5 }),
    ],
  });

  const elementsRef = useRef([]);

  const handleSelect = useCallback(() => {
    setSelectedIndex(null);
    setOpen(false);
  }, []);

  const listNav = useListNavigation(data.context, {
    listRef: elementsRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
  });

  const click = useClick(data.context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(data.context);
  const role = useRole(data.context);

  const interactions = useInteractions([listNav, click, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
      activeIndex,
      selectedIndex,
      handleSelect,
      elementsRef,
    }),
    [open, setOpen, interactions, data, labelId, descriptionId],
  );
};

const PopoverContext = createContext();

const usePopoverContext = () => {
  const context = useContext(PopoverContext);

  if (context == null) {
    throw new Error("Popover components must be wrapped in <Popover />");
  }

  return context;
};

const PopoverTrigger = React.forwardRef(({ children, ...props }, propRef) => {
  const { getReferenceProps, open, refs } = usePopoverContext();
  const ref = useMergeRefs([refs.setReference, propRef, children.ref]);

  return React.cloneElement(
    children,
    getReferenceProps({
      ref,
      ...props,
      ...children.props,
      "data-state": open ? "open" : "closed",
    }),
  );
});

PopoverTrigger.propTypes = {
  children: PropTypes.node.isRequired,
};

const PopoverContent = forwardRef(({ className, style, ...props }, propRef) => {
  const transitionRef = useRef(null);
  const { context, descriptionId, elementsRef, floatingStyles, getFloatingProps, labelId } =
    usePopoverContext();
  const popoverRef = useMergeRefs([context.refs.setFloating, propRef]);

  return createPortal(
    <Transition in={context.open} nodeRef={transitionRef} timeout={200} unmountOnExit>
      {(transitionState) => (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={(ref) => {
              transitionRef.current = ref;
              popoverRef(ref);
            }}
            className={`tw-z-1000 tw-bg-white tw-shadow-dropdown tw-overflow-auto tw-flex tw-flex-col tw-rounded-[4px] tw-transition-opacity tw-duration-200 ${["entering", "entered"].includes(transitionState) ? "tw-opacity-100" : "tw-opacity-0"} ${className}`}
            style={{ ...floatingStyles, ...style }}
            aria-labelledby={labelId}
            aria-describedby={descriptionId}
            {...getFloatingProps(props)}
          >
            <FloatingList elementsRef={elementsRef}>{props.children}</FloatingList>
          </div>
        </FloatingFocusManager>
      )}
    </Transition>,
    document.querySelector("#popover-container"),
  );
});

PopoverContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

PopoverContent.defaultProps = {
  className: "",
  style: {},
};

const PopoverItem = ({ checkboxProps, children, className, icon, onClick, ...props }) => {
  const { activeIndex, selectedIndex, handleSelect } = usePopoverContext();
  const { ref, index } = useListItem();

  const isActive = activeIndex === index;
  const isSelected = selectedIndex === index;

  const content = (
    <div className="tw-flex tw-justify-start tw-items-center tw-gap-[8px]">
      {icon}
      {children || <span />}
    </div>
  );
  return (
    <button
      ref={ref}
      type="button"
      role="option"
      aria-selected={isActive && isSelected}
      tabIndex={isActive ? 0 : -1}
      className={`tw-flex-none tw-border-none tw-outline-none tw-bg-white tw-overflow-hidden hover:tw-bg-gray-5 focus:tw-bg-gray-5 active:tw-bg-gray-10 tw-text-left tw-h-[32px] tw-py-[4px] tw-px-[12px] disabled:tw-text-neutral-gray-30 ${className}`}
      onClick={(e) => {
        onClick?.(e);
        handleSelect(index);
      }}
      {...props}
    >
      {checkboxProps ? (
        <Checkbox
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...checkboxProps}
          label={content}
          labelClassName="tw-pointer-events-none"
        />
      ) : (
        content
      )}
    </button>
  );
};

PopoverItem.propTypes = {
  checkboxProps: PropTypes.shape(),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
};

PopoverItem.defaultProps = {
  checkboxProps: null,
  className: "",
  icon: null,
  onClick: undefined,
};

const Popover = ({ children, ...popoverOptions }) => {
  const popover = usePopover(popoverOptions);
  return <PopoverContext.Provider value={popover}>{children}</PopoverContext.Provider>;
};

Popover.Trigger = PopoverTrigger;
Popover.Content = PopoverContent;
Popover.Item = PopoverItem;

Popover.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Popover, PopoverTrigger, PopoverContent, PopoverItem, usePopoverContext };
