import React, { useState } from "react";
import PropTypes from "prop-types";
import { Popover, PopoverContent, PopoverItem, PopoverTrigger } from "@shared/v2/Popover/Popover";
import Button from "@shared/v2/Button";
import { ArrowUpRightFromSquareRegularV6 } from "@shared/v2/Icomoon/Icons";
import Tooltip from "@shared/v2/Tooltip";

const FinancingStagesDropDown = ({
  financingStagesMapping,
  selectedStage,
  containerClassName,
  isLoading,
}) => {
  const [showStagePicker, setShowStagePicker] = useState(false);
  const selectedTitle = financingStagesMapping.find((obj) => obj?.value === selectedStage)?.label || "";

  const handleClick = () => {
    window.open(
      "https://knowledge.brivity.com/en/articles/5527189-email-reputation-deliverability",
      "_blank",
    );
  };

  return (
    <div className="tw-relative tw-flex tw-justify-start">
      <Popover
        open={showStagePicker}
        onOpenChange={setShowStagePicker}
        maxHeight={320}
        placement="bottom-start"
      >
        <PopoverTrigger>
          <Tooltip
            placement="right"
            trigger={
              <Button
                data-cy="financing-stage-button"
                schema="tertiary"
                isLoading={isLoading}
                onClick={() => setShowStagePicker(!showStagePicker)}
                className={`!tw-p-0 tw-border-none tw-flex tw-justify-start tw-flex-row tw-flex-nowrap tw-grow tw-shrink-0 tw-items-center
            tw-uppercase tw-text-center tw-align-middle tw-cursor-pointer tw-h-[24px] tw-text-[#666] tw-text-11px tw-leading-[16px]
            tw-font-bold hover:!tw-bg-transparent hover:tw-border-transparent !tw-bg-transparent tw-border-transparent ${containerClassName}`}
              >
                {selectedTitle}
                <i
                  className={`fa fa-chevron-down tw-ml-[4px] tw-text-11d tw-transition-transform ${
                    showStagePicker && "fa-rotate-180"
                  }`}
                />
              </Button>
            }
            content="View stages"
          />
        </PopoverTrigger>
        <PopoverContent className="tw-z-[3000]">
          {financingStagesMapping.map((financingStageOption) => (
            <PopoverItem
              className={`!tw-cursor-default ${selectedStage === financingStageOption.value ? "!tw-bg-gray-10" : "hover:!tw-bg-white"}`}
              key={financingStageOption.value}
            >
              <span className="tw-w-full tw-text-center tw-text-12d tw-font-semibold">
                {financingStageOption?.label}
              </span>
            </PopoverItem>
          ))}
          <PopoverItem
            className="tw-text-theme-primary tw-border-[1px] !tw-border-solid tw-border-x-0 tw-border-b-0 tw-border-gray-10"
            onClick={handleClick}
          >
            <span className="tw-w-full tw-flex tw-justify-center tw-items-center tw-gap-[4px]">
              Status Info
              <ArrowUpRightFromSquareRegularV6 size="m" />
            </span>
          </PopoverItem>
        </PopoverContent>
      </Popover>
    </div>
  );
};

FinancingStagesDropDown.propTypes = {
  financingStagesMapping: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ).isRequired,
  selectedStage: PropTypes.string,
  containerClassName: PropTypes.string,
  isLoading: PropTypes.bool,
};

FinancingStagesDropDown.defaultProps = {
  selectedStage: "",
  containerClassName: "",
  isLoading: false,
};

export default FinancingStagesDropDown;
