import { useEffect } from "react";
import { isEqual, filter } from "lodash";
import { useDebounce as useDebounceFn } from "../shared/hookHelpers";

export const getFirstLettersOfString = (letterString = "", partCount = 2) => {
  const includeSpaces = false;
  // break the string to an array on spaces
  const parts = letterString.split(" ");
  // determine the max number of letters to return
  const maxParts = partCount > parts.length ? parts.length : partCount;

  // set the return string
  let retString = "";
  if (maxParts > 0) {
    // loop through the avail. parts
    let loopIdx = 0;
    for (loopIdx; loopIdx < maxParts; loopIdx += 1) {
      // add the first letter of the string part to the retrun string
      retString += parts[loopIdx][0] + (includeSpaces ? " " : "");
    }
    // remove trailing spaces
    retString.trim();
  }
  return retString.toUpperCase();
};

export const useDebounce = useDebounceFn;

export const useSetViewWrapper = () =>
  useEffect(() => {
    // get the view wrapper
    const el = document.getElementsByClassName("content-wrapper")[0];
    const cname = "fp-reset-wrap";
    if (el) {
      // add custom view class
      el.classList.add(cname);
      return () => {
        // remove custom view class
        el.classList.remove(cname);
      };
    }
    return () => {};
  }, []);

export const useLoadImage = (src, setFn) =>
  useEffect(() => {
    if (!src) {
      return;
    }
    // Load the passed in image src
    const img = document.createElement("img");
    img.onload = () => {
      setFn(src);
    };
    img.onerror = () => {
      console.log("can not load image", src);
    };
    img.src = src;
  }, [src]);

export const useToggleUsers = (users, setFn) => (agent) => {
  // check if it exists
  const existing = users ? [...users] : [];
  const fnd = filter(existing, (u) => u.uuid === agent.uuid);
  if (fnd.length === 0) {
    // add it if not found
    setFn([...existing, agent]);
  } else {
    // remove the user
    const removed = filter(existing, (u) => u.uuid !== agent.uuid);
    setFn(removed);
  }
};

export const useCanSaveModel = (orig, model, hasDuplicate) => {
  const isDirty = !isEqual(orig, model);
  // Flag if the model is complete enough to save
  return (
    model.name > "" &&
    !hasDuplicate &&
    model.accessible_by_users &&
    model.accessible_by_users.length > 0 &&
    model.display_agent &&
    isDirty
  );
};

export const useSetModel = (model, setModel) =>
  useEffect(() => {
    setModel(model ? { ...model } : {});
  }, [model]);

export const useSetUserOptions = (collection, setFn) =>
  useEffect(() => {
    if (collection && collection.length) {
      setFn(
        collection.map((u) => ({
          label: u.name,
          value: { ...u },
        })),
      );
    }
  }, [collection]);

export const useDuplicateCheck = (term, checkFn) =>
  useEffect(() => {
    checkFn(term);
  }, [term]);

export const useInitUsers = (loadFn) =>
  useEffect(() => {
    loadFn();
  }, []);

// Change the model based on key - removeKey param will delete the objects key/val pair
export const useSetValue =
  (setFn) =>
  (key, val, removeKey = false) => {
    setFn((p) => {
      // eslint-disable-next-line no-param-reassign
      p[key] = val;
      if (removeKey) {
        // eslint-disable-next-line no-param-reassign
        delete p[key];
      }
      return { ...p };
    });
  };
