import React from "react";
import PropTypes from "prop-types";

import ErrorBanner, { errorShapeForErrorBanner } from "../ErrorBanner";
import IconButton from "../IconButton";

import { Close as CloseIcon } from "../Icomoon";

import { ModalContext } from "./ModalContext";

import {
  SR_ONLY_CLASSES,
  MODAL_TITLE_CLASSES,
  MODAL_DESCRIPTION_CLASSES,
  availableHeaderSizes,
} from "./Modal.styles";

export const CloseButton = ({ onClick }) => (
  <IconButton onClick={onClick} aria-label="Close" schema="tertiary" data-cy="modal-close-button">
    <CloseIcon />
    <span className={SR_ONLY_CLASSES}>Close</span>
  </IconButton>
);
CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const ModalHeader = ({
  title,
  description,
  errors,
  closeButton,
  className,
  titleClassName,
  wrapperClassName,
  children,
  size,
  ...props
}) => {
  const { onHide } = React.useContext(ModalContext);

  return (
    // Arbitrary props might be passed to header
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={`${className} tw-space-y-16px`} data-cy="modal-header" {...props}>
      <div
        className={`tw-flex tw-justify-between tw-align-top tw-gap-[16px] tw-max-w-full ${wrapperClassName}`}
      >
        {title ? (
          <h1 className={`${MODAL_TITLE_CLASSES[size]} ${titleClassName}`} data-cy="modal-header-title">
            {title}
          </h1>
        ) : (
          <span />
        )}
        <div className="tw-relative">{closeButton && <CloseButton onClick={onHide} />}</div>
      </div>
      {description && <div className={MODAL_DESCRIPTION_CLASSES[size]}>{description}</div>}
      <ErrorBanner errors={errors} />
      {children}
    </div>
  );
};

ModalHeader.propTypes = {
  closeButton: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  wrapperClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
  errors: PropTypes.arrayOf(errorShapeForErrorBanner),
  size: PropTypes.oneOf(availableHeaderSizes),
};

ModalHeader.defaultProps = {
  closeButton: false,
  className: "",
  children: null,
  title: null,
  titleClassName: "",
  wrapperClassName: "",
  description: null,
  errors: [],
  size: "medium",
};

ModalHeader.CloseButton = CloseButton;

export default ModalHeader;
