import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CallStatusBar from "@shared/CallStatusBar";
import CALL_STATES from "../utils/states";
import { useOutboundCallRecordingSetting } from "../../reducers/layoutReducer/selectors";
import { startRecording, stopRecording, setDuration } from "../../reducers/dialerReducer/dialerReducer";
import { useCallState, useConference, useIsRecording } from "../../reducers/dialerReducer/selectors";

export const getTimeString = (time) => {
  const hours = `${Math.floor(time / 3600)}`;
  const minutes = `${Math.floor((time % 3600) / 60)}`;
  const seconds = `${Math.floor((time % 3600) % 60)}`;

  return `${hours}:${minutes.length === 1 ? "0" : ""}${minutes}:${seconds.length === 1 ? "0" : ""}${seconds}`;
};

const CallBar = () => {
  const dispatch = useDispatch();
  const callState = useCallState();
  const isRecording = useIsRecording();
  const conference = useConference();
  const { outboundRecording } = useOutboundCallRecordingSetting();
  const [time, setTime] = useState(0);
  const clickStartRecording = (recordingConsent) => {
    if (!isRecording) dispatch(startRecording(recordingConsent));
  };
  useEffect(() => {
    if (callState === CALL_STATES.Connected) {
      if (outboundRecording === "automatic") {
        clickStartRecording(false);
      }

      const interval = setInterval(() => {
        setTime((t) => t + 1);
      }, 1000);
      return () => clearInterval(interval);
    }
    if (callState === CALL_STATES.Ended) {
      dispatch(setDuration(time));
    }
    if (callState === CALL_STATES.Started) {
      dispatch(setDuration(null));
      setTime(0);
    }
    return () => {};
  }, [callState]);

  if (callState === CALL_STATES.Init) return null;

  const toggleRecording = () => {
    if (isRecording) {
      dispatch(stopRecording());
    } else {
      dispatch(startRecording(false));
    }
  };

  return (
    <CallStatusBar
      callStatus={conference?.currentParticipant?.humanizedCallStatus}
      currentCallTime={getTimeString(time)}
      isCompleted={callState === CALL_STATES.Ended}
      recorded={isRecording}
      onToggleChange={toggleRecording}
      agentPhoneNumber={conference?.agentParticipant?.phoneNumber}
      statusClassName="!tw-w-full"
    />
  );
};

export default CallBar;
