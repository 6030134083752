import React, { useState } from "react";
import PropTypes from "prop-types";
import { RightToBracketSolidV6 } from "@shared/v2/Icomoon";
import Modal from "@shared/v2/Modal";
import BaseEvent from "./BaseEvent";

const PublicActivityLoginEvent = ({ event }) => {
  const {
    object_attributes: { first_name: firstName, last_name: lastName },
    interacted_at_absolute: interactedAt,
  } = event;
  const [showModal, setShowModal] = useState(false);

  const headline = (
    <div>
      <span className="bold-detail">{`${firstName} ${lastName}`.trim()}</span>
      {" logged into Brivity"}
    </div>
  );

  return (
    <>
      <BaseEvent
        icon={<RightToBracketSolidV6 />}
        title={headline}
        onClick={() => setShowModal(true)}
        interactedAt={interactedAt}
      >
        <div className="timeline-description-text">Time: {interactedAt}</div>
      </BaseEvent>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
        contentClassName="tw-max-w-[480px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
      >
        <Modal.Header title="Login" closeButton />
        <Modal.Body>
          <div>{headline}</div>
          <div className="tw-font-semibold">Details:</div>
          <div>Time: {interactedAt}</div>
        </Modal.Body>
      </Modal>
    </>
  );
};

PublicActivityLoginEvent.propTypes = {
  event: PropTypes.shape().isRequired,
};

export default PublicActivityLoginEvent;
