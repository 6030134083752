import React from "react";
import PropTypes from "prop-types";
import format from "@shared/formatters";

const HomeInfoOverlay = ({ timelineFields }) => (
  <div className="tw-relative tw-flex tw-justify-center tw-items-center tw-w-[260px] tw-h-[150px] tw-rounded-[4px] tw-overflow-hidden">
    {(timelineFields.photo || timelineFields.photo_url) && (
      <img
        className="tw-w-full tw-h-full tw-object-cover"
        src={timelineFields.photo || timelineFields.photo_url}
        alt="Property"
      />
    )}
    <div className="tw-flex tw-flex-col tw-gap-[4x] tw-justify-end tw-absolute tw-inset-0 tw-bg-black/50 tw-p-[16px] tw-text-white">
      {(timelineFields.price || timelineFields.property_price) && (
        <div className="tw-text-18d">
          {format.currency(timelineFields.price || timelineFields.property_price, 0)}
        </div>
      )}
      <div>
        <div>{timelineFields.address || timelineFields.street_address}</div>
        <div>
          {timelineFields.city}, {timelineFields.state} {timelineFields.zip || timelineFields.postal_code}
        </div>
        <div>
          {typeof timelineFields.beds === "number" && <span>{timelineFields.beds} Bed</span>}{" "}
          {typeof timelineFields.baths === "number" && <span>{timelineFields.baths} Bath</span>}
        </div>
      </div>
    </div>
  </div>
);

HomeInfoOverlay.propTypes = {
  timelineFields: PropTypes.shape({
    address: PropTypes.string,
    baths: PropTypes.number,
    beds: PropTypes.number,
    city: PropTypes.string,
    photo: PropTypes.string,
    photo_url: PropTypes.string,
    postal_code: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    property_price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    state: PropTypes.string,
    street_address: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
};

export default HomeInfoOverlay;
