import moment from "moment-timezone";

const transformSms = (m) => ({
  id: m.id,
  messageType: m.interactionType,
  dateCreated: m.interactedAt,
  createdByAlias: `${m.senderFirstName} ${m.senderLastName}`,
  createdByAvatar: m.senderAvatar,
  sentBy: m.sentBy,
  deliveryStatus: m.deliveryStatus,
  message: m.comment,
  attachments: m.mmsMedia?.map(({ url }) => ({ fileUrl: url })),
});

const transformToTimelineSmsEvent = (conversation, message) => {
  const agentPerson = conversation.conversationUsers?.find((u) => u.userUuid === message.createdByUserUuid);
  const recipient = conversation.conversationUsers?.find((u) => u.userUuid !== message.createdByUserUuid);
  const [agentFirstName, agentLastName] = agentPerson?.alias?.split(" ") || ["", ""];
  const [recipientFirstName, recipientLastName] = recipient?.alias?.split(" ") || ["", ""];
  return {
    id: `public_activity_${message.uuid || message.id}`,
    interaction_type: "sms",
    agent_person: {
      first_name: agentFirstName,
      last_name: agentLastName,
      avatar: agentPerson?.avatar,
    },
    recipient: {
      first_name: recipientFirstName,
      last_name: recipientLastName,
      avatar: recipient?.avatar,
    },
    object_attributes: {
      interaction_type: message.smsOrGroupSmsOrBrivityTextMessageDirection === 1 ? "sms_out" : "sms_in",
      comment: message.message,
    },
    interacted_at_epoch: moment(message.dateCreated).unix(),
    interacted_at_absolute: moment(message.dateCreated)
      .tz("America/Los_Angeles")
      .format("MM/DD/YYYY h:mm A z"),
    mms_media: message.attachments?.map(({ fileAccessKey }) => ({
      url: fileAccessKey,
      content_type: "image/jpg",
    })),
  };
};

export { transformSms, transformToTimelineSmsEvent };
