import React from "react";
import PropTypes from "prop-types";
import MarketReportMenu from "./MarketReportMenu";
import { PencilSolidV6 } from "@shared/v2/Icomoon";

const INLINE_DETAIL = "tw-flex tw-overflow-ellipse tw-mb-[10px]";
const LABEL_CLASS =
  "tw-text-[12px] tw-text-neutral-gray-50 tw-font-semibold tw-w-[75px] tw-mr-[14px] tw-inline-block";
const VALUE_CLASS = "tw-text-[12px] tw-text-neutral-gray-75 tw-font-semibold tw-no-wrap tw-flex-1";

const MarketReport = ({
  id,
  recurring_frequency,
  report_name,
  preview_url,
  view_count,
  view_history,
  last_viewed,
  last_opened,
  created_at,
  editReportFn,
  editMarketReportPanel2,
  showAdjustment,
  has_address,
  deleteReportFn,
  home_value_adjustment,
  home_value_adjustment_as_percentage,
}) => (
  <div className="tw-shadow-card tw-p-[24px] tw-bg-white tw-rounded-[7px] tw-overflow-y-auto tw-h-[224px]">
    <div className="tw-flex tw-overflow-ellipsis tw-mb-[14px]">
      <a
        href={preview_url}
        target="_blank"
        className={`tw-text-[14px] tw-text-left tw-font-bold tw-text-neutral-gray-75 tw-truncate tw-flex-1 tw-border-0 tw-bg-transparent tw-p-0 tw-mr-[8px]`}
      >
        {report_name}
      </a>
      <MarketReportMenu
        previewUrl={preview_url}
        id={id}
        editReportFn={editReportFn}
        deleteReportFn={deleteReportFn}
      />
    </div>
    <div className={INLINE_DETAIL}>
      <span className={LABEL_CLASS}>Last Viewed</span>
      <span className={VALUE_CLASS}>{last_viewed}</span>
    </div>
    <div className={INLINE_DETAIL}>
      <span className={LABEL_CLASS}>Last Opened</span>
      <span className={VALUE_CLASS}>{last_opened}</span>
    </div>
    <div className={INLINE_DETAIL}>
      <span className={LABEL_CLASS}>Created</span>
      <span className={VALUE_CLASS}>{created_at}</span>
    </div>
    <div className={INLINE_DETAIL}>
      <span className={LABEL_CLASS}>Frequency</span>
      <span className={VALUE_CLASS}>{recurring_frequency}</span>
    </div>
    {has_address && showAdjustment && (
      <div className={INLINE_DETAIL}>
        <span className={LABEL_CLASS}>Adjust Est.</span>
        <span
          className={`${VALUE_CLASS} !tw-text-theme-primary tw-font-semibold tw-text-12d tw-cursor-pointer`}
          onClick={() => editMarketReportPanel2(id)}
        >
          {(() => {
            const homeValueAdj = home_value_adjustment * 1;
            if (home_value_adjustment === null || homeValueAdj === 0) {
              return (
                <div className="tw-flex tw-items-center tw-cursor-pointer">
                  <PencilSolidV6 size="s" className="tw-mr-4px" />
                  <span>Adjust</span>
                </div>
              );
            }

            const signValue = homeValueAdj > 0 ? "" : "-";

            return home_value_adjustment_as_percentage
              ? `${signValue} ${Math.floor(Math.abs(homeValueAdj))}%`
              : `${signValue}$ ${Math.floor(Math.abs(homeValueAdj))}`;
          })()}
        </span>
      </div>
    )}
    <div className={INLINE_DETAIL}>
      <span className={LABEL_CLASS}>View History</span>
      <span className={VALUE_CLASS}>{view_count} views</span>
    </div>
    {view_history && (
      <div className={INLINE_DETAIL}>
        <span className={LABEL_CLASS} />
        <span className="tw-no-wrap tw-flex-1">
          {view_history.map((history, index) => (
            <div key={index} className="tw-text-[12px] tw-text-neutral-gray-50 tw-mb-4px tw-lowercase">
              {history.replace("at", "")}
            </div>
          ))}
        </span>
      </div>
    )}
  </div>
);

MarketReport.propTypes = {
  id: PropTypes.string,
  recurring_frequency: PropTypes.string,
  report_name: PropTypes.string,
  preview_url: PropTypes.string,
  view_count: PropTypes.number,
  last_viewed: PropTypes.string,
  last_opened: PropTypes.string,
  created_at: PropTypes.string,
  view_history: PropTypes.arrayOf(PropTypes.shape()),
  editReportFn: PropTypes.func,
  editMarketReportPanel2: PropTypes.func,
  deleteReportFn: PropTypes.func,
  home_value_adjustment: PropTypes.string,
  home_value_adjustment_as_percentage: PropTypes.bool,
};
MarketReport.defaultProps = {
  id: null,
  recurring_frequency: "",
  report_name: "",
  preview_url: "",
  view_count: "",
  last_viewed: "",
  last_opened: "",
  created_at: "",
  view_history: null,
  editReportFn: null,
  editMarketReportPanel2: () => {},
  deleteReportFn: null,
  home_value_adjustment: null,
  home_value_adjustment_as_percentage: null,
};

export default MarketReport;
