import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Button from "@shared/v2/Button";
import IconButton from "@shared/v2/IconButton";
import Pagination from "@shared/v2/Pagination";
import TabBar from "@shared/v2/TabBar/TabBar";
import Tab from "@shared/v2/Tab/Tab";
import { PencilSolidV6, PlusSolidV6, XmarkSolidV6 } from "@shared/v2/Icomoon";
import { TBody, TD, TH, THead, TR, Table } from "../Reports/components/BusinessDashboard/Table/Table";
import AvatarLink from "./AvatarLink";
import InteractionTemplateModal from "./InteractionTemplateModal";
import {
  getInteractionTemplates,
  setDeleteTemplate,
  setEditTemplate,
} from "../reducers/interactionTemplatesReducer";
import DeleteInteractionTemplateModal from "./DeleteInteractionTemplateModal";
import Loading from "../Goals/components/Loader";

const THEAD_CLASSES = "tw-border-[1px] tw-border-solid tw-border-x-0 tw-border-gray-5";

const InteractionTemplates = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const [page, setPage] = useState(queryParams.get("page") || 1);
  const {
    loading,
    data: interactionTemplates,
    pagination,
  } = useSelector((state) => state.interactionTemplates.interactionTemplates);

  useEffect(() => {
    const onPopState = () => {
      const params = new URLSearchParams(window.location.search);
      setPage(params.get("page") || 1);
    };
    window.addEventListener("popstate", onPopState);
    return () => {
      window.removeEventListener("popstate", onPopState);
    };
  }, []);

  useEffect(() => {
    dispatch(getInteractionTemplates({ params: { page } }));
  }, [page]);

  return (
    <div className="tw-flex tw-flex-col tw-gap-[16px] tw-px-[32px] tw-py-[24px] tw-h-full">
      <h1 className="tw-text-28px tw-text-gray-75 tw-font-normal tw-my-0">Templates</h1>
      <div className="tw-relative tw-flex tw-justify-between">
        <TabBar>
          <Tab selected={true} label="Email" />
          <Tab selected={false} label="Listing Alerts" onClick={navigateToAlertTemplates} />
        </TabBar>
        {loading && (
          <span className="tw-mx-auto">
            <Loading />
          </span>
        )}
        <Button size="medium" onClick={() => dispatch(setEditTemplate({ name: "", subject: "", body: "" }))}>
          <div className="tw-flex tw-justify-center tw-items-center tw-gap-6px">
            <PlusSolidV6 />
            Create New Email Template
          </div>
        </Button>
      </div>
      <div className="tw-flex-1 tw-overflow-auto">
        <Table className="tw-border-separate">
          <THead className="tw-bg-white tw-sticky tw-top-0">
            <TR>
              <TH className={THEAD_CLASSES}>Title</TH>
              <TH className={`tw-text-center ${THEAD_CLASSES}`}>Created</TH>
              <TH className={`tw-text-center ${THEAD_CLASSES}`}>Created By</TH>
              <TH className={`tw-text-center ${THEAD_CLASSES}`}>Last Updated</TH>
              <TH className={`tw-text-center ${THEAD_CLASSES}`}>Updated By</TH>
              <TH className={`tw-text-center ${THEAD_CLASSES}`}>Edit</TH>
            </TR>
          </THead>
          <TBody>
            {interactionTemplates.map((template) => (
              <TR key={template.uuid} className="tw-border-0 hover:tw-bg-gray-5">
                <TD>{template.name}</TD>
                <TD className="tw-text-center">{moment(template.createdAt).format("MMM DD, YYYY")}</TD>
                <TD className="tw-text-center">
                  <AvatarLink simplePerson={template.createdBy} />
                </TD>
                <TD className="tw-text-center">{moment(template.updatedAt).fromNow()}</TD>
                <TD className="tw-text-center">
                  <AvatarLink simplePerson={template.updatedBy} />
                </TD>
                <TD>
                  <div className="tw-flex tw-gap-[4px] tw-justify-center tw-items-center">
                    <IconButton
                      aria-label="Edit"
                      schema="transparent"
                      onClick={() => dispatch(setEditTemplate(template))}
                    >
                      <PencilSolidV6 />
                    </IconButton>
                    <IconButton
                      aria-label="Delete"
                      schema="transparent"
                      onClick={() => dispatch(setDeleteTemplate(template))}
                    >
                      <XmarkSolidV6 size="l" />
                    </IconButton>
                  </div>
                </TD>
              </TR>
            ))}
          </TBody>
        </Table>
      </div>

      {interactionTemplates.length > 0 && (
        <div className="tw-flex tw-justify-center tw-p-[12px]">
          <Pagination
            currentPage={pagination.currentPage}
            totalPages={pagination.totalPages}
            onGoToPage={(pageNumber) => {
              window.history.pushState(null, null, `?page=${pageNumber}`);
              document.scrollingElement?.scrollTo({ top: 0 });
              setPage(pageNumber);
            }}
          />
        </div>
      )}

      <DeleteInteractionTemplateModal />
      <InteractionTemplateModal />
    </div>
  );
};

export default InteractionTemplates;

const navigateToAlertTemplates = () => {
  window.location.href = "/listing_alert_templates";
};
