import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "@shared/v2/Modal";
import { LocationArrowSolidV6 } from "@shared/v2/Icomoon";
import BaseEvent from "./BaseEvent";

const Details = ({ event }) => (
  <div className="timeline-description-text">
    <div>
      Location: {event.timeline_fields.address} {event.timeline_fields.city} {event.timeline_fields.state}{" "}
      {event.timeline_fields.zip}
    </div>
    <div>Keyword: {event.timeline_fields.keyword}</div>
  </div>
);

Details.propTypes = {
  event: PropTypes.shape().isRequired,
};

const KwklyPropertyView = ({ event }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => setShowModal(true)}
      onKeyDown={(e) => {
        if (["Enter", " "].includes(e.key)) {
          e.preventDefault();
          setShowModal(true);
        }
      }}
    >
      <BaseEvent
        icon={<LocationArrowSolidV6 />}
        title="Contact viewed a property using KWKLY"
        interactedAt={event.interacted_at_absolute}
      >
        <Details event={event} />
      </BaseEvent>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
        contentClassName="tw-max-w-[480px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
      >
        <Modal.Header title="KWKLY property viewed" closeButton />
        <Modal.Body>
          <div className="tw-font-semibold">Details:</div>
          <Details event={event} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

KwklyPropertyView.propTypes = {
  event: PropTypes.shape().isRequired,
};

export default KwklyPropertyView;
