import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Button from "@shared/v2/Button";
import { PlusSolidV6, ChevronDownSolidV6, ChevronUpSolidV6 } from "@shared/v2/Icomoon";
import TextButton from "@shared/v2/TextButton";
import CollapsibleElement from "../CollapsibleElement";
import {
  useLastReferredDateTime,
  useLenderModalOpen,
} from "../../../reducers/personalDetailReducer/personDetailReducer";
import { fetchLoans, fetchLoansFinancingStages } from "../../actions/personDetailActionCreators";
import { toggleLenderModal } from "../../actions/personDetailActionCreators";
import MortgageCard from "./MortgageCard";
import { useFeatureFlags } from "../../../reducers/layoutReducer/selectors";

const Mortgage = () => {
  const dispatch = useDispatch();
  const featureFlags = useFeatureFlags();
  const lenderModalOpen = useLenderModalOpen();
  const lastRefferedDateTime = useLastReferredDateTime();
  const person = useSelector((state) => state.personDetail.person);
  const loansData = useSelector((state) =>
    state.personDetail.loansData.sort((x, y) => (x.archived === y.archived ? 0 : x.archived ? 1 : -1)),
  );
  const loansFinancingStages = useSelector((state) => state.personDetail.loansFinancingStages);
  const [isArchived, setIsArchived] = useState(true);
  const divRef = useRef(null);

  const mortgageButtonText = loansData.length === 0 ? "" : "+ Envoy Referral";
  const hasArchived = loansData.length > 0 && loansData.some((item) => item?.archived === true);

  const filteredLoans = isArchived && hasArchived ? loansData.filter((loan) => !loan.archived) : loansData;

  useEffect(() => {
    if (person.data?.id) {
      dispatch(fetchLoans(person.data?.id));
      dispatch(fetchLoansFinancingStages(person.data?.id));
    }
  }, [person.data?.id]);

  useEffect(() => {
    if (!isArchived && divRef.current) {
      divRef.current.scrollTo({
        top: divRef.current.scrollHeight,
        behavior: "smooth", // Smooth scroll behavior
      });
    }
  }, [isArchived]);

  const toggleArchive = () => {
    setIsArchived(!isArchived);
  };

  return (
    <CollapsibleElement
      id="pdp-mortgage"
      title="Mortgage"
      customButtonSchema={featureFlags.loans && "secondary"}
      buttonText={featureFlags.loans && mortgageButtonText}
      buttonClickFunction={featureFlags.loans ? () => dispatch(toggleLenderModal()) : null}
    >
      {!featureFlags.loans ? (
        <>
          <Button
            schema={lenderModalOpen ? "primary" : "secondary"}
            onClick={() => dispatch(toggleLenderModal())}
            className="tw-mt-[4px] tw-w-full tw-h-[44px] !tw-rounded-[8px] tw-flex !tw-gap-[4px] tw-items-center tw-justify-center tw-text-14d !tw-normal-case"
          >
            <PlusSolidV6 />
            <span>Envoy Referral</span>
          </Button>

          {lastRefferedDateTime && (
            <div className="tw-text-12d tw-flex tw-justify-between tw-mt-[16px]">
              <span className="tw-text-gray-50">Last Referral:</span>
              <span className="tw-text-gray-75">
                {moment(lastRefferedDateTime).format("MM/DD/YY [at] hh:mma")}
              </span>
            </div>
          )}
        </>
      ) : (
        <>
          {loansData.length === 0 && (
            <Button
              schema="secondary"
              onClick={() => dispatch(toggleLenderModal())}
              className="tw-mt-[4px] tw-w-full tw-h-[44px] !tw-rounded-[8px] tw-flex !tw-gap-[4px] tw-items-center tw-justify-center tw-text-14d !tw-normal-case"
            >
              <PlusSolidV6 />
              <span>Envoy Referral</span>
            </Button>
          )}
          {loansData.length > 0 && (
            <div>
              <div
                ref={divRef}
                id="person-mortgage-card"
                className="tw-overflow-y-scroll tw-m-[-12px] tw-p-[12px]"
              >
                <div className="tw-max-h-[440px]">
                  {filteredLoans.map((loan) => (
                    <MortgageCard
                      mortgageLoan={loan}
                      loansFinancingStages={loansFinancingStages}
                      person={person}
                    />
                  ))}
                </div>
              </div>
              {hasArchived && (
                <div className="tw-pt-[20px]">
                  <TextButton onClick={toggleArchive}>
                    {isArchived ? (
                      <span>
                        <span className="tw-pr-[4px]">Show Archived Loans</span>
                        <ChevronDownSolidV6 size="s" />
                      </span>
                    ) : (
                      <span>
                        <span className="tw-pr-[4px]">Hide Archived Loans</span>
                        <ChevronUpSolidV6 size="s" />
                      </span>
                    )}
                  </TextButton>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </CollapsibleElement>
  );
};

export default Mortgage;
