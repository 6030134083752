import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FieldLabel from "@shared/v2/FieldLabel";

const AutoPlanStateSelect = ({ previousSelectedStates, togglePlanDropdown }) => {
  function checkState(inputId) {
    document.getElementById(inputId).checked = true;
  }
  useEffect(() => {
    if (previousSelectedStates) {
      previousSelectedStates.forEach((state) => {
        if (state === "assigned_plans_none") {
          togglePlanDropdown();
        }
        checkState(state);
      });
    }
  }, []);

  return (
    <div className="tw-flex tw-flex-col tw-gap-[4px] tw-mb-[10px]">
      <FieldLabel htmlFor="assigned_plans_none" label="Auto Plan" />

      <div className="tw-flex tw-flex-wrap tw-gap-[4px]">
        <input
          className="filter-checkbox"
          type="checkbox"
          value="assigned_plans_none"
          name="leads_filter[selected_assigned_plan_state_filters][]"
          id="assigned_plans_none"
        />
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
        <label onClick={() => togglePlanDropdown()} className="gray-label" htmlFor="assigned_plans_none">
          None Applied
        </label>
        <input
          className="filter-checkbox"
          type="checkbox"
          value="assigned_plans_running"
          name="leads_filter[selected_assigned_plan_state_filters][]"
          id="assigned_plans_running"
        />
        <label className="info-label" htmlFor="assigned_plans_running">
          Running
        </label>
        <input
          className="filter-checkbox"
          type="checkbox"
          value="assigned_plans_paused"
          name="leads_filter[selected_assigned_plan_state_filters][]"
          id="assigned_plans_paused"
        />
        <label className="danger-label" htmlFor="assigned_plans_paused">
          Paused
        </label>
        <input
          className="filter-checkbox"
          type="checkbox"
          value="assigned_plans_completed"
          name="leads_filter[selected_assigned_plan_state_filters][]"
          id="assigned_plans_completed"
        />
        <label className="success-label" htmlFor="assigned_plans_completed">
          Completed
        </label>
        <input
          className="filter-checkbox"
          type="checkbox"
          value="assigned_plans_deleted"
          name="leads_filter[selected_assigned_plan_state_filters][]"
          id="assigned_plans_deleted"
        />
        <label className="gray-darker-label" htmlFor="assigned_plans_deleted">
          Deleted
        </label>
      </div>
    </div>
  );
};

AutoPlanStateSelect.propTypes = {
  previousSelectedStates: PropTypes.arrayOf(PropTypes.string).isRequired,
  togglePlanDropdown: PropTypes.func.isRequired,
};

export default AutoPlanStateSelect;
