/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const RightToBracketSolidV6 = (props) => (
  <Icon {...props} name="Right To Bracket Solid V6">
    <path d="m10.772 7.453-4.503-4.25a.751.751 0 0 0-1.266.547V6H1A1 1 0 0 0-.001 7v2A1 1 0 0 0 1 10h4.003v2.25a.751.751 0 0 0 1.266.545l4.503-4.25a.776.776 0 0 0-.001-1.092zM13 1h-2a1 1 0 0 0 0 2h2a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2a1 1 0 0 0 0 2h2a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3z" />
  </Icon>
);

export default RightToBracketSolidV6;
