import React, { useState } from "react";
import PropTypes from "prop-types";
import { EnvelopeSolidV6 } from "@shared/v2/Icomoon";
import Modal from "@shared/v2/Modal";
import TextButton from "../../../shared/v2/TextButton";
import Button from "../../../shared/v2/Button";
import BaseEvent from "./BaseEvent";

const PublicActivityMessageEvent = ({
  event,
  personId,
  newDetailsPage,
  setInteractionTab,
  setGmailReplyThread,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleReplyClick = () => {
    setShowModal(false);
    if (newDetailsPage) {
      const additionalRecipient = event.other_recipients || [];
      const ccVal = event.cc || [];
      const bccVal = event.bcc || [];
      setGmailReplyThread(event.id, `RE: ${event.email_subject}`, additionalRecipient, ccVal, bccVal);
      setInteractionTab(1);
      window.scrollTo(0, 0);
    } else {
      Brivity.EmailInteraction.showModal(personId, null, event.id, event.email_subject);
    }
  };

  const additionalRecipient = event.other_recipients || [];
  const recipientString = additionalRecipient.length > 0 ? `, ${additionalRecipient.join(", ")}` : "";
  const from = `${event.agent_person.first_name} ${event.agent_person.last_name} <${event.agent_person.main_email}>`;
  const to = `${event.recipient.first_name} ${event.recipient.last_name} <${event.recipient.main_email}> ${recipientString}`;

  return (
    <BaseEvent
      icon={<EnvelopeSolidV6 />}
      title={
        <div>
          <span className="tw-font-semibold">{from}</span>
          {" emailed "}
          <span className="tw-font-semibold">{to}</span>
        </div>
      }
      interactedAt={event.interacted_at_absolute}
      onClick={() => setShowModal(true)}
    >
      <div className="tw-font-semibold">{event.email_subject}</div>
      <div>{event.email_snippet}</div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
        contentClassName="tw-max-w-[900px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
      >
        <Modal.Header title="Email" closeButton />
        <Modal.Body className="tw-flex-1 tw-overflow-auto">
          <h4 className="tw-text-18d tw-font-bold">Subject: {event.email_subject}</h4>
          <div className="tw-flex tw-justify-between tw-gap-[16px] tw-border-solid tw-border-t-0 first-of-type:tw-border-t-[2px] tw-border-[2px] tw-border-x-0 tw-border-gray-10 tw-p-[10px] tw-text-theme-primary">
            <div>{`${from} to ${to}`}</div>
            <div>{event.interacted_at_relative_in_words}</div>
          </div>
          <div className="tw-p-[16px] tw-border-solid tw-border-b-[2px] tw-border-x-0 tw-border-t-0 tw-border-gray-10">
            <div dangerouslySetInnerHTML={{ __html: event.email_body }} />
          </div>
        </Modal.Body>
        <Modal.Footer className="tw-flex tw-justify-between tw-gap-[16px]">
          <Button onClick={() => setShowModal(false)} schema="tertiary" size="medium">
            Close
          </Button>

          <div className="tw-flex tw-gap-[8px]">
            {event.other_recipients && event.other_recipients.length > 0 && (
              <TextButton onClick={handleReplyClick} size="medium">
                Reply All
              </TextButton>
            )}

            <Button onClick={handleReplyClick} schema="primary" size="medium">
              Reply
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </BaseEvent>
  );
};

PublicActivityMessageEvent.propTypes = {
  event: PropTypes.shape().isRequired,
  personId: PropTypes.number,
  newDetailsPage: PropTypes.bool.isRequired,
  setInteractionTab: PropTypes.func.isRequired,
  setGmailReplyThread: PropTypes.func.isRequired,
};

PublicActivityMessageEvent.defaultProps = {
  personId: undefined,
};

export default PublicActivityMessageEvent;
