import React from "react";
import PropTypes from "prop-types";

const Button = ({
  title = null,
  children = null,
  icon = null,
  type = "primary",
  onClick = () => {},
  className = "",
  iconClass = "",
  disabled = false,
  ...props
}) => {
  let btnBase = "tw-text-[14px] tw-border-2 tw-border-solid tw-font-bold";
  const btnSpacing = `tw-py-2 tw-px-8`;
  const btnTypes = {
    default: "tw-text-gray-75 tw-bg-transparent tw-border-transparent",
    primary: `tw-uppercase tw-bg-teal tw-text-white tw-border-teal tw-rounded-full ${btnSpacing}`,
    cancel: `tw-uppercase tw-bg-neutral-gray-10 tw-text-gray-75 tw-border-neutral-gray-10 tw-rounded-full ${btnSpacing}`,
    "soft-cancel": `tw-uppercase tw-bg-transparent tw-text-teal tw-border-teal tw-rounded-full ${btnSpacing}`,
    remove: `tw-uppercase tw-bg-transparent tw-text-red-500 tw-border-red-500 tw-rounded-full ${btnSpacing}`,
    "hard-remove": `tw-uppercase tw-bg-red-500 tw-text-white tw-border-red-500 tw-rounded-full ${btnSpacing}`,
  };
  // add disabled styles
  if (disabled) {
    btnBase += " tw-opacity-30";
  }
  return (
    <button
      type="button"
      data-cy={props["data-cy"]}
      disabled={disabled}
      onClick={onClick}
      className={`${btnBase} ${btnTypes[type]} ${className}`}
    >
      {icon && <i className={`fa ${icon} ${iconClass} ${title || children ? " tw-mr-4" : ""}`} />}
      {title}
      {children}
    </button>
  );
};

Button.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  "data-cy": PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  iconClass: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  title: null,
  children: null,
  "data-cy": undefined,
  icon: null,
  type: "primary",
  onClick: () => {},
  className: "",
  iconClass: "",
  disabled: false,
};

export default Button;
