import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { CommentsSolidV6 } from "@shared/v2/Icomoon";
import Modal from "@shared/v2/Modal";
import BaseEvent from "./BaseEvent";

const PublicActivityOtherEvent = ({ event }) => {
  const [showModal, setShowModal] = useState(false);
  const from = `${event.agent_person.first_name} ${event.agent_person.last_name}`.trim();
  const headline = (
    <div>
      <span className="tw-font-semibold">{from}</span>
      {" created an Interaction"}
    </div>
  );

  const details = (
    <>
      <div>Scheduled at: {moment(event.object_attributes.interacted_at).format("LL")}</div>
      {event.interaction_type && <div>Type: {event.interaction_type}</div>}
      {event.object_attributes.comment && (
        <div className="tw-whitespace-pre-wrap">Notes: {event.object_attributes.comment}</div>
      )}
    </>
  );

  return (
    <BaseEvent
      icon={<CommentsSolidV6 size="l" />}
      title={headline}
      interactedAt={event.interacted_at_absolute}
      onClick={() => setShowModal(true)}
    >
      {details}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
        contentClassName="tw-max-w-[400px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
      >
        <Modal.Header title="Interaction" closeButton />
        <Modal.Body>
          {headline}
          {details}
        </Modal.Body>
      </Modal>
    </BaseEvent>
  );
};

PublicActivityOtherEvent.propTypes = {
  event: PropTypes.shape().isRequired,
};

export default PublicActivityOtherEvent;
