import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextButton from "@shared/v2/TextButton";
import { ChevronDownSolidV6, ChevronUpSolidV6 } from "@shared/v2/Icomoon/Icons";
import MarketReportMenu from "./MarketReportMenu";
import EstimatedValChart from "./EstimatedValChart";
import { getHomeValue } from "../../AddMarketReportModal/api";

const LABEL_CLASS = "tw-text-[12px] tw-text-gray-50 tw-font-semibold tw-inline-block tw-pb-[4px]";
const VALUE_CLASS = "tw-text-[14px] tw-text-gray-75 tw-font-normal tw-no-wrap tw-flex-1";

const MarketReportCard = ({
  id,
  recurring_frequency,
  report_name,
  preview_url,
  view_count,
  street_address,
  city,
  state_abbreviation,
  postal_code,
  report_type,
  view_history,
  last_viewed,
  last_opened,
  location,
  showAdjustment,
  has_address,
  created_at,
  editReportFn,
  editMarketReportPanel2,
  deleteReportFn,
  home_value_adjustment,
  home_value_adjustment_as_percentage,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [homeAdjVal, setHomeAdjVal] = useState(0);
  const displayCondition = homeAdjVal && homeAdjVal != 0 ? homeAdjVal : null;

  useEffect(() => {
    if (street_address) {
      (async () => {
        const { response } = await getHomeValue({
          streetAddress: street_address,
          city: city,
          state: state_abbreviation,
          postalCode: postal_code,
        });

        setHomeAdjVal(Math.floor(response?.data?.value || 0));
      })();
    }
  }, [home_value_adjustment, street_address]);

  const handleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickAdjust = () => {
    editMarketReportPanel2(id);
  };

  return (
    <div className={`tw-relative tw-shadow-card tw-bg-white tw-rounded-[7px] tw-overflow-hidden `}>
      <div
        className={`tw-p-[24px] ${isExpanded ? "tw-overflow-y-scroll tw-max-h-[500px]" : has_address && showAdjustment && displayCondition && report_type !== "market" ? "tw-h-[349px]" : "tw-h-[110px]"}`}
      >
        <div className="tw-flex tw-pb-[2px] tw-justify-between tw-overflow-ellipsis">
          <a
            href={preview_url}
            target="_blank"
            className={`tw-text-[14px] tw-text-left tw-font-semibold tw-text-gray-99 tw-flex-1 tw-border-0 tw-bg-transparent tw-p-0 tw-mr-[8px]`}
          >
            {report_name}
          </a>

          <MarketReportMenu
            id={id}
            previewUrl={preview_url}
            editReportFn={editReportFn}
            deleteReportFn={deleteReportFn}
          />
        </div>
        <div className="tw-flex tw-pb-[2px] tw-justify-between tw-overflow-ellipsis tw-mb-[11px]">
          <div> {location}</div>
          <div></div>
        </div>

        <hr className="tw-mt-0 tw-mb-0 tw-pt-5px tw-pb-5px tw-bg-white tw-border-neutral-gray-10 tw-w-full" />
        {has_address && showAdjustment && displayCondition && report_type !== "market" && (
          <>
            <div className="tw-pb-[11px]">
              <EstimatedValChart
                preview_url={preview_url}
                homeAdjVal={homeAdjVal}
                handleClickAdjust={handleClickAdjust}
                home_value_adjustment_as_percentage={home_value_adjustment_as_percentage}
                home_value_adjustment={home_value_adjustment}
              />
            </div>
            <hr className="tw-mt-0 tw-mb-0 tw-pt-5px tw-pb-5px tw-bg-white tw-border-neutral-gray-10 tw-w-full" />
          </>
        )}
        <div className="tw-grid tw-grid-cols-2 tw-gap-y-[12px] tw-gap-x-[9px]">
          <div>
            <div className={LABEL_CLASS}>Last Viewed</div>
            <div className={VALUE_CLASS}>{last_viewed}</div>
          </div>
          <div>
            <div className={LABEL_CLASS}>Last Opened</div>
            <div className={VALUE_CLASS}>{last_opened}</div>
          </div>
          <div>
            <div className={LABEL_CLASS}>Frequency</div>
            <div className={VALUE_CLASS}>{recurring_frequency}</div>
          </div>
          <div>
            <div className={LABEL_CLASS}>Created</div>
            <div className={VALUE_CLASS}>{created_at}</div>
          </div>
        </div>

        <div className="tw-py-[12px]">
          <span className={`${LABEL_CLASS} !tw-pb-0`}>View History</span>
          <span className={`${LABEL_CLASS} tw-pl-[3px]`}>({view_count})</span>
        </div>
        {view_history && (
          <div>
            {view_history.map((history, index) => {
              const [date, time] = history.replace("at", ",").split(",");
              return (
                <div key={index} className="tw-flex tw-justify-between">
                  <span className={LABEL_CLASS}>{date}</span>
                  <span className={LABEL_CLASS}>{time}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="tw-w-[100%] tw-h-[50px] tw-bg-white"></div>
      <div className="tw-absolute tw-bottom-[0px] tw-w-[100%] tw-h-[50px] tw-bg-white tw-left-[0px]">
        {isExpanded && (
          <hr className="tw-mt-0 tw-mb-0 tw-bg-white tw-px-[24px] tw-border-neutral-gray-10 tw-w-full" />
        )}
        <div className="tw-pt-[12px] tw-text-center">
          <TextButton schema="tertiary" onClick={handleExpanded} className="tw-text-theme-primary">
            <span className="tw-uppercase tw-text-[11px] tw-font-bold ">
              {isExpanded ? "Less Info" : "More Info"}
            </span>
            <span>
              {isExpanded ? (
                <ChevronUpSolidV6 size="m" className="tw-ml-[4px]" />
              ) : (
                <ChevronDownSolidV6 size="m" className="tw-ml-[4px]" />
              )}
            </span>
          </TextButton>
        </div>
      </div>
    </div>
  );
};

MarketReportCard.propTypes = {
  id: PropTypes.string,
  recurring_frequency: PropTypes.string,
  report_name: PropTypes.string,
  location: PropTypes.string,
  street_address: PropTypes.string,
  city: PropTypes.string,
  state_abbreviation: PropTypes.string,
  postal_code: PropTypes.string,
  preview_url: PropTypes.string,
  view_count: PropTypes.number,
  last_viewed: PropTypes.string,
  last_opened: PropTypes.string,
  created_at: PropTypes.string,
  view_history: PropTypes.arrayOf(PropTypes.shape()),
  editReportFn: PropTypes.func,
  editMarketReportPanel2: PropTypes.func,
  deleteReportFn: PropTypes.func,
  home_value_adjustment: PropTypes.string,
  home_value_adjustment_as_percentage: PropTypes.bool,
  showAdjustment: PropTypes.bool,
  has_address: PropTypes.bool,
  report_type: PropTypes.string,
};
MarketReportCard.defaultProps = {
  id: null,
  recurring_frequency: "",
  location: "",
  street_address: "",
  city: "",
  state_abbreviation: "",
  postal_code: "",
  report_name: "",
  preview_url: "",
  view_count: "",
  last_viewed: "",
  last_opened: "",
  created_at: "",
  view_history: null,
  editReportFn: null,
  editMarketReportPanel2: () => {},
  deleteReportFn: null,
  home_value_adjustment: null,
  home_value_adjustment_as_percentage: null,
  showAdjustment: false,
  has_address: false,
  report_type: "market",
};

export default MarketReportCard;
