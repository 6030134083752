import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";
import TextButton from "@shared/v2/TextButton";
import { useTheme } from "../../reducers/layoutReducer/selectors";
import { calculateHomeAdjustment } from "../../AddMarketReportModal/components/Panel2/helpers";

const EstimatedValChart = ({
  handleClickAdjust,
  home_value_adjustment,
  home_value_adjustment_as_percentage,
  homeAdjVal,
  preview_url,
}) => {
  const isPlaceTheme = useTheme().name === "place-theme";
  const [highVal, setHighVal] = useState(0);
  const [lowVal, setLowVal] = useState(0);
  const [automatedEst, setAutomatedEst] = useState(0);

  useEffect(() => {
    if (homeAdjVal) {
      setAutomatedEst(
        calculateHomeAdjustment({
          homeAdj: home_value_adjustment,
          isHomeAdjPercentage: home_value_adjustment_as_percentage,
          isHomeAdjPositive: checkValue,
          homeAdjVal,
        }),
      );
    }
  }, [homeAdjVal, home_value_adjustment]);

  useEffect(() => {
    if (automatedEst) {
      const result = calculatePercentage(automatedEst);
      setHighVal(result.High);
      setLowVal(result.Low);
    }
  }, [automatedEst]);

  const openMarketReport = () => {
    window.open(preview_url || window.location.href, "_blank");
  };

  const calculatePercentage = (valueStr) => {
    // Remove any non-numeric characters except for the minus sign
    const numericValue = parseFloat(valueStr.replace(/[^0-9.-]/g, ""));

    if (isNaN(numericValue)) {
      console.log("Invalid number format");
    }

    // Calculate +10% and -10%
    const plusTenPercent = numericValue * 1.1;
    const minusTenPercent = numericValue * 0.9;
    // Format the numbers back with commas
    const formatNumber = (num) => num.toLocaleString("en-US");

    return {
      High: formatNumber(Math.round(plusTenPercent)),
      Low: formatNumber(Math.round(minusTenPercent)),
    };
  };

  const checkValue = () => {
    // Convert the string to a number
    const numericValue = parseFloat(home_value_adjustment);

    // Check if positive, negative, or zero
    if (numericValue >= 0) {
      return true;
    } else {
      return false;
    }
  };

  const generateBarColors = () => {
    const barColors = Array(20).fill("#C4C4C4"); // Default gray

    // Apply theme-based colors
    const themeColors = isPlaceTheme ? ["#2454B2", "#3270FA", "#578EFA"] : ["#4CA7A7", "#59C4C4", "#94DCDC"];

    // Leave the first and last 4 unchanged
    for (let i = 4; i < 16; i++) {
      if (i === 4 || i === 15) {
        barColors[i] = themeColors[2]; // First and last within range
      } else if (i === 5 || i === 14) {
        barColors[i] = themeColors[1]; // Second and second last within range
      } else {
        barColors[i] = themeColors[0]; // Middle range
      }
    }
    return barColors;
  };

  // Generate dynamic bar colors
  const barColors = generateBarColors();

  const lowValue = 100000; // Low value
  const highValue = 999999; // High value
  const data = {
    labels: Array(20).fill(""), // Empty labels
    datasets: [
      {
        label: "Value Range",
        data: Array(20)
          .fill(0)
          .map((_, i) => lowValue + (i * (highValue - lowValue)) / 19),
        backgroundColor: barColors,
        borderRadius: Number.MAX_VALUE, // Rounded bars
        borderSkipped: false,
        barThickness: 5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false, // Hide y-axis
      },
    },
  };

  return (
    <>
      <div>
        <div className="tw-flex tw-justify-between tw-pb-[4px]">
          <span className="tw-text-[12px] tw-text-gray-75 tw-font-semibold tw-inline-block">
            Estimated Value
          </span>
          <TextButton schema="tertiary" className="tw-text-theme-primary tw-p-0" onClick={handleClickAdjust}>
            {(() => {
              const homeValueAdj = home_value_adjustment * 1;
              const signValue = homeValueAdj > 0 ? "" : "-";
              return (
                <span
                  className={`tw-text-[12px] ${home_value_adjustment === null || homeValueAdj === 0 ? "tw-font-bold" : "tw-font-semibold"}`}
                >
                  {home_value_adjustment === null || homeValueAdj === 0
                    ? "ADJUST"
                    : `Adjusted ${home_value_adjustment_as_percentage ? `${signValue} ${Math.floor(Math.abs(homeValueAdj))}%` : `${signValue}$ ${Math.floor(Math.abs(homeValueAdj))}`}`}
                </span>
              );
            })()}
          </TextButton>
        </div>
        <div
          onClick={openMarketReport}
          className={`tw-text-[28px] tw-cursor-pointer tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-font-normal tw-inline-block ${isPlaceTheme ? "tw-from-[#578EFA] tw-to-[#2454B2]" : "tw-from-[#94DCDC] tw-to-[#4CA7A7]"}`}
        >
          {automatedEst}
        </div>
      </div>
      <div className="tw-w-full !tw-flex tw-flex-col tw-items-center">
        <div className="chart-container">
          <Bar data={data} height={55} width={192} options={options} />
        </div>
        <div className="tw-flex tw-justify-between tw-w-full tw-mt-2 tw-text-gray-600 tw-text-sm">
          <div>
            <div className="tw-font-semibold tw-text-12 tw-text-gray-75">${lowVal}</div>
            <div className="tw-text-center tw-font-normal tw-text-10 tw-text-gray-50">Low</div>
          </div>
          <div>
            <div className="tw-font-semibold tw-text-12 tw-text-gray-75">${highVal}</div>
            <div className="tw-text-center tw-font-normal tw-text-10 tw-text-gray-50">High</div>
          </div>
        </div>
      </div>
    </>
  );
};

EstimatedValChart.propTypes = {
  homeAdjVal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleClickAdjust: PropTypes.func,
  home_value_adjustment: PropTypes.string,
  home_value_adjustment_as_percentage: PropTypes.bool,
  preview_url: PropTypes.string,
};
EstimatedValChart.defaultProps = {
  homeAdjVal: 0,
  handleClickAdjust: () => {},
  home_value_adjustment: null,
  preview_url: "",
  home_value_adjustment_as_percentage: null,
};

export default EstimatedValChart;
