export const valueOrDash = (value) => value || "\u2014";

export const prospectNameColor = (prospect) => {
  if (!prospect.phone) {
    return "tw-text-gray-30";
  }

  if (prospect.isMyLead) {
    return "tw-text-theme-primary";
  }

  return "tw-text-gray-75";
};

const sharedPropertyTypes = [
  "Condo/Townhome",
  "Farm/Ranch",
  "Multi-Family",
  "Residential",
  "Commercial Sale",
  "Mixed Use",
];

export const SearchTypeConfig = {
  expireds: {
    type: "expireds",
    domOrList: "DOM",
    domOrListField: "daysOnMarket",
    showAllPersonsColumn: true,
    showDaysOnMarketColumn: true,
    statuses: ["Expired", "Withdrawn", "Canceled"],
    hasPrice: true,
    dateName: "Status Date",
    typeName: "Expired",
    dateDescription: "expired",
    sourceName: "Opportunities - Expired",
    propertyTypes: sharedPropertyTypes,
  },
  fsbos: {
    type: "fsbos",
    domOrList: "Publish Date",
    domOrListField: "statusDateDisplay",
    showAllPersonsColumn: false,
    showDaysOnMarketColumn: false,
    statuses: [],
    hasPrice: true,
    dateName: "Publish Date",
    typeName: "FSBO",
    dateDescription: "was listed",
    sourceName: "Opportunities - FSBO",
    propertyTypes: [...sharedPropertyTypes, "Vacant Land"],
  },
  preforeclosures: {
    type: "preforeclosures",
    domOrList: "Publish Date",
    domOrListField: "statusDateDisplay",
    showAllPersonsColumn: true,
    showDaysOnMarketColumn: false,
    statuses: ["Notice of Default", "Notice of Sale", "Lis Pendens", "Trustee Sale", "Bank Owned"],
    hasPrice: false,
    dateName: "Publish Date",
    typeName: "Preforeclosure",
    dateDescription: "was published",
    sourceName: "Opportunities - Preforeclosure",
    propertyTypes: [...sharedPropertyTypes, "Vacant Land"],
  },
};
