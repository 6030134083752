import React from "react";
import PropTypes from "prop-types";

const BaseEvent = ({ children, extraContent, icon, interactedAt, onClick, title }) => {
  const extraProps = onClick ? { onClick, role: "button", tabIndex: 0 } : {};

  return (
    <div className="tw-p-[24px] tw-bg-white tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-b-[1px] tw-border-gray-10">
      <div className="tw-flex tw-items-start tw-gap-[24px] hover:tw-bg-gray-5" {...extraProps}>
        <div className="tw-flex tw-flex-none tw-items-center tw-justify-center tw-border-solid tw-border-[2px] tw-border-neutral-gray-75 tw-rounded-full tw-p-[10px]">
          {icon}
        </div>
        <div className="tw-text-14d tw-flex tw-flex-col tw-gap-[4px] tw-flex-1">
          <div className="tw-flex tw-justify-between tw-gap-[8px]">
            {title}
            <span className="tw-whitespace-nowrap tw-text-12d">{interactedAt}</span>
          </div>

          {children}
        </div>
      </div>
      {extraContent}
    </div>
  );
};

BaseEvent.propTypes = {
  children: PropTypes.node.isRequired,
  extraContent: PropTypes.node,
  icon: PropTypes.node.isRequired,
  interactedAt: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.node.isRequired,
};

BaseEvent.defaultProps = {
  extraContent: null,
  onClick: null,
};

export default BaseEvent;
