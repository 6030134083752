import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Toggle from '@shared/v2/Toggle';
import { CheckSolidV6, MinusSolidV6 } from '@shared/v2/Icomoon';
import formatPhoneNumber from "@shared/FormatPhoneNumber";
import { useFeatureFlags } from '../../reducers/layoutReducer/selectors';

const reminderStyles = (status) => !status ? 'tw-border-[#96B7FA] tw-text-[#2454B2] tw-bg-[#F7F9FC]' : 'tw-border-[#46C771] tw-text-[#177335] tw-bg-[#EDFAF1]';

function getCallStatusMessage(callStatus) {

  const statusMap = {
    null: {
      status: "Calling you at your phone",
      circleColor: 'tw-bg-[#3270FA]'
    },
    Initiated: { status: 'Ringing', circleColor: 'tw-bg-[#3270FA]' },
    Ringing: { status: 'Ringing', circleColor: 'tw-bg-[#3270FA]' },
    'In-progress': { status: 'Connected', circleColor: 'tw-bg-green-100' },
  };

  return statusMap[callStatus] || { status: 'Call Ended', circleColor: 'tw-bg-[#E5342E]' };
}

const CallStatusBar = ({
  callStatus,
  currentCallTime,
  isCompleted,
  recorded,
  recordToggleDisabled,
  onToggleChange,
  agentPhoneNumber,
  statusClassName
}) => {
  const RecordConfirmationIcon = recorded ? CheckSolidV6 : MinusSolidV6;
  const featureFlag = useFeatureFlags();
  const standardCallStatus = getCallStatusMessage(callStatus);

  return (
    <div className='tw-block'>
      <div
        className={`tw-py-10px tw-px-16px tw-border-[1px] tw-border-solid tw-border-gray-30 tw-flex tw-items-center tw-text-gray-75 tw-bg-gray-5 tw-text-12d tw-font-semibold tw-rounded-lg tw-w-[448px] tw-mr-auto ${!isCompleted ? 'tw-rounded-bl-none tw-rounded-br-none' : ''
          } ${statusClassName}`}
      >
        <div
          className={`tw-w-8px tw-h-8px tw-rounded-full tw-mr-8px ${standardCallStatus.circleColor}`}
        />
        <span className='tw-leading-10px'>{standardCallStatus.status}</span>
        <span className="tw-font-normal tw-ml-auto">{currentCallTime}</span>
        {featureFlag.callRecording &&
          <>
            <span
              className={`tw-leading-10px tw-font-semibold tw-text-gray-75 tw-ml-32px tw-whitespace-nowrap ${isCompleted ? '' : 'tw-w-[65px]'
                }`}
            >
              {(() => {
                if (isCompleted) {
                  return recorded ? 'Recorded' : 'Not Recorded';
                }
                return `Record ${recorded ? 'ON' : 'OFF'}`;
              })()}
            </span>
            {callStatus === 'In-progress' && (
              <Toggle
                id="recordedToggle"
                name="recordedToggle"
                labelClassName="tw-ml-8px !tw-mb-[0px]"
                value={recorded}
                checked={recorded === true}
                disabled={recordToggleDisabled}
                onChange={onToggleChange}
              />
            )}
            {isCompleted && <RecordConfirmationIcon className="tw-ml-8px" />}
          </>
        }
      </div>
      <div
        className={`tw-py-10px tw-px-16px tw-border-[1px] tw-border-solid tw-flex tw-items-center tw-text-12d tw-font-semibold tw-rounded-lg tw-rounded-tl-none tw-rounded-tr-none ${reminderStyles(
          callStatus
        )} ${isCompleted || (callStatus && !featureFlag.callRecording) ? 'tw-hidden' : ''}`}
      >
        {!callStatus
          ? `To start the call, please answer your phone ${agentPhoneNumber ? `at ${formatPhoneNumber(agentPhoneNumber)}` : ''
          }`
          : 'Please inform the other party before recording.'}
      </div>
    </div>
  )
};

export default CallStatusBar;

CallStatusBar.propTypes = {
  callStatus: PropTypes.string,
  currentCallTime: PropTypes.string,
  isCompleted: PropTypes.bool,
  recorded: PropTypes.bool,
  recordToggleDisabled: PropTypes.bool,
  onToggleChange: PropTypes.func.isRequired,
  agentPhoneNumber: PropTypes.string,
  statusClassName: PropTypes.string,
};

CallStatusBar.defaultProps = {
  callStatus: null,
  currentCallTime: '',
  isCompleted: false,
  recorded: false,
  recordToggleDisabled: false,
  agentPhoneNumber: null,
  statusClassName: '',
};