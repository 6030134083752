import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { EnvelopeRegularV6, PaperclipSolidV6 } from "@shared/v2/Icomoon";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import TextButton from "@shared/v2/TextButton";
import GmailThread from "./GmailThread";
import BaseEvent from "./BaseEvent";

const GmailInteraction = ({
  interaction,
  newDetailsPage,
  personId,
  setGmailReplyThread,
  setInteractionTab,
}) => {
  const messageCount = interaction.messages.length;
  const [showModal, setShowModal] = useState(false);
  const [recipientsEmails, setRecipientsEmails] = useState([]);
  const [ccEmails, setCcEmails] = useState([]);

  useEffect(() => {
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
    if (interaction.most_recent_message_to) {
      // Regular expression to match email addresses
      // Use the match method to find all email addresses in the most_recent_message_to
      const recipientEmailAddresses = interaction.most_recent_message_to.match(emailRegex);
      if (recipientEmailAddresses) setRecipientsEmails(recipientEmailAddresses);
    }

    if (interaction.most_recent_message_cc) {
      // Regular expression to match email addresses
      // Use the match method to find all email addresses in the most_recent_message_cc
      const ccEmailAddresses = interaction.most_recent_message_cc.match(emailRegex);
      if (ccEmailAddresses) setCcEmails(ccEmailAddresses);
    }
  }, []);

  const showGmailModal = () => setShowModal(true);
  const hideGmailModal = () => setShowModal(false);

  const showEmailModal = () => {
    hideGmailModal();
    Brivity.EmailInteraction.showModal(personId, null, interaction.id, interaction.subject);
  };

  const handleReplyClick = () => {
    if (newDetailsPage) {
      const additionalRecipient = recipientsEmails.length > 1 ? recipientsEmails.slice(1) : [];
      const ccVal = ccEmails || [];
      const bccVal = interaction.bcc || [];
      setGmailReplyThread(interaction.id, `RE: ${interaction.subject}`, additionalRecipient, ccVal, bccVal);
      setInteractionTab(1);
      hideGmailModal();
      window.scrollTo(0, 0);
    } else {
      showEmailModal();
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={showGmailModal}
      onKeyDown={(e) => {
        if (["Enter", " "].includes(e.key)) {
          e.preventDefault();
          showGmailModal();
        }
      }}
    >
      <BaseEvent
        icon={<EnvelopeRegularV6 />}
        title={
          <div>
            <span className="tw-font-semibold">{interaction.most_recent_message_from}</span>
            {` emailed `}
            <span className="tw-font-semibold">{interaction.most_recent_message_to}</span>
            {messageCount > 1 ? ` (${messageCount}) ` : " "}
          </div>
        }
        interactedAt={
          <>
            {interaction.interacted_at_absolute} {interaction.has_attachment && <PaperclipSolidV6 />}
          </>
        }
      >
        <div className="timeline-description-text">
          <div className="bold-detail mail-row-subject">{interaction.subject}</div>
          <div dangerouslySetInnerHTML={{ __html: interaction.snippet }} />
        </div>
      </BaseEvent>

      <Modal
        show={showModal}
        onHide={hideGmailModal}
        dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
        contentClassName="tw-max-w-[800px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
      >
        <Modal.Header title="Email" closeButton />
        <Modal.Body className="tw-flex-1 tw-overflow-y-auto">
          <h4 className="subject">Subject: {interaction.subject}</h4>
          <GmailThread messages={interaction.messages} />
        </Modal.Body>
        <Modal.Footer className="tw-flex tw-justify-between tw-gap-[8px]">
          <Button onClick={hideGmailModal} schema="tertiary" size="medium">
            Close
          </Button>

          <div className="tw-flex tw-gap-[8px]">
            {recipientsEmails.length > 1 && (
              <TextButton onClick={handleReplyClick} size="medium">
                Reply All
              </TextButton>
            )}

            <Button onClick={handleReplyClick} schema="primary" size="medium">
              Reply
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

GmailInteraction.propTypes = {
  interaction: PropTypes.shape().isRequired,
  newDetailsPage: PropTypes.bool.isRequired,
  personId: PropTypes.number.isRequired,
  setInteractionTab: PropTypes.func.isRequired,
  setGmailReplyThread: PropTypes.func.isRequired,
};

export default GmailInteraction;
