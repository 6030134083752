import React from "react";
import PropTypes from "prop-types";

const FormLabel = ({ htmlFor = null, title = "Form Label", copy = null, required = false }) => (
  <div className="tw-mt-8 tw-mb-4 tw-text-[14px]">
    <label htmlFor={htmlFor} className="tw-font-semibold tw-text-gray-50">
      {title}
      {required ? <i className="tw-text-red-500 tw-mx-3">*</i> : null}
    </label>
    {copy ? <p className="tw-mt-2">{copy}</p> : null}
  </div>
);

FormLabel.propTypes = {
  htmlFor: PropTypes.string,
  title: PropTypes.string,
  copy: PropTypes.string,
  required: PropTypes.bool,
};

FormLabel.defaultProps = {
  htmlFor: null,
  title: "Form Label",
  copy: null,
  required: false,
};

export default FormLabel;
