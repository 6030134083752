import { useEffect, useState } from "react";
import axios from "axios";
import { camelCase } from "lodash";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";

const BASE_GOALS = {
  avgCommissionPercent: 0,
  avgSalePrice: 0,
  brokerageCap: 0,
  brokerageSplitPercentage: 0,
  buyerCommissionSplit: 0,
  currentNetIncomeGoal: 0,
  goalTypes: [],
  pendingToCloseRatio: 0,
  royaltyCap: 0,
  royaltyPercentage: 0,
  sellerCommissionSplit: 0,
  sellerToTotalClosingRatio: 0,
};

const getWizard = (...config) =>
  caseTransformingAxios.get("/api/v4/goals/wizard", ...config).then((res) => res.data);

const saveGoal = (...config) =>
  caseTransformingAxios.post("/api/v4/goals/upsert", ...config).then((res) => res.data);

const useWizard = ({ agentId, show, year }) => {
  const [goals, setGoals] = useState({
    ...BASE_GOALS,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      setLoading(true);
      getWizard({ params: { userUuid: agentId, year } })
        .then((data) => {
          setLoading(false);
          setGoals(data);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setLoading(false);
            setErrors({ global: "Failed to load Goals" });
          }
        });
    } else {
      setErrors({});
    }
  }, [agentId, show, year]);

  return {
    actions: {
      setLocalGoals: setGoals,
      saveGoals: (recommendedGoals) => {
        setLoading(true);
        return Promise.all(
          goals.goalTypes
            .filter((gt) => gt.name !== "closed_gci")
            .map((gt) =>
              saveGoal({
                userUuid: agentId,
                goalTypeId: gt.id,
                goalValue: recommendedGoals[camelCase(gt.name)] || goals.currentNetIncomeGoal,
                year,
              }),
            ),
        )
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            setErrors({ global: "Failed to save Goals" });
          });
      },
    },
    errors,
    goals,
    loading,
  };
};

export default useWizard;
