/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import TextButton from "@shared/v2/TextButton";

import NameWithAvatar from "./NameWithAvatar";
import RoutingNumbersDropdown from "./RoutingNumbersDropdown";
import { INBOUND_RECORDING_OPTIONS, OUTBOUND_RECORDING_OPTIONS } from "./helpers/recordingOptions";
import CallRecordingDropdown from "./CallRecordingDropdown";
import { updateUserRecordingSettings } from "../../reducers/layoutReducer/phoneSettingsReducer";
import {
  useAccountCallRecordingSettings,
  useCurrentUser,
  useFeatureFlags,
} from "../../reducers/layoutReducer/selectors";

const AgentRow = ({ addBrivityNumber, agent }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const featureFlags = useFeatureFlags();
  const accountCallRecordingSettings = useAccountCallRecordingSettings();
  const inboundByNumberDisabled = accountCallRecordingSettings?.inboundRecording !== "by_number";
  const outboundByNumberDisabled = accountCallRecordingSettings?.outboundRecording !== "by_number";
  const inboundDisabled = inboundByNumberDisabled
    ? accountCallRecordingSettings?.inboundRecording === "disabled"
    : agent.callRecordingSettings?.inboundRecording === "disabled" ||
      !agent.callRecordingSettings?.inboundRecording;
  const outboundDisabled = outboundByNumberDisabled
    ? accountCallRecordingSettings?.outboundRecording === "disabled"
    : agent.callRecordingSettings?.outboundRecording === "disabled" ||
      !agent.callRecordingSettings?.outboundRecording;
  const managedNumber = agent.managedNumber ?? (
    <TextButton onClick={() => addBrivityNumber(agent)}>Add</TextButton>
  );

  return (
    <tr>
      <td className="tw-px-[8px] tw-py-[16px]">
        <NameWithAvatar
          aria-label="agent"
          name={agent.fullName}
          role={agent.role}
          avatarUrl={agent.avatarUrl}
          showPath={agent.showPath}
        />
      </td>
      <td className="tw-px-[8px] tw-py-[16px]">
        <RoutingNumbersDropdown
          aria-label="routing numbers"
          numbers={agent.routingNumbers}
          selectedNumberId={agent.routingNumberId}
        />
      </td>
      <td className="tw-px-[8px] tw-py-[16px]">{managedNumber}</td>
      {featureFlags.callRecording && (
        <>
          <td className="tw-px-[8px] tw-py-[16px] tw-align-top">
            <CallRecordingDropdown
              aria-label="Inbound settings"
              options={INBOUND_RECORDING_OPTIONS}
              isDisabled={!currentUser.account.isAdmin || inboundByNumberDisabled}
              value={INBOUND_RECORDING_OPTIONS.find(
                (o) =>
                  o.value ===
                  (inboundByNumberDisabled
                    ? accountCallRecordingSettings?.inboundRecording
                    : agent.callRecordingSettings?.inboundRecording || "disabled"),
              )}
              loading={agent.loading}
              onChange={(option) => {
                dispatch(
                  updateUserRecordingSettings({
                    userUuid: agent.uuid,
                    settings: {
                      inboundRecording: option.value,
                    },
                  }),
                );
              }}
            />
            {!inboundDisabled && (
              <div className="tw-ml-[2px] tw-text-12d tw-text-neutral-gray-30">
                Notification Message:{" "}
                {accountCallRecordingSettings?.inboundNotificationMessageCondition === "always"
                  ? "Always Play"
                  : "Check Location"}
              </div>
            )}
          </td>
          <td className="tw-px-[8px] tw-py-[16px]">
            <CallRecordingDropdown
              aria-label="Outbound settings"
              options={OUTBOUND_RECORDING_OPTIONS}
              isDisabled={!currentUser.account.isAdmin || outboundByNumberDisabled}
              value={OUTBOUND_RECORDING_OPTIONS.find(
                (o) =>
                  o.value ===
                  (outboundByNumberDisabled
                    ? accountCallRecordingSettings?.outboundRecording
                    : agent.callRecordingSettings?.outboundRecording || "disabled"),
              )}
              loading={agent.loading}
              onChange={(option) => {
                dispatch(
                  updateUserRecordingSettings({
                    userUuid: agent.uuid,
                    settings: {
                      outboundRecording: option.value,
                    },
                  }),
                );
              }}
            />
            {!outboundDisabled && (
              <div className="tw-ml-[2px] tw-text-12d tw-text-neutral-gray-30">
                Notification Message:{" "}
                {accountCallRecordingSettings?.outboundNotificationMessageCondition === "always"
                  ? "Always Play"
                  : "Check Location"}
              </div>
            )}
          </td>
        </>
      )}
    </tr>
  );
};

AgentRow.propTypes = {
  addBrivityNumber: PropTypes.func.isRequired,
  agent: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    uuid: PropTypes.string,
    loading: PropTypes.bool,
    managedNumber: PropTypes.string,
    fullName: PropTypes.string,
    role: PropTypes.string,
    avatarUrl: PropTypes.string,
    showPath: PropTypes.string,
    routingNumbers: PropTypes.arrayOf(PropTypes.shape({})),
    routingNumberId: PropTypes.string,
    callRecordingSettings: PropTypes.shape(),
  }).isRequired,
};

export default AgentRow;
