/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const SquareCheckSolidV6 = (props) => (
  <Icon {...props} name="Square Check Solid V6">
    <path d="M13 1a2 2 0 0 1 2 2v10c0 1.103-.897 2-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zm-1.381 5.619a.876.876 0 0 0-1.237-1.237L7.001 8.763 5.62 7.382a.876.876 0 0 0-1.237 1.237l2 2a.876.876 0 0 0 1.237 0l4-4z" />
  </Icon>
);

export default SquareCheckSolidV6;
