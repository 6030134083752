import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "@shared/v2/Modal";
import { TrashSolidV6, UploadSolidV6 } from "@shared/v2/Icomoon";
import BaseEvent from "./BaseEvent";

const TimelineAgreementEvent = ({ event }) => {
  const [showModal, setShowModal] = useState(false);

  const showModalHandler = () => {
    setShowModal(true);
  };

  const hideModalHandler = () => {
    setShowModal(false);
  };

  const headline = (
    <div>
      <span className="bold-detail">{event.timeline_fields.updater_name} </span>
      {event.event_type === "agreement_deleted" ? "deleted" : "added"} a{" "}
      {event.timeline_fields.agreement_type} agreement
    </div>
  );

  return (
    <BaseEvent
      icon={event.event_type === "agreement_deleted" ? <TrashSolidV6 size="l" /> : <UploadSolidV6 size="l" />}
      title={headline}
      interactedAt={event.interacted_at_absolute}
      onClick={showModalHandler}
    >
      {event.timeline_fields.document_name && <div>Document Name: {event.timeline_fields.document_name}</div>}

      <Modal
        show={showModal}
        onHide={hideModalHandler}
        dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
        contentClassName="tw-max-w-[400px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
      >
        <Modal.Header title="Agreement" closeButton />
        <Modal.Body className="tw-flex-1 tw-overflow-auto tw-flex tw-flex-col tw-gap-[8px]">
          {headline}
          {event.timeline_fields.document_name && (
            <>
              <div className="tw-font-semibold">Details:</div>
              <div>Document Name: {event.timeline_fields.document_name}</div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </BaseEvent>
  );
};

TimelineAgreementEvent.propTypes = {
  event: PropTypes.shape({
    timeline_fields: PropTypes.shape({
      updater_name: PropTypes.string,
      agreement_type: PropTypes.string,
      document_name: PropTypes.string,
    }),
    event_type: PropTypes.string,
    interacted_at_absolute: PropTypes.string,
  }).isRequired,
};

export default TimelineAgreementEvent;
