import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import cards from "./cards.module.css";
import colors from "./colors.module.css";
import css from "./person-detail-styles.module.css";
import MarketReportCard from "../../shared/MarketReport/MarketReportCard";
import MarketReportPreview from "../../MarketReports/MarketReportPreview";
import CollapsibleElement from "./CollapsibleElement";
import Loading from "../../Goals/components/Loader";

const getApiHeaders = () => ({
  "Content-Type": "application/json",
  Accept: "application/json",
  "x-csrf-token": window.ReactOnRails.authenticityToken(),
});

const useMarketReports = ({ person, refreshKey }) => {
  const [marketReports, setMarketReports] = useState([]);
  const [isMarketReportModalOpen, setIsMarketReportModalOpen] = useState(false);

  useEffect(() => {
    if (!person?.uuid) return;
    axios
      .get(`/market_reports?uuid=${person?.uuid}`, { headers: getApiHeaders() })
      .then(({ data }) => setMarketReports(data))
      .catch(console.log);
  }, [person?.uuid, refreshKey]);

  return {
    actions: {
      deleteMarketReport: ({ id, primaryAgent }) =>
        axios
          .post(
            "/market_reports/delete_report",
            { id, primary_agent_uuid: primaryAgent },
            { headers: getApiHeaders() },
          )
          .then(() => setMarketReports((currentReports) => currentReports.filter((r) => r.id !== id)))
          .catch((err) => console.log(err)),
      toggleModal: (value) => setIsMarketReportModalOpen((o) => (typeof value === "boolean" ? value : !o)),
    },
    data: { marketReports, isMarketReportModalOpen },
  };
};

const MarketReports = ({ editMarketReport, openMarketReport, refreshKey, editMarketReportPanel2 }) => {
  const basePerson = useSelector((state) => state.personDetail.person);
  const person = {
    ...basePerson.data?.attributes,
    status: basePerson.data?.attributes.status,
    person_avatar: basePerson.data?.attributes.avatar_url,
    lead_id: basePerson.data?.attributes.lead.id,
    person_lead_type: basePerson.data?.attributes.lead_type?.charAt(0),
    new_market_report_link: basePerson.data?.attributes.new_market_report_link,
    display_a_street_address: basePerson?.included
      ?.filter((i) => i.type === "address" && i.id !== null)
      .map((i) => `${i.attributes?.street_address}`)[0],
  };
  const {
    actions,
    data: { isMarketReportModalOpen, marketReports },
  } = useMarketReports({ person, refreshKey });
  const primaryAgent = basePerson.data?.attributes.primary_agent_uuid;

  const mostRecentReport = useMemo(() => {
    const viewedReports = marketReports.filter((report) => report.most_recent_view !== null);
    if (viewedReports.length > 1) {
      return viewedReports.sort((a, b) => new Date(b.most_recent_view) - new Date(a.most_recent_view))[0];
    }
    return marketReports[0];
  }, [marketReports]);

  return (
    <CollapsibleElement
      id="pdp-market-reports"
      title="Market Reports"
      buttonText="+ ADD"
      buttonId="market-report-add"
      buttonClickFunction={openMarketReport}
      disabled={!basePerson.data?.attributes.main_email}
    >
      {!basePerson.data && <Loading />}
      <div id="person-market-reports-card">
        {marketReports.length === 0 ? (
          <div className={css.personDetailEmptyStateText}>
            Send Market Reports to contacts to let them know what’s happening in the real estate market in
            their area.
          </div>
        ) : (
          <MarketReportCard
            {...mostRecentReport}
            editReportFn={(id) => editMarketReport(id)}
            editMarketReportPanel2={editMarketReportPanel2}
            deleteReportFn={(id) => actions.deleteMarketReport({ id, primaryAgent })}
            showAdjustment
          />
        )}

        {marketReports.length > 1 && (
          <Row>
            <Col xs={12} className="tw-mt-[10px]">
              <a
                onClick={() => actions.toggleModal(true)}
                className={`${cards.viewAll} ${colors.brivityBlue}`}
              >
                VIEW ALL
              </a>
            </Col>
          </Row>
        )}

        {isMarketReportModalOpen && (
          <MarketReportPreview
            modalIsOpen
            modalOnly
            onClose={() => actions.toggleModal(false)}
            person={person}
            person_status={person.status}
            person_address={person.display_a_street_address}
            person_lead_type={person.person_lead_type}
            person_avatar={person.person_avatar}
            editReportFn={(id) => editMarketReport(id)}
            editMarketReportPanel2={editMarketReportPanel2}
            deleteReportFn={(id) => actions.deleteMarketReport({ id, primaryAgent })}
            openMarketReport={() => openMarketReport()}
          />
        )}
      </div>
    </CollapsibleElement>
  );
};

export default MarketReports;
