import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Button from "./Button";

const DeleteConfirm = ({ close, confirm }) => (
  <Modal
    show
    onHide={close}
    bsPrefix="lp-delete"
    backdropClassName="lp-delete-bck"
    dialogClassName="tw-max-w-xl tw-mt-20"
  >
    <div className="tw-p-6">
      <h2 className="tw-mb-10">Delete pond?</h2>
      <p>All leads in this pond will be reassigned to the account owner.</p>
      <div className="tw-flex tw-mt-20">
        <Button type="cancel" title="Cancel" onClick={close} />
        <div className="tw-grow" />
        <Button type="hard-remove" title="Delete" onClick={confirm} />
      </div>
    </div>
  </Modal>
);

DeleteConfirm.propTypes = {
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};

export default DeleteConfirm;
