import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import formatters from "@shared/formatters";

const validateNumber = (strOrNum, format = "number", precision = 2) => {
  const unformattedValue = (String(strOrNum) || "").replace(/[^0-9.]/g, "");
  const [, number = "", decimal = ""] = unformattedValue.match(/^([0-9]*)(\.[0-9]*)?/) || [];
  const decimalPortion = decimal.substring(0, format === "number" ? 0 : precision + 1);
  const finalValue = `${number ? formatters.number(number) : number}${decimalPortion || ""}`;
  return { unformattedValue, finalValue };
};

const NumberField = ({ className, defaultValue, format, precision, onChange, ...props }) => {
  const inputRef = useRef(null);
  const [selectionStart, setSelectionStart] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState(formatters.number(defaultValue));
  const isCurrency = format === "currency";

  useEffect(() => {
    const { finalValue } = validateNumber(defaultValue, format, precision);
    setValue(finalValue);
  }, [defaultValue]);

  // keep cursor at last character typed
  useEffect(() => {
    const input = inputRef.current;
    if (input.setSelectionRange && selectionStart) {
      input.setSelectionRange(selectionStart, selectionStart);
    }
  }, [inputRef, selectionStart]);

  const handleChange = (input, valueString) => {
    const { unformattedValue, finalValue } = validateNumber(valueString, format, precision);
    setSelectionStart(input.selectionStart + (finalValue.length - valueString.length));
    setValue(finalValue);
    onChange?.(unformattedValue);
  };

  return (
    <div
      className={`tw-flex tw-items-center tw-flex-1 tw-border tw-border-solid tw-border-neutral-gray-30 tw-rounded tw-min-w-[60px] ${
        (isFocused && "tw-bg-white tw-ring-2") || "tw-bg-tinted-gray-50"
      } ${className}`}
    >
      {isCurrency && <span className="tw-ml-[4px]">$</span>}
      <input
        ref={inputRef}
        size="1"
        className={`tw-flex-1 tw-outline-none tw-border-none tw-bg-transparent ${
          isCurrency ? "tw-py-[8px] tw-px-[4px]" : "tw-p-[8px]"
        }`}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        value={value}
        onPaste={(e) => {
          e.preventDefault();
          const valueString = (e.clipboardData || window.clipboardData).getData("text");
          handleChange(e.target, valueString);
        }}
        onKeyDown={(e) => {
          // allow standard keys
          if (/^(?:Backspace|Enter|Tab|Arrow)/.test(e.key)) return;
          // allow numbers
          if (/^[0-9.]$/.test(e.key)) return;
          // allow copy/pasting
          if ((e.metaKey || e.ctrlKey) && (e.key === "c" || e.key === "v")) return;

          e.preventDefault();
        }}
        onChange={(e) => handleChange(e.target, e.target.value)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </div>
  );
};

NumberField.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.oneOf(["decimal", "number", "currency"]),
  precision: PropTypes.number,
  id: PropTypes.string,
  onChange: PropTypes.func,
};

NumberField.defaultProps = {
  className: "",
  defaultValue: undefined,
  format: "number",
  precision: 2,
  id: undefined,
  onChange: undefined,
};

export default NumberField;
