import React, { useState } from "react";
import PropTypes from "prop-types";
import { SquareCheckSolidV6 } from "@shared/v2/Icomoon";
import Modal from "@shared/v2/Modal";
import BaseEvent from "./BaseEvent";

const TaskEvent = ({ event }) => {
  const [showModal, setShowModal] = useState(false);

  const headline = (
    <div>
      <span className="bold-detail">{event.owning_user_name}</span>
      {" completed a task"}
    </div>
  );

  return (
    <BaseEvent
      icon={<SquareCheckSolidV6 size="l" />}
      title={headline}
      interactedAt={event.interacted_at_absolute}
      onClick={() => setShowModal(true)}
    >
      {headline}
      <div className="timeline-description-text">Details: {event.description}</div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
        contentClassName="tw-max-w-[400px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
      >
        <Modal.Header title="Completed Task" closeButton />
        <Modal.Body>
          {headline}
          <div className="details-header">Details: {event.description}</div>
        </Modal.Body>
      </Modal>
    </BaseEvent>
  );
};

TaskEvent.propTypes = {
  event: PropTypes.shape().isRequired,
};

export default TaskEvent;
