/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const LinkedinRegularV6 = (props) => (
  <Icon {...props} name="Linkedin Regular V6">
    <path d="M3.581 16H.264V5.318h3.317V16zM1.921 3.86C.86 3.86 0 2.981 0 1.921A1.924 1.924 0 0 1 1.921 0a1.924 1.924 0 0 1 1.921 1.921c0 1.061-.861 1.939-1.921 1.939zM15.996 16h-3.31v-5.2c0-1.239-.025-2.829-1.725-2.829-1.725 0-1.989 1.346-1.989 2.739v5.289H5.658V5.317h3.181v1.457h.046c.443-.839 1.525-1.725 3.139-1.725 3.357 0 3.974 2.211 3.974 5.082v5.868h-.004z" />
  </Icon>
);

export default LinkedinRegularV6;
