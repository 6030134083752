import { actionTypes } from "./actionTypes";
import {
  createPondApiFn,
  updatePondApiFn,
  deletePondApiFn,
  loadMoreApiFn,
  getAssignableUsersApiFn,
  duplicateCheckApiFn,
} from "../api";

import {
  // addPondToSidebar,
  updatePondInSidebar,
  createPondStart,
  createPondSuccess,
  createPondError,
  updatePondStart,
  updatePondSuccess,
  updatePondError,
  removePondSuccess,
  removePondError,
  duplicateCheckSuccess,
  duplicateCheckError,
  loadMorePondsSuccess,
  loadMorePondsError,
  getAssignableUsersSuccess,
  getAssignableUsersError,
} from "./default";

export const createPond = (model) => async (dispatch) => {
  dispatch(createPondStart());
  const onSuccess = (response) => {
    dispatch(createPondSuccess({ leadPond: response.data.lead_pond }));
    // addPondToSidebar(response.data.lead_pond)
  };
  const onFailure = (error) => dispatch(createPondError({ error }));
  createPondApiFn(model).then(onSuccess).catch(onFailure);
};

export const updatePond = (model) => async (dispatch) => {
  dispatch(updatePondStart());
  const onSuccess = (response) => {
    updatePondInSidebar(response.data.lead_pond);
    dispatch(updatePondSuccess({ leadPond: response.data.lead_pond }));
  };
  const onFailure = (error) => dispatch(updatePondError({ error }));
  updatePondApiFn(model).then(onSuccess).catch(onFailure);
};

export const removePond = (id) => async (dispatch) => {
  const onSuccess = (response) => dispatch(removePondSuccess({ id: response.data.id }));
  const onFailure = (error) => dispatch(removePondError({ error }));
  deletePondApiFn(id).then(onSuccess).catch(onFailure);
};

// eslint-disable-next-line consistent-return
export const duplicateCheck = (name) => async (dispatch) => {
  if (name <= "") {
    // ensure we have a name to check
    return dispatch({ type: actionTypes.duplicateCheckClear });
  }
  dispatch({ type: actionTypes.duplicateCheck });
  const onSuccess = (response) => dispatch(duplicateCheckSuccess({ results: response.data.page }));
  const onFailure = (error) => dispatch(duplicateCheckError({ error }));
  duplicateCheckApiFn(name).then(onSuccess).catch(onFailure);
};

export const loadMorePonds = (page, pageSize) => async (dispatch) => {
  const onSuccess = (response) => dispatch(loadMorePondsSuccess(response.data));
  const onFailure = (error) => dispatch(loadMorePondsError({ error }));
  loadMoreApiFn(page, pageSize).then(onSuccess).catch(onFailure);
};

export const getAssignableUsers = () => async (dispatch) => {
  const onSuccess = (response) => dispatch(getAssignableUsersSuccess({ users: response.data }));
  const onFailure = (error) => dispatch(getAssignableUsersError({ error }));
  getAssignableUsersApiFn().then(onSuccess).catch(onFailure);
};
