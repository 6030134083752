// eslint-disable-next-line import/prefer-default-export
export const actionTypes = {
  openPondModal: "OPEN_POND_MODAL",
  closePondModal: "CLOSE_POND_MODAL",

  createPond: "CREATE_POND",
  createPondSuccess: "CREATE_POND_SUCCESS",
  createPondError: "CREATE_POND_ERROR",

  updatePond: "UPDATE_POND",
  updatePondSuccess: "UPDATE_POND_SUCCESS",
  updatePondError: "UPDATE_POND_ERROR",

  removePond: "REMOVE_POND",
  removePondSuccess: "REMOVE_POND_SUCCESS",
  removePondError: "REMOVE_POND_ERROR",

  duplicateCheck: "DUPLICATE_CHECK",
  duplicateCheckSuccess: "DUPLICATE_CHECK_SUCCESS",
  duplicateCheckError: "DUPLICATE_CHECK_ERROR",
  duplicateCheckClear: "DUPLICATE_CHECK_CLEAR",

  loadMorePonds: "LOAD_MORE_DATA",
  loadMorePondsSuccess: "LOAD_MORE_DATA_SUCCESS",
  loadMorePondsError: "LOAD_MORE_DATA_ERROR",

  getAssignableUsers: "ASSIGNABLE_USERS",
  getAssignableUsersSuccess: "ASSIGNABLE_USERS_SUCCESS",
  getAssignableUsersError: "ASSIGNABLE_USERS_ERROR",

  showAlert: "SHOW_ALERT",
  hideAlert: "HIDE_ALERT",
};
