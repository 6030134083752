import React from "react";
import { useDispatch } from "react-redux";
import { PhoneHangupSolidV6 } from "@shared/v2/Icomoon";
import Button from "@shared/v2/Button";
import { useCallState, usePhoneNumber } from "../../reducers/dialerReducer/selectors";
import CALL_STATES from "../utils/states";
import { setCallState } from "../../reducers/dialerReducer/dialerReducer";
import CallRecordingTooltip from "./CallRecordingTooltip";
import { useOutboundCallRecordingSetting } from "../../reducers/layoutReducer/selectors";

const CallButton = () => {
  const dispatch = useDispatch();
  const { outboundNotificationMessage, outboundRecording } = useOutboundCallRecordingSetting();
  const callState = useCallState();
  const phoneNumber = usePhoneNumber();
  const initialState = callState === CALL_STATES.Init;

  return (
    <div className="tw-flex tw-flex-col tw-items-end tw-gap-[4px]">
      <CallRecordingTooltip
        disabled={outboundRecording === "disabled"}
        willNotify={outboundNotificationMessage}
        willRecord={outboundRecording === "automatic"}
      />
      <Button
        disabled={
          initialState ? !phoneNumber || phoneNumber?.meta.callUnsubscribed : callState === CALL_STATES.Ended
        }
        onClick={() => dispatch(setCallState(initialState ? CALL_STATES.Started : CALL_STATES.Ended))}
        size="medium"
        schema={initialState ? "solid-green" : "solid-red"}
      >
        <PhoneHangupSolidV6 size="m" className="tw-mr-4px" />
        <span>{initialState ? "" : "END"} CALL</span>
      </Button>
    </div>
  );
};

export default CallButton;
