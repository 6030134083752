/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const LocationArrowSolidV6 = (props) => (
  <Icon {...props} name="Location Arrow Solid V6">
    <path d="M9.925 13.878a1 1 0 0 1-1.119.603c-.469-.094-.834-.503-.834-.981V8h-5.5a1.001 1.001 0 0 1-.35-1.925l11.001-4.501a.998.998 0 0 1 1.303 1.304l-4.5 10.999z" />
  </Icon>
);

export default LocationArrowSolidV6;
