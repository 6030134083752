/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CommentsSolidV6 = (props) => (
  <Icon {...props} name="Comments Solid V6">
    <path d="M10.4 6c0-2.43-2.327-4.4-5.2-4.4S0 3.57 0 6c0 .989.391 1.899 1.042 2.635a6.834 6.834 0 0 1-.99 1.442c-.052.055-.067.137-.036.209s.099.114.176.114c.959 0 1.769-.278 2.393-.601.77.379 1.66.601 2.615.601 2.873 0 5.2-1.97 5.2-4.4zm4.59 6.693C15.62 11.948 16 11.015 16 10c0-2.43-2.15-4.4-4.8-4.4l-.023.001c.011.131.023.264.023.399 0 2.466-1.992 4.53-4.652 5.063.52 1.915 2.38 3.337 4.652 3.337.842 0 1.633-.2 2.321-.549a5.163 5.163 0 0 0 2.286.549.193.193 0 0 0 .139-.324c-.007-.008-.549-.593-.957-1.383z" />
  </Icon>
);

export default CommentsSolidV6;
