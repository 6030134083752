import { endOfMonth, endOfYear, format, isBefore, startOfMonth, startOfYear, sub } from "date-fns";
import { useEffect, useMemo, useState } from "react";

const TIME_FRAME_OPTIONS = [
  { label: "All Time", value: "all_time" },
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Last 7 days",
    value: "last_7_days",
  },
  {
    label: "Last 14 days",
    value: "last_14_days",
  },
  {
    label: "Last 30 days",
    value: "last_30_days",
  },
  {
    label: "This Month",
    value: "this_month",
  },
  {
    label: "Last Month",
    value: "last_month",
  },
  {
    label: "This Year",
    value: "this_year",
  },
  {
    label: "Last Year",
    value: "last_year",
  },
];

const CUSTOM_OPTION = { label: "Custom", value: "custom" };

const useTimeFrameOptions = ({ includeCustom = false }) => {
  const [timeFrame, setTimeFrame] = useState(TIME_FRAME_OPTIONS[7]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const timeFrameOptions = useMemo(
    () => (includeCustom ? [...TIME_FRAME_OPTIONS, CUSTOM_OPTION] : TIME_FRAME_OPTIONS),
    [includeCustom],
  );

  useEffect(() => {
    let startAt = null;
    let endAt = null;
    switch (timeFrame.value) {
      case "all_time":
        startAt = null;
        endAt = null;
        break;
      case "today":
        startAt = format(new Date(), "yyyy-MM-dd");
        endAt = format(new Date(), "yyyy-MM-dd");
        break;
      case "last_7_days":
        startAt = format(sub(new Date(), { days: 7 }), "yyyy-MM-dd");
        endAt = format(new Date(), "yyyy-MM-dd");
        break;
      case "last_14_days":
        startAt = format(sub(new Date(), { days: 14 }), "yyyy-MM-dd");
        endAt = format(new Date(), "yyyy-MM-dd");
        break;
      case "last_30_days":
        startAt = format(sub(new Date(), { days: 30 }), "yyyy-MM-dd");
        endAt = format(new Date(), "yyyy-MM-dd");
        break;
      case "this_month":
        startAt = format(startOfMonth(new Date()), "yyyy-MM-dd");
        endAt = format(new Date(), "yyyy-MM-dd");
        break;
      case "last_month":
        startAt = format(startOfMonth(sub(new Date(), { months: 1 })), "yyyy-MM-dd");
        endAt = format(endOfMonth(sub(new Date(), { months: 1 })), "yyyy-MM-dd");
        break;
      case "last_year":
        startAt = format(startOfYear(sub(new Date(), { years: 1 })), "yyyy-MM-dd");
        endAt = format(endOfYear(sub(new Date(), { years: 1 })), "yyyy-MM-dd");
        break;
      default: // this_year
        startAt = format(startOfYear(new Date()), "yyyy-MM-dd");
        endAt = format(endOfYear(new Date()), "yyyy-MM-dd");
    }
    setStartDate(startAt);
    setEndDate(endAt);
  }, [timeFrame]);

  const dateRange = useMemo(() => ({ startAt: startDate, endAt: endDate }), [startDate, endDate]);

  const error = useMemo(() => {
    if (startDate && endDate && !isBefore(startDate, endDate)) {
      return "End date must be after start date";
    }
    return "";
  }, [startDate, endDate]);

  return {
    actions: { setStartDate, setEndDate },
    error,
    timeFrameOptions,
    timeFrame,
    setTimeFrame,
    dateRange,
  };
};

export default useTimeFrameOptions;
