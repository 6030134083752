import React, { useState } from "react";
import PropTypes from "prop-types";
import capitalize from "capitalize";
import Modal from "@shared/v2/Modal";
import BaseEvent from "./BaseEvent";

const PublicActivityEvent = ({ event }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <BaseEvent
        icon={<i className={event.icon} />}
        title={event.interaction_type}
        onClick={() => setShowModal(true)}
        interactedAt={event.interacted_at_absolute}
      >
        {event.object_attributes.comment}
      </BaseEvent>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
        contentClassName="tw-max-w-[480px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
      >
        <Modal.Header title={capitalize(event.interaction_type)} closeButton />
        <Modal.Body>{event.object_attributes.comment}</Modal.Body>
      </Modal>
    </>
  );
};

PublicActivityEvent.propTypes = {
  event: PropTypes.shape().isRequired,
};

export default PublicActivityEvent;
