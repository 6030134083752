import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FieldLabel from "@shared/v2/FieldLabel";

const MarketReportPresenceSelect = ({
  previousSelectedReportPresence,
  setIsLastMarketReportDropdownDisabled,
}) => {
  function uncheckInput(inputId) {
    document.getElementById(inputId).checked = false;
  }

  useEffect(() => {
    if (previousSelectedReportPresence === "true") {
      document.getElementById("leads_filter_selected_market_reports_filters_true").checked = true;
    } else if (previousSelectedReportPresence === "false") {
      document.getElementById("leads_filter_selected_market_reports_filters_false").checked = true;
      setIsLastMarketReportDropdownDisabled(true);
    }
  }, []);

  return (
    <div className="tw-flex tw-flex-col tw-gap-[4px] tw-mb-[10px]">
      <FieldLabel htmlFor="leads_filter_selected_market_reports_filters_false" label="Market Reports" />
      <div className="tw-flex tw-flex-wrap tw-gap-[4px]">
        <input
          className="filter-checkbox"
          type="checkbox"
          value="false"
          name="leads_filter[selected_market_reports_filters][]"
          id="leads_filter_selected_market_reports_filters_false"
        />
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
        <label
          className="danger-label"
          htmlFor="leads_filter_selected_market_reports_filters_false"
          onClick={() => {
            uncheckInput("leads_filter_selected_market_reports_filters_true");
            setIsLastMarketReportDropdownDisabled((isDisabled) => !isDisabled);
          }}
        >
          None Created
        </label>
        <input
          className="filter-checkbox"
          type="checkbox"
          value="true"
          name="leads_filter[selected_market_reports_filters][]"
          id="leads_filter_selected_market_reports_filters_true"
        />
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
        <label
          className="info-label"
          htmlFor="leads_filter_selected_market_reports_filters_true"
          onClick={() => {
            uncheckInput("leads_filter_selected_market_reports_filters_false");
            setIsLastMarketReportDropdownDisabled(false);
          }}
        >
          Has Reports
        </label>
      </div>
    </div>
  );
};

MarketReportPresenceSelect.propTypes = {
  previousSelectedReportPresence: PropTypes.string.isRequired,
  setIsLastMarketReportDropdownDisabled: PropTypes.func.isRequired,
};

export default MarketReportPresenceSelect;
