/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TextButton from "@shared/v2/TextButton";
import Toggle from "@shared/v2/Toggle";
import TextComboInput from "@shared/v2/TextComboInput";
import {
  ArrowRightSolidV6,
  DollarSignSolidV6,
  MinusSolidV6,
  PercentSolidV6,
  PlusSolidV6,
} from "@shared/v2/Icomoon";
import ButtonToggle from "@shared/v2/ButtonToggle";
import { formatNumber, isValidValue } from "@shared/v2/inputFormatUtils";
import * as creators from "../../actions/creators";
import HomeEstimateValue from "../HomeEstimateValue/HomeEstimateValue";
import { getHomeValue } from "../../api";
import { calculateHomeAdjustment } from "../../../AddMarketReportModal/components/Panel2/helpers";

const STYLE = {
  header: "tw-text-18d tw-text-gray-75 tw-mt-18px tw-mb-24px",
  subHeader: "tw-text-14d tw-text-gray-50 tw-font-semibold",
};

const REPORT_TYPE_HASH = {
  market: false,
  home_value: true,
};

const HomeValueEstimate = ({ setFormData, formData, report }) => {
  const [homeAdjVal, setHomeAdjVal] = useState(0);
  const [showAdjustment, setShowAdjustment] = useState(false);

  useEffect(() => {
    if (report?.street_address) {
      (async () => {
        const { response } = await getHomeValue({
          streetAddress: report.street_address,
          city: report.city,
          state: report.state_abbreviation,
          postalCode: report.postal_code,
        });

        setHomeAdjVal(Math.floor(response.data.value));
      })();
    }
  }, [report?.street_address]);

  useEffect(() => {
    setFormData({
      home_value_adjustment_as_percentage: report?.home_value_adjustment_as_percentage,
      home_value_adjustment: Math.abs(report?.home_value_adjustment * 1),
      report_type: report?.report_type,
      isHomeAdjPositive: report?.home_value_adjustment >= 0,
    });
  }, [report]);

  const handleClickShow = () => {
    setShowAdjustment(!showAdjustment);
  };

  return (
    <>
      <div>
        <div className={`tw-text-18d tw-text-gray-75 tw-mt-18px ${report?.has_address && "tw-mb-24px"}`}>
          Home Value Estimate
        </div>
        {!report?.has_address && (
          <div className="tw-text-14d tw-text-gray-50 tw-mb-24px">
            Enter an address in the search area to include a home value estimate in this market report.{" "}
          </div>
        )}
        <Toggle
          id="homeValue"
          name="homeValue"
          value={REPORT_TYPE_HASH[formData.report_type]}
          checked={REPORT_TYPE_HASH[formData.report_type]}
          onChange={() =>
            setFormData({
              report_type: formData.report_type === "market" ? "home_value" : "market",
              home_value_adjustment: null,
              home_value_adjustment_as_percentage: false,
            })
          }
          disabled={!report?.has_address}
          textClassName="tw-font-semibold"
          text="Include Home Value in Market Report"
        />
        {report?.has_address && (
          <>
            <div className="tw-mt-24px tw-flex tw-gap-24px tw-items-center">
              <HomeEstimateValue value={`$ ${Number(homeAdjVal).toLocaleString()}`} />
              {Boolean(formData?.home_value_adjustment) && formData?.home_value_adjustment * 1 !== 0 && (
                <>
                  <ArrowRightSolidV6 size="l" className="tw-text-gray-75" />
                  <HomeEstimateValue
                    header="Adjusted Est."
                    value={calculateHomeAdjustment({
                      homeAdj: formData?.home_value_adjustment,
                      isHomeAdjPercentage: formData?.home_value_adjustment_as_percentage,
                      isHomeAdjPositive: formData?.isHomeAdjPositive,
                      homeAdjVal,
                    })}
                    onClose={() =>
                      setFormData({
                        isHomeAdjPositive: true,
                        isHomeAdjPercentage: false,
                        home_value_adjustment: 0,
                      })
                    }
                  />
                </>
              )}
            </div>
            {showAdjustment && (
              <div>
                <div className="tw-mt-24px">
                  <div className={STYLE.subHeader}>Adjust Home Value Estimate</div>
                  <div className="tw-flex tw-gap-4px tw-mt-4px">
                    <ButtonToggle
                      options={[
                        { label: <PlusSolidV6 size="l" />, value: true },
                        { label: <MinusSolidV6 size="l" />, value: false },
                      ]}
                      value={formData.isHomeAdjPositive}
                      onChange={(e) => {
                        setFormData({ isHomeAdjPositive: e.target.value === "true" });
                      }}
                    />
                    <TextComboInput
                      leading={!formData.home_value_adjustment_as_percentage ? "$" : ""}
                      trailing={formData.home_value_adjustment_as_percentage ? "%" : ""}
                      value={`${Math.floor(Math.abs(formData.home_value_adjustment))}`}
                      onChange={(e) => {
                        if (isValidValue(e.target.value)) {
                          setFormData({ home_value_adjustment: Number(formatNumber(e.target.value.trim())) });
                        }
                      }}
                      className="tw-flex-1"
                    />
                    <ButtonToggle
                      options={[
                        { label: <DollarSignSolidV6 size="l" />, value: false },
                        { label: <PercentSolidV6 size="l" />, value: true },
                      ]}
                      value={formData.home_value_adjustment_as_percentage}
                      onChange={(e) => {
                        setFormData({
                          home_value_adjustment_as_percentage: e.target.value === "true",
                          home_value_adjustment: 0,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="tw-mt-24px tw-text-14d tw-text-gray-50">
                  The adjusted estimate will use the most recent automated estimate and then add or subtract a
                  dollar or percentage amount. This will appear on the home value section of the Market
                  Report.
                </div>
              </div>
            )}
            <TextButton className="tw-p-0 tw-pt-[8px]" onClick={handleClickShow}>
              {!showAdjustment ? "Show estimate Adjustment" : "Hide estimate Adjustment"}
            </TextButton>
          </>
        )}

        <hr className="tw-border-x-0 tw-border-y tw-border-solid tw-border-neutral-gray-10 tw-m-0 tw-my-24px" />
      </div>
    </>
  );
};

HomeValueEstimate.propTypes = {
  formData: PropTypes.shape({
    home_value_adjustment: PropTypes.number,
    home_value_adjustment_as_percentage: PropTypes.bool,
    isHomeAdjPositive: PropTypes.bool,
  }),
  setFormData: PropTypes.func,
  report: PropTypes.shape({}),
};

HomeValueEstimate.defaultProps = {
  formData: {
    home_value_adjustment: 0,
    home_value_adjustment_as_percentage: false,
    isHomeAdjPositive: true,
  },
  setFormData: null,
  report: null,
};
const mapStateToProps = (state) => ({
  formData: state.addMarketReport.form1,
  report: state.addMarketReport.savedMarketReport,
});
export default connect(mapStateToProps, creators)(HomeValueEstimate);
