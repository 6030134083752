import React, { useState, useEffect } from 'react';
import Modal from '@shared/v2/Modal';
import Button from '@shared/v2/Button';
import axios from "axios";
import DncBanner from "../../Interactions/PhoneCall/components/DncBanner";
import { CircleInfoSolidV6, ToggleOffSolidV6, VolumeHighSolidV6 } from '@shared/v2/Icomoon';
import Tooltip from '@shared/v2/Tooltip';
import { useFeatureFlags, useIsAdmin } from '../../reducers/layoutReducer/selectors';

const ConfirmationModal = ({ selectedLeadUuids, closeConfirmationModal, createDialerSession, show, isLoading }) => {
  const [validLeadCounts, setValidLeadCounts] = useState({ total_leads: 0, valid_leads: 0 });
  const [loadingValidLeads, setLoadingValidLeads] = useState(true);
  const featureFlags = useFeatureFlags();

  const isAdmin = useIsAdmin();

  const checkValidLeads = () => {
    axios.post('/dialer/check_valid_leads',
      { lead_uuids: selectedLeadUuids, authenticity_token: ReactOnRails.authenticityToken() })
      .then(resp => {
        setValidLeadCounts(resp.data);
        setLoadingValidLeads(false);
      });
  }

  useEffect(() => {
    if (show) { checkValidLeads() }
  }, [show]);

  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      show={show}
      contentClassName="tw-m-auto tw-max-h-[509px] tw-flex tw-flex-col tw-w-[657px] tw-p-[32px]"
      onHide={closeConfirmationModal}>
      <Modal.Header
        className='tw-mb-32px'
        title='Brivity Dialer'
        closeButton />
      <Modal.Body className='tw-overflow-x-hidden'>
        <div className='phone-call'>
          <div className='tw-w-full tw-text-12d tw-text-white tw-font-semibold tw-mb-32px'>
            <div className={`tw-bg-gray-50 tw-py-10px tw-px-16px tw-rounded-t-[8px] ${featureFlags.callRecording ? '' : 'tw-rounded-b-[8px]'}`}>
              {loadingValidLeads ? (
                <span><i className="fa fa-spinner fa-pulse" /> Loading Selected Leads</span>
              ) : (
                <span>{`${validLeadCounts.valid_leads} of ${validLeadCounts.total_leads} contacts can be dialed`}</span>
              )}
            </div>

            {featureFlags.callRecording &&
              <div className="tw-bg-gray-5 tw-p-16px  tw-rounded-b-[8px] tw-text-14d tw-font-normal tw-flex tw-justify-between tw-items-center">
                <div className='tw-flex tw-flex-col tw-gap-8px'>
                  <div className="tw-text-gray-75 tw-flex tw-gap-6px tw-items-center">
                    <ToggleOffSolidV6 size='l' />
                    <span>
                      Recording will not start until you press record for each call
                    </span>
                  </div>
                  <div className="tw-text-gray-75 tw-flex tw-gap-6px tw-items-center">
                    <VolumeHighSolidV6 size='l' />
                    <span>A notification message will play when the recording starts</span>
                  </div>
                </div>
                <Tooltip
                  placement="top"
                  innerClassName="tw-max-w-[193px]"
                  trigger={<CircleInfoSolidV6 size='l' className='tw-text-gray-50' />}
                  content={
                    <div className="tw-text-left">
                      {isAdmin ? 'Configure your recording options in Phone Settings' : ' Contact your admin if you want to change your call recording settings'}
                    </div>
                  }
                />
              </div>
            }
          </div>
          {!loadingValidLeads && <DncBanner />}
          <p className='tw-mt-32px'>
            Start a dialer session with a list of leads!
            After you end a call and set the outcome, Brivity will automatically dial the next contact.
            Leads with invalid or no phone numbers will not be included.
          </p>
          <p className='tw-font-semibold'>Note: Click 'Hang Up' within Brivity CRM to end calls. Hanging up your phone will end the dialer session.</p>
        </div>
      </Modal.Body>
      <Modal.Footer className='tw-flex tw-justify-between tw-mt-32px'>
        <Button size="medium" schema="tertiary" onClick={closeConfirmationModal}>
          Close
        </Button>
        <Button
          size="medium"
          onClick={createDialerSession}
          isLoading={isLoading}
          disabled={validLeadCounts.valid_leads === 0}
        >
          Start
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;
