import { actionTypes } from "../LeadPonds/actions/actionTypes";

const defaultState = {
  isModalOpen: false,
  alerts: false,
  isAdmin: false,
  leadPondsCollection: [],
  usersCollection: [],
  pageSize: 40,
  totalPages: 0,
  isSaving: 0,
  hasSaveError: 0,
  isLoadingMore: 0,
  hasLoadingMoreError: 0,
  isCheckingDuplicate: 0,
  page: 1,
  pondData: null,
  errors: "",
  leadPondsNotificationsOn: false,
  maximumPondsReached: false,
};

const leadPondsReducer = (state = defaultState, payload) => {
  switch (payload.type) {
    case actionTypes.openPondModal:
      return {
        ...state,
        isModalOpen: true,
        pondData: payload.pond,
      };
    case actionTypes.closePondModal:
      return {
        ...state,
        isModalOpen: false,
        hasDuplicate: 0,
        pondData: null,
        hasError: false,
        errorMessage: undefined,
      };

    case actionTypes.showAlert:
      return {
        ...state,
        alert: payload,
        errors: null,
      };
    case actionTypes.hideAlert:
      return {
        ...state,
        alert: false,
      };

    case actionTypes.createPond:
      return {
        ...state,
        isSaving: 1,
        hasSaveError: 0,
      };
    case actionTypes.createPondSuccess:
      return {
        ...state,
        isSaving: 0,
        leadPondsCollection: [...state.leadPondsCollection, payload.leadPond],
        hasSaveError: 0,
      };
    case actionTypes.createPondError:
      return {
        ...state,
        isSaving: 0,
        hasSaveError: 1,
      };

    case actionTypes.updatePond:
      return {
        ...state,
        isSaving: 1,
        hasSaveError: 0,
      };
    case actionTypes.updatePondSuccess:
      // update the passed back model
      return {
        ...state,
        leadPondsCollection: [...state.leadPondsCollection].map((lp) => {
          if (lp.id === payload.leadPond.id) {
            return payload.leadPond;
          }
          return lp;
        }),
        isSaving: 0,
        hasSaveError: 0,
      };
    case actionTypes.updatePondError:
      return {
        ...state,
        isSaving: 0,
        hasSaveError: 1,
      };

    case actionTypes.removePond:
      return {
        ...state,
        hasSaveError: 0,
      };
    case actionTypes.removePondSuccess:
      // update the passed back model
      return {
        ...state,
        leadPondsCollection: [...state.leadPondsCollection].filter((lp) => lp.id !== payload.id),
        hasSaveError: 0,
      };
    case actionTypes.removePondError:
      return {
        ...state,
        hasSaveError: 1,
      };

    case actionTypes.loadMorePonds:
      return {
        ...state,
        isLoadingMore: 1,
        hasLoadingMoreError: 0,
      };
    case actionTypes.loadMorePondsSuccess:
      return {
        ...state,
        page: state.page + 1, // increment to the next page
        isLoadingMore: 0,
        leadPondsCollection: [...state.leadPondsCollection, ...payload.page],
        totalPages: payload.total_pages,
      };
    case actionTypes.loadMorePondsError:
      return {
        ...state,
        isLoadingMore: 0,
        hasLoadingMoreError: 1,
      };

    case actionTypes.getAssignableUsers:
      return {
        ...state,
        isLoadingUsers: 1,
        hasLoadingUsersError: 0,
      };
    case actionTypes.getAssignableUsersSuccess:
      return {
        ...state,
        isLoadingUsers: 0,
        hasLoadingUsersError: 0,
        usersCollection: [...payload.users],
      };
    case actionTypes.getAssignableUsersError:
      return {
        ...state,
        isLoadingUsers: 0,
        hasLoadingUsersError: 1,
      };
    case actionTypes.duplicateCheck:
      return {
        ...state,
        isCheckingDuplicate: 1,
        hasDuplicate: 0,
        duplicateError: 0,
      };
    case actionTypes.duplicateCheckClear:
      return {
        ...state,
        isCheckingDuplicate: 0,
        hasDuplicate: 0,
        duplicateError: 0,
      };
    case actionTypes.duplicateCheckSuccess:
      return {
        ...state,
        isCheckingDuplicate: 0,
        hasDuplicate: (() => {
          // check if this is the pond were editing or if it's new
          const fnd = state.pondData ? payload.results.filter((r) => r.id === state.pondData.id) : [];
          const dup = payload.results.length > 0 && fnd.length === 0; // we ignore duplicates if its the same pond were editing
          return dup;
        })(),
        duplicateError: 0,
      };
    case actionTypes.duplicateCheckError:
      return {
        ...state,
        isCheckingDuplicate: 0,
        hasDuplicate: 0,
        duplicateError: 1,
      };

    default:
      return state;
  }
};

export default leadPondsReducer;
