/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const VolumeHighSolidV6 = (props) => (
  <Icon {...props} name="Volume High Solid V6">
    <path d="M10.315 6.15a.6.6 0 0 0-.76.928c.282.231.444.566.444.899 0 .354-.162.69-.445.921a.599.599 0 1 0 .76.929c.563-.439.886-1.112.886-1.849s-.322-1.367-.885-1.827zm1.513-1.845c-.256-.208-.634-.172-.845.083s-.173.634.084.843c.848.67 1.333 1.7 1.333 2.745s-.486 2.053-1.333 2.768a.599.599 0 1 0 .76.926c1.127-.9 1.772-2.247 1.772-3.695s-.645-2.752-1.772-3.672zm1.532-1.87a.598.598 0 1 0-.76.927c1.398 1.146 2.201 2.836 2.201 4.615s-.802 3.493-2.201 4.638a.599.599 0 0 0-.084.843c.118.167.29.242.465.242a.598.598 0 0 0 .381-.136c1.677-1.374 2.639-3.402 2.639-5.587s-.962-4.169-2.64-5.542zm-5.83.04a.802.802 0 0 0-.861.132L3.295 5.603H1.2A1.2 1.2 0 0 0 0 6.802V9.2a1.2 1.2 0 0 0 1.2 1.199h2.096l3.373 2.995a.802.802 0 0 0 .86.131.794.794 0 0 0 .471-.727V3.204c0-.314-.182-.6-.47-.728z" />
  </Icon>
);

export default VolumeHighSolidV6;
