import React from "react";
import { useSelector } from "react-redux";
import PageHeader from "../components/PageHeader";
import PondTable from "../components/PondTable";
import PondModal from "../components/PondModal";
import "../styles/LeadPonds.sass";

const LeadPonds = () => {
  const notificationsOn = useSelector((state) => state.leadPonds.leadPondsNotificationsOn);
  const alert = useSelector((state) => state.leadPonds.alert);
  const isDisabled = useSelector((state) => state.leadPonds.maximumPondsReached);

  return (
    <>
      {alert && (
        <div className={`tw-w-full tw-absolute top: 0; tw-z-50 tw-bg-${alert.color}`} role="alert">
          {alert.message}
        </div>
      )}
      <div id="content" className="lead-ponds-view tw-relative tw-flex tw-flex-col">
        <PageHeader
          title="Lead Pond"
          copy="Use Lead Ponds for storing leads that are not assigned to a specific agent. Users given permission to use a Lead Pond will have access to work and claim leads to pull them out of the Lead Pond."
          buttonTitle="New lead pond"
          notification={{
            title: `Lead Pond Notification: ${notificationsOn === "true" ? "On" : "Off"}`,
            link: "/notifications",
          }}
          isDisabled={isDisabled}
          buttonTooltipContent={
            <>
              You&apos;ve reached the limit of
              <br />
              100 lead ponds. You must delete a<br />
              pond to create a new pond.
            </>
          }
        />
        <PondTable />
        <PondModal />
      </div>
    </>
  );
};

export default LeadPonds;
