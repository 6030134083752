import axios from "axios";

export const createPondApiFn = (model) =>
  axios.post("/lead_ponds", {
    ...model,
    authenticity_token: ReactOnRails.authenticityToken(),
  });

export const updatePondApiFn = (model) =>
  axios.put(`/lead_ponds/${model.id}`, {
    ...model,
    authenticity_token: ReactOnRails.authenticityToken(),
  });

export const deletePondApiFn = (id) =>
  axios.delete(`/lead_ponds/${id}`, {
    authenticity_token: ReactOnRails.authenticityToken(),
    headers: {
      "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
    },
  });

export const duplicateCheckApiFn = (name) =>
  axios.get(`/lead_ponds?format=json&duplicate_check=${name}`, {
    authenticity_token: ReactOnRails.authenticityToken(),
  });

export const loadMoreApiFn = (page, pageSize) =>
  axios.get(`/lead_ponds?format=json&page=${page}&per_page=${pageSize}`, {
    authenticity_token: ReactOnRails.authenticityToken(),
  });

export const getAssignableUsersApiFn = () =>
  axios.get(`/account_active_agents`, {
    authenticity_token: ReactOnRails.authenticityToken(),
  });
