import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ArrowsRotateSolidV6, TrashSolidV6, UploadSolidV6 } from "@icons/index";
import Modal from "@shared/v2/Modal";
import BaseEvent from "./BaseEvent";

const Icon = ({ action }) => {
  if (action === "replaced") return <ArrowsRotateSolidV6 size="l" />;
  if (action === "deleted") return <TrashSolidV6 size="l" />;
  if (action === "added") return <UploadSolidV6 size="l" />;
  return null;
};

Icon.propTypes = {
  action: PropTypes.string.isRequired,
};

const TimelineAgreementDocumentEvent = ({ event }) => {
  const {
    timeline_fields: {
      updater_name: updaterName,
      agreement_type: agreementType,
      document_name: documentName,
      changes,
      transaction_name: transactionName,
    },
    interacted_at_absolute: interactedAtAbsolute,
  } = event;
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState("");

  const showModalHandler = () => {
    setShowModal(true);
  };

  const hideModalHandler = () => {
    setShowModal(false);
  };

  const headline = (
    <div>
      <span className="bold-detail">{updaterName}</span>
      {` ${action} a ${agreementType} agreement document`}
    </div>
  );

  const eventBody = () => {
    switch (action) {
      case "added":
        return ` ${documentName}`;
      case "deleted":
        return (
          <>
            <span className="tw-italic"> ({changes.document[0]})</span>
            <span className="tw-text-[#A81E19]"> removed</span>
          </>
        );
      case "replaced":
        return (
          <>
            <span className="tw-italic"> ({changes.document[0]}) </span>
            {changes.document[1]}
          </>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (changes.document.length > 0) {
      setAction(() => {
        if (changes.document.length === 2) return "replaced";
        return documentName ? "added" : "deleted";
      });
    }
  }, [changes, documentName]);

  return (
    <BaseEvent
      icon={<Icon action={action} />}
      title={headline}
      interactedAt={interactedAtAbsolute}
      onClick={showModalHandler}
    >
      {changes.document.length > 0 && (
        <div>
          Agreement:
          <span className="tw-text-[#3B3B3B]">{eventBody()}</span>
        </div>
      )}
      {transactionName && (
        <div>
          Transaction:
          <span className="tw-text-[#3B3B3B]"> {transactionName}</span>
        </div>
      )}

      <Modal
        show={showModal}
        onHide={hideModalHandler}
        dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
        contentClassName="tw-max-w-[400px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
      >
        <Modal.Header title="Agreement Document" closeButton />
        <Modal.Body className="tw-flex-1 tw-overflow-auto tw-flex tw-flex-col tw-gap-[8px]">
          {headline}
          {changes.document.length > 0 && (
            <>
              <div className="tw-font-semibold">Details:</div>
              <div>
                Agreement:
                <span className="tw-text-[#3B3B3B]">{eventBody()}</span>
              </div>
              {transactionName && (
                <div>
                  Transaction:
                  <span className="tw-text-[#3B3B3B]"> {transactionName}</span>
                </div>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </BaseEvent>
  );
};

TimelineAgreementDocumentEvent.propTypes = {
  event: PropTypes.shape({
    timeline_fields: PropTypes.shape({
      updater_name: PropTypes.string,
      agreement_type: PropTypes.string,
      document_name: PropTypes.string,
      transaction_name: PropTypes.string,
      changes: PropTypes.shape({
        document: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
    interacted_at_absolute: PropTypes.string,
  }),
};

TimelineAgreementDocumentEvent.defaultProps = {
  event: {
    timeline_fields: {
      updater_name: "",
      agreement_type: "",
      document_name: "",
      transaction_name: "",
      changes: {
        document: [],
      },
    },
    interacted_at_absolute: "",
  },
};

export default TimelineAgreementDocumentEvent;
