import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "./Button";
import PondTableRow from "./PondTableRow";

import * as actions from "../actions/default";
import * as apiActions from "../actions/apiActions";

const mapStateToProps = (state) => ({
  collection: state.leadPonds.leadPondsCollection,
  pageSize: state.leadPonds.pageSize,
  page: state.leadPonds.page,
  totalPages: state.leadPonds.totalPages,
  isLoadingMore: state.leadPonds.isLoadingMore,
  isAdmin: state.leadPonds.isAdmin,
});

const columns = [
  { title: "Pond Name" },
  { title: "Display Agent" },
  { title: "Accessible To" },
  { title: "Created By" },
  { title: "Last Modified By" },
  { title: "Edit" },
];

const PondTable = ({
  collection,
  openPondModal,
  loadMorePonds,
  isLoadingMore,
  pageSize,
  page,
  totalPages,
  isAdmin,
}) => {
  const hasMore = totalPages >= page;
  return collection.length === 0 ? (
    <>
      <p className="tw-text-center tw-my-10 tw-text-gray-50">You don’t have any lead ponds created yet.</p>
      <Button
        className="tw-mx-auto tw-block"
        title="New lead pond"
        icon="fa-plus"
        onClick={() => {
          openPondModal();
        }}
      />
    </>
  ) : (
    <div className="tw-h-full tw-grow tw-w-full">
      <section className=" lp-table-section tw-w-full">
        <table className="data tw-w-full tw-text-[14px] tw-mb-4">
          <thead>
            <tr>
              {columns.map((col) => (
                <th key={col.title.replace(/' '/g, "_")} className="tw-font-normal tw-px-4">
                  <small>{col.title}</small>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {collection.map((pond) => (
              <PondTableRow
                key={pond.id}
                isAdmin={isAdmin}
                editPond={() => {
                  openPondModal({ pond });
                }}
                pond={pond}
              />
            ))}
          </tbody>
        </table>
        <div className="tw-w-full tw-sticky tw-left-0">
          <div className="tw-flex tw-justify-center ">
            {hasMore ? (
              <div>
                <Button
                  data-cy="lp-load-more"
                  disabled={isLoadingMore}
                  title={isLoadingMore ? "Loading..." : "Load More Ponds"}
                  onClick={() => {
                    loadMorePonds(page, pageSize);
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </section>

      <div className="tw-h-[100px]  tw-mb-20 " />
    </div>
  );
};

PondTable.propTypes = {
  collection: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  openPondModal: PropTypes.func.isRequired,
  loadMorePonds: PropTypes.func.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, { ...actions, ...apiActions })(PondTable);
