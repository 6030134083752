import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import Dropdown from "@shared/v2/Dropdown";
import { useEventListenerAction } from "@shared/hooks/useEventListenerAction";
import { capitalize } from "lodash";
import DropdownWithMultiCtrlledRemovable from "@shared/DropdownWithMultiCtrlledRemovable";
import { CLEAR_MODAL_FILTER_ID } from "../shared/constants";

const TAG_PREFIX_OPTIONS = [
  { label: "Any", value: "any" },
  { label: "All", value: "all" },
];

const IncludeTagFilter = ({ anyOrAll, options: propOptions, selectedOptions }) => {
  const [prefix, setPrefix] = useState({ label: "Any", value: "any" });

  useEffect(() => {
    if (anyOrAll) setPrefix({ label: capitalize(anyOrAll), value: anyOrAll });
  }, [anyOrAll]);

  useEventListenerAction(CLEAR_MODAL_FILTER_ID, () => setPrefix({ label: "Any", value: "any" }));

  const { options, hashOptions } = useMemo(
    () => ({
      options: propOptions.map(({ name, id }) => ({ label: name, value: `${id}` })),
      hashOptions: propOptions.reduce((acc, { name, id }) => {
        acc[id] = name;
        return acc;
      }, {}),
    }),
    [propOptions],
  );

  return (
    <div className="tw-flex tw-gap-[16px]">
      <Dropdown options={TAG_PREFIX_OPTIONS} value={prefix} onChange={setPrefix} />
      <input name="leads_filter[selected_any_all_tags][]" type="hidden" value={prefix.value} />
      <div className="tw-flex-1 tw-max-w-[300px]">
        <DropdownWithMultiCtrlledRemovable
          options={options}
          selectedOptions={selectedOptions?.map((id) => ({ label: hashOptions[id], value: `${id}` }))}
          removablePrefix={`Tag (${prefix.label}): `}
          placeHolderTxt=" tags selected"
          ctrlName="leads_filter[selected_tag_filters][]"
        />
      </div>
    </div>
  );
};

export default IncludeTagFilter;

IncludeTagFilter.propTypes = {
  anyOrAll: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

IncludeTagFilter.defaultProps = {
  anyOrAll: "any",
};
