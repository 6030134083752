import React, { useState, useEffect } from "react";
import axios from "axios";
import Tooltip from "@shared/v2/Tooltip";
import Button from "@shared/v2/Button/Button";
import Modal from "@shared/v2/Modal";
import Avatar from "@shared/v2/Avatar";
import { getStatusClass } from "@shared/StatusDropdown/StatusDropdown";
import { Plus } from "@shared/v2/Icomoon/Icons";
import MarketReport from "../shared/MarketReport";

const MarketReportPreview = ({
  modalIsOpen: initialModalIsOpen,
  person,
  person_status,
  person_lead_type,
  person_address,
  person_avatar,
  deleteReportFn,
  onClose,
  openMarketReport,
  editReportFn,
  editMarketReportPanel2,
  openMarketReportModal,
  modalOnly,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [marketReports, setMarketReports] = useState([]);

  useEffect(() => {
    if (initialModalIsOpen) {
      fetchReportsAndOpenModal();
    }
  }, [initialModalIsOpen]);

  const fetchMarketReports = () => {
    axios
      .get(`/market_reports?uuid=${person.uuid}`)
      .then((res) => setMarketReports(res.data))
      .catch((err) => console.error("Error fetching reports:", err));
  };

  const fetchReportsAndOpenModal = () => {
    setModalIsOpen(true);
    fetchMarketReports();
  };

  const closeMarketReportModal = () => {
    setModalIsOpen(false);
    setMarketReports([]);
    if (onClose) onClose();
  };

  const deleteReportFunc = (id) => {
    if (deleteReportFn(id)) {
      setMarketReports(marketReports.filter((r) => r.id !== id));
    } else {
      alert("Something went wrong...");
    }
  };

  const tooltipDescription = () => {
    if (person?.active_market_report_count > 0) return "Market Report(s) Sending";
    if (person?.market_report_count > 0) return "No Active Market Report Drips";
    return "No Market Reports";
  };

  const getColorStyle = () => {
    return person?.active_market_report_count ? "active" : person?.market_report_count ? "inactive" : "zero";
  };

  const getReportCount = () => {
    return person?.market_report_count || "0";
  };

  return (
    <div className="tw-flex-row tw-flex tw-space-evenly tw-justify-center">
      {!modalOnly && (
        <Tooltip
          trigger={
            <h6 className={`tw-cursor-pointer ${getColorStyle()}`} onClick={fetchReportsAndOpenModal}>
              {getReportCount()}
            </h6>
          }
          innerClassName="!tw-text-left tw-max-w-[180px]"
          content={tooltipDescription()}
          placement="right"
        />
      )}

      <Modal
        className="tw-flex tw-justify-center tw-items-center"
        dialogClassName="!tw-w-[616px] tw-h-auto"
        contentClassName="tw-w-full tw-h-full tw-p-[22px]"
        backdrop="static"
        show={modalIsOpen}
        onHide={closeMarketReportModal}
      >
        <Modal.Header
          titleClassName="tw-mx-[10px] tw-mb-32px tw-text-gray-75"
          title="Applied Market Reports"
          closeButton
        />

        <Modal.Body className="tw-mb-32px tw-max-h-[80vh] tw-overflow-y-auto ">
          <div className="tw-mx-[10px]">
            <div className="tw-flex tw-items-center">
              <Avatar
                src={person_avatar}
                alt={
                  !person?.first_name && !person?.last_name
                    ? "No Name"
                    : `${person?.first_name} ${person?.last_name}`.trim()
                }
                size="large"
              />
              <div className="tw-flex-1 tw-ml-[12px]">
                <div className="tw-flex tw-items-center tw-space-x-[12px]">
                  <span className="tw-text-[14px] tw-text-neutral-gray-75 tw-font-semibold">
                    {!person?.first_name && !person?.last_name
                      ? "No Name"
                      : `${person?.first_name} ${person?.last_name}`}{" "}
                  </span>
                  {person_lead_type &&
                    person_lead_type.split("/").map((t) => (
                      <div key={t} className={`intent-box ${t.toLowerCase()}`}>
                        {t}
                      </div>
                    ))}
                  <button
                    className={`status btn btn-sm ${getStatusClass(person_status?.toLowerCase())} tw-text-white tw-px-[23px] tw-py-[4px] tw-leading-4 tw-rounded-[12px] tw-text-[11px] !tw-min-w-[50px] !tw-w-auto`}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {person_status}
                  </button>
                </div>
                {person_address && (
                  <div className="tw-text-[14px] tw-text-neutral-gray-75">{person_address}</div>
                )}
              </div>
            </div>
            <div className="tw-flex tw-mt-[32px]">
              <span className="tw-flex-1 tw-font-[12px] tw-text-neutral-gray-75">{`Reports Created (${marketReports.length})`}</span>
              <Button
                schema="secondary"
                onClick={() => {
                  openMarketReport();
                  closeMarketReportModal();
                }}
              >
                <Plus size="s" className="tw-mr-4px" />
                Add market report
              </Button>
            </div>
            <div className="tw-h-[1px] tw-w-full tw-my-[16px] tw-bg-neutral-gray-10" />
          </div>

          <div
            id="listing-grid"
            className="tw-mx-[10px] tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-mb-[20px] tw-gap-[24px]"
          >
            {marketReports?.map((report) => (
              <div key={report.id}>
                <MarketReport
                  {...report}
                  editReportFn={(id) => {
                    editReportFn(id);
                    closeMarketReportModal();
                  }}
                  editMarketReportPanel2={(id) => {
                    editMarketReportPanel2(id);
                    closeMarketReportModal();
                  }}
                  deleteReportFn={(id) => {
                    deleteReportFunc(id);
                  }}
                  showAdjustment={Boolean(editMarketReportPanel2)}
                  openMarketReportModal={() => {
                    openMarketReportModal();
                    closeMarketReportModal();
                  }}
                />
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MarketReportPreview;
