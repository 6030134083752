import React, { Component } from "react";
import Modal from "@shared/v2/Modal";
import HeaderTitle from "./HeaderTitle";
import Activity from "../WebActivity/Activity";
import "../WebActivity/activity.scss";

const ViewWebActivityModal = ({ uuid, visits, lastVisit, views, isFavorite, closeWebActivityModal }) => (
  <Modal
    id="view-web-activity-modal"
    dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
    contentClassName="tw-max-w-[1000px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
    show={true}
    onHide={closeWebActivityModal}
  >
    <Modal.Header
      titleClassName="tw-flex-1 tw-flex tw-items-center tw-gap-[16px] tw-flex-wrap"
      title={
        <>
          <div className="tw-flex-1">Web Activity</div>
          <div className="tw-flex tw-flex-wrap tw-gap-[24px] tw-font-semibold">
            <div>
              <span className="tw-text-11d tw-uppercase">Views: </span>
              <span className="tw-text-12d">{views}</span>
            </div>
            <div>
              <span className="tw-text-11d tw-uppercase">Visits: </span>
              <span className="tw-text-12d">{visits}</span>
            </div>
            <div>
              <span className="tw-text-11d tw-uppercase">Last Visit: </span>
              <span className="tw-text-12d">{lastVisit}</span>
            </div>
          </div>
        </>
      }
      closeButton
    />
    <Modal.Body className="tw-flex-1 tw-overflow-x-hidden tw-overflow-auto">
      <Activity uuid={uuid} isFavorite={isFavorite} closeModal={closeWebActivityModal} views={views} />
    </Modal.Body>
  </Modal>
);

export default ViewWebActivityModal;
