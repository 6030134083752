import React from "react";
import Proptypes from "prop-types";
import Tooltip from "@shared/v2/Tooltip";
import IconButton from "@shared/v2/IconButton";
import { EmailDeliveryInvalid, EnvelopeSolidV6 } from "@shared/v2/Icomoon";

const EmailButton = ({ emailIconDetails, emailDetails, openModal, primaryEmailAddress }) => {
  if (emailIconDetails === "blocked_email_icon") {
    return (
      <Tooltip
        trigger={<i className="dont-email tw-cursor-default" />}
        content="Contact has opted out of email communication"
        placement="bottom"
      />
    );
  }
  if (emailDetails.length > 0 && emailIconDetails === "invalid_email_icon") {
    return (
      <Tooltip
        placement="bottom"
        trigger={
          <IconButton type="button" onClick={openModal} aria-label="invalid" schema="tertiary">
            <EmailDeliveryInvalid className="tw-text-semantic-red-100" />
          </IconButton>
        }
        content="Email may be invalid"
      />
    );
  }
  if (emailDetails.length > 0 && emailIconDetails === "active_email_icon") {
    return (
      <Tooltip
        placement="right"
        trigger={
          <IconButton aria-label="email" onClick={openModal}>
            <EnvelopeSolidV6 />
          </IconButton>
        }
        content={primaryEmailAddress}
      />
    );
  }
  return (
    <IconButton disabled>
      <EnvelopeSolidV6 />
    </IconButton>
  );
};

EmailButton.propTypes = {
  emailIconDetails: Proptypes.string,
  openModal: Proptypes.func,
  emailDetails: Proptypes.arrayOf(
    Proptypes.shape({
      id: Proptypes.number,
    }),
  ),
  primaryEmailAddress: Proptypes.string,
};
EmailButton.defaultProps = {
  emailIconDetails: null,
  openModal: null,
  emailDetails: null,
  primaryEmailAddress: null,
};

export default EmailButton;
