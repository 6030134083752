import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@shared/v2/Button";
import { fetchTimeline } from "../../PersonDetail/actions/personDetailActionCreators";

const LoadingFailure = () => {
  const dispatch = useDispatch();
  const person = useSelector(({ personDetail }) => personDetail.person);
  return (
    <div className="tw-flex tw-justify-center tw-bg-white tw-text-center">
      <div className="tw-py-[20px] tw-w-[550px]">
        <h1 className="tw-text-28d tw-text-coral tw-font-normal">Whoops! The page failed to load</h1>
        <p>Sorry about that! Something went wrong on our end and the page failed to load.</p>
        <p>
          If you continue to have issues, you can contact Brivity Support by calling (855) 427-4848, and
          we&apos;ll make sure that we address the issue as quickly as possible.
        </p>
        <br />
        <Button size="medium" onClick={() => dispatch(fetchTimeline(person.data.attributes.slug))}>
          Try Again
        </Button>
      </div>
    </div>
  );
};

export default LoadingFailure;
