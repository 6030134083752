import React from "react";
import PropTypes from "prop-types";
import { PaperclipSolidV6 } from "@shared/v2/Icomoon";

const attachmentFileIconClass = (mimeType) => {
  switch (mimeType) {
    case "image/gif":
    case "image/png":
    case "image/jpeg":
    case "image/jpg":
      return "fa fa-file-image-o";
    case "application/pdf":
      return "fa fa-file-pdf-o";
    default:
      return "fa fa-file-o";
  }
};

const GmailAttachment = ({ attachment, message }) => {
  const queryParams = new URLSearchParams();
  queryParams.append("message_id", message.id);
  queryParams.append("filename", attachment.filename);
  queryParams.append("content_type", attachment.mime_type);

  return (
    <a
      href={`/gmail_attachments/${attachment.body.attachment_id}?${queryParams}`}
      className="message-attachment"
    >
      <i
        className={`attachment-file-icon ${attachmentFileIconClass(attachment.mime_type)}`}
        aria-hidden="true"
      />
      {attachment.filename}
    </a>
  );
};

GmailAttachment.propTypes = {
  attachment: PropTypes.shape().isRequired,
  message: PropTypes.shape().isRequired,
};

const GmailMessage = ({ message, activeMessageId, toggleMessage }) => (
  <>
    <div
      className={`tw-flex tw-justify-between tw-gap-[16px] tw-border-solid tw-border-t-0 first-of-type:tw-border-t-[2px] tw-border-[2px] tw-border-x-0 tw-border-gray-10 tw-p-[10px] ${message.id === activeMessageId ? "tw-bg-white tw-text-theme-primary" : "tw-bg-gray-5"}`}
      role="button"
      tabIndex={0}
      onClick={() => toggleMessage(message.id)}
      onKeyDown={(e) => {
        if (["Enter", " "].includes(e.key)) {
          e.preventDefault();
          toggleMessage(message.id);
        }
      }}
    >
      <div>
        {message.from} to {message.to}
      </div>
      <div className="tw-whitespace-nowrap">
        {message.attachments.length > 1 && <PaperclipSolidV6 />} {message.interacted_at_relative_in_words}
      </div>
    </div>

    {message.id === activeMessageId && (
      <div className="tw-p-[16px] tw-border-solid tw-border-b-[2px] tw-border-x-0 tw-border-t-0 tw-border-gray-10">
        <div dangerouslySetInnerHTML={{ __html: message.body }} />

        <div className="message-attachments">
          {message.attachments.map((attachment) => (
            <GmailAttachment key={attachment.body.attachment_id} attachment={attachment} message={message} />
          ))}
        </div>
      </div>
    )}
  </>
);

GmailMessage.propTypes = {
  message: PropTypes.shape().isRequired,
  activeMessageId: PropTypes.number.isRequired,
  toggleMessage: PropTypes.func.isRequired,
};

export default GmailMessage;
