import React, { useState } from "react";
import PropTypes from "prop-types";
import GmailMessage from "./GmailMessage";

const GmailThread = ({ messages }) => {
  const [activeMessageId, setActiveMessageId] = useState(messages[0].id);

  return (
    <div className="tw-flex tw-flex-col">
      {messages.map((message) => (
        <GmailMessage
          key={message.id}
          message={message}
          activeMessageId={activeMessageId}
          toggleMessage={setActiveMessageId}
        />
      ))}
    </div>
  );
};

GmailThread.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default GmailThread;
