import React, { useState } from "react";
import PropTypes from "prop-types";
import { TrashSolidV6, UploadSolidV6 } from "@shared/v2/Icomoon/Icons";
import Modal from "@shared/v2/Modal";
import BaseEvent from "./BaseEvent";

const TimelineAgreementAddendumEvent = ({ event }) => {
  const {
    timeline_fields: {
      updater_name: updaterName,
      addendum,
      agreement,
      transaction,
      agreement_type: agreementType,
    },
    event_type: eventType,
    interacted_at_absolute: interactedAtAbsolute,
  } = event;
  const [showModal, setShowModal] = useState(false);

  const showModalHandler = () => {
    setShowModal(true);
  };

  const hideModalHandler = () => {
    setShowModal(false);
  };

  const headline = (
    <div>
      <span className="tw-font-semibold">{updaterName}</span>
      {` ${eventType === "agreement_addendum_created" ? "added" : "deleted"} a ${agreementType} agreement addendum`}
    </div>
  );

  const eventBody = () => {
    switch (eventType) {
      case "agreement_addendum_created":
        return ` ${addendum}`;
      case "agreement_addendum_deleted":
        return (
          <>
            <span className="tw-italic">{` (${addendum})`}</span>
            <span className="tw-text-[#A81E19]"> removed</span>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <BaseEvent
      icon={
        eventType === "agreement_addendum_created" ? <UploadSolidV6 size="l" /> : <TrashSolidV6 size="l" />
      }
      title={headline}
      interactedAt={interactedAtAbsolute}
      onClick={showModalHandler}
    >
      {addendum && (
        <div>
          Addendum: <span className="tw-text-[#3B3B3B]">{eventBody()}</span>
        </div>
      )}
      {agreement && (
        <div>
          Agreement: <span className="tw-text-[#3B3B3B]"> {agreement}</span>
        </div>
      )}
      {transaction && (
        <div>
          Transaction: <span className="tw-text-[#3B3B3B]"> {transaction}</span>
        </div>
      )}

      <Modal
        show={showModal}
        onHide={hideModalHandler}
        dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
        contentClassName="tw-max-w-[400px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
      >
        <Modal.Header title="Agreement Document" closeButton />
        <Modal.Body className="tw-flex-1 tw-overflow-auto tw-flex tw-flex-col tw-gap-[8px]">
          {headline}
          {addendum && (
            <>
              <div className="tw-font-semibold">Details:</div>
              <div>
                Addendum: <span className="tw-text-[#3B3B3B]">{eventBody()}</span>
              </div>
              {agreement && (
                <div>
                  Agreement: <span className="tw-text-[#3B3B3B]"> {agreement}</span>
                </div>
              )}
              {transaction && (
                <div>
                  Transaction: <span className="tw-text-[#3B3B3B]"> {transaction}</span>
                </div>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </BaseEvent>
  );
};

TimelineAgreementAddendumEvent.propTypes = {
  event: PropTypes.shape({
    timeline_fields: PropTypes.shape({
      updater_name: PropTypes.string,
      agreement_type: PropTypes.string,
      addendum: PropTypes.string,
      agreement: PropTypes.string,
      transaction: PropTypes.string,
    }),
    event_type: PropTypes.string,
    interacted_at_absolute: PropTypes.string,
  }),
};

TimelineAgreementAddendumEvent.defaultProps = {
  event: {
    timeline_fields: {
      updater_name: "",
      agreement_type: "",
      addendum: "",
      agreement: "",
      transaction: "",
    },
    event_type: "",
    interacted_at_absolute: "",
  },
};

export default TimelineAgreementAddendumEvent;
