/* global tinyMCE */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useBeforeUnload, useBlocker } from "react-router-dom";
import { format } from "date-fns";
import { debounce, isEqual, xorBy } from "lodash";
import AiAssistant from "@shared/AiAssistant";
import Alert from "@shared/v2/Alert";
import Avatar from "@shared/v2/Avatar";
import BrivityTextEditor from "@shared/BrivityTextEditor";
import Button from "@shared/v2/Button";
import Checkbox from "@shared/v2/Checkbox";
import DateSelector from "@shared/v2/DateSelector";
import FieldLabel from "@shared/v2/FieldLabel";
import IconButton from "@shared/v2/IconButton";
import { Popover } from "@shared/v2/Popover";
import TextButton from "@shared/v2/TextButton";
import TextInput from "@shared/v2/TextInput";
import Tooltip from "@shared/v2/Tooltip";
import Typography from "@shared/v2/Typography";
import {
  ArrowLeftSolidV6,
  BoxArchiveSolidV6,
  CheckSolidV6,
  CircleInfoSolidV6,
  EllipsisSolidV6,
  EyeSolidV6,
  LinkSolidV6,
  PencilSolidV6,
  PlusSolidV6,
  ShareSolidV6,
  SpinnerThirdSolidV6,
  TriangleExclamationSolidV6,
} from "@shared/v2/Icomoon";
import { getExternalApi } from "@shared/externalApi";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import {
  archivePost,
  getPost,
  publishPost,
  setArchivePost,
  setPostSaved,
  setSharePost,
  updatePost,
} from "../../reducers/websiteReducer/websiteReducer";
import CollapsiblePostCard from "./CollapsiblePostCard";
import ImageDropTarget from "./ImageDropTarget";
import {
  useCategories,
  usePost,
  usePostError,
  usePostLoading,
  usePostSaved,
  useTags,
  useWebsiteApiUrl,
  useWebsiteIDXUrl,
} from "../../reducers/websiteReducer/selectors";
import PostStatus from "../Blog/PostStatus";
import CategoryTagModal from "../Blog/CategoryTagModal";
import FeaturedImageHelpModal from "./FeaturedImageHelpModal";
import SeoHelpModal from "./SeoHelpModal";
import UnsavedChangesModal from "./UnsavedChangesModal";
import EditingSlugsModal from "./EditingSlugsModal";
import Loading from "../../Goals/components/Loader";
import { setSnackbar } from "../../reducers/snackbarReducer";

const useTeamPeople = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    caseTransformingAxios.get("/website/team_people").then((res) => setData(res.data));
  }, []);

  return data;
};

const PostIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const apiUrl = useWebsiteApiUrl();
  const categories = useCategories();
  const errors = usePostError();
  const initialPost = usePost();
  const loading = usePostLoading();
  const postSaved = usePostSaved();
  const tags = useTags();
  const websiteIDXUrl = useWebsiteIDXUrl();
  const [post, setPost] = useState(initialPost);
  const [categoryTagModel, setCategoryTagModel] = useState();
  const [featuredImageModalIsOpen, setFeaturedImageModalIsOpen] = useState(false);
  const [seoModalIsOpen, setSeoModalIsOpen] = useState(false);
  const [editSlugModalIsOpen, setEditSlugModalIsOpen] = useState(false);
  const [isPublishPickerOpen, setIsPublishPickerOpen] = useState(false);
  const teamPeople = useTeamPeople();

  const hasChanges = !isEqual(post, initialPost);

  const onBeforeUnload = useCallback(
    (e) => {
      if (!hasChanges) return;
      e.returnValue =
        "Your post has unsaved changes. If you proceed without saving your post, all edits will be lost.\n Would you like to save or discard your changes?";
    },
    [hasChanges],
  );

  const onFileUpload = useCallback((cb, value, meta) => {
    if (meta.filetype === "image") {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.onchange = (e) => {
        const websiteApi = getExternalApi(apiUrl);
        const formData = new FormData();
        formData.append("postUuid", uuid);
        formData.append("file", e.target.files[0]);
        websiteApi.put("/PostAsset", formData).then((response) => cb(response.data));
      };
      input.click();
    }
  }, []);

  // Handle browser navigation
  useBeforeUnload(onBeforeUnload, { capture: true });

  // Handle internal navigation
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => hasChanges && currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    setPost(initialPost);
  }, [initialPost]);

  useEffect(() => {
    if (uuid) dispatch(getPost(uuid));
  }, [uuid]);

  const onChange = useMemo(
    () =>
      debounce((changes) => {
        if (changes) dispatch(setPostSaved(false));
      }, 600),
    [],
  );

  useEffect(() => {
    onChange(hasChanges);
  }, [hasChanges]);

  const setCategory = (category) =>
    setPost((p) => ({ ...p, categories: xorBy(p.categories, [category], "categoryUuid") }));
  const setTag = (tag) => setPost((p) => ({ ...p, tags: xorBy(p.tags, [tag], "tagUuid") }));

  return (
    <div className="tw-flex-0 tw-max-w-[1416px] tw-mx-auto tw-p-[16px] tw-flex tw-flex-wrap tw-gap-[16px]">
      <div className="tw-flex-1 tw-flex tw-flex-col tw-gap-[16px]">
        <div className="tw-flex-0 tw-flex tw-flex-col tw-bg-white tw-border-[1px] tw-border-solid tw-border-gray-10 tw-rounded-[8px]">
          <div className="tw-px-[20px] tw-py-[24px] tw-flex tw-justify-between tw-items-center tw-gap-[8px]">
            <div className="tw-flex tw-items-center tw-gap-[8px]">
              <IconButton schema="transparent" onClick={() => navigate(-1)}>
                <ArrowLeftSolidV6 size="l" />
              </IconButton>
              <Typography variant="headline1">Post</Typography>
            </div>
            <div className="tw-flex tw-items-center tw-gap-[8px]">
              <Typography variant="body-m" className="!tw-text-gray-75">
                Written by
              </Typography>
              <div className="tw-flex tw-items-center">
                <Avatar
                  size="medium"
                  src={post?.author.avatar}
                  alt={`${post?.author.firstName || ""} ${post?.author.lastName || ""}`}
                />
                <Popover placement="bottom-end" maxHeight={400}>
                  <Popover.Trigger>
                    <IconButton size="small" schema="transparent">
                      <PencilSolidV6 />
                    </IconButton>
                  </Popover.Trigger>
                  <Popover.Content>
                    {teamPeople.map((p) => (
                      <Popover.Item
                        key={p.value}
                        onClick={() => setPost((t) => ({ ...t, author: { ...t.author, ...p.meta } }))}
                      >
                        <div className="tw-flex tw-items-center tw-gap-[8px]">
                          <Avatar src={p.meta.avatar} alt={p.label} />
                          {p.label}
                        </div>
                      </Popover.Item>
                    ))}
                  </Popover.Content>
                </Popover>
              </div>
            </div>
          </div>
          <div className="tw-w-full tw-min-h-[1px] tw-bg-gray-5" />
          <div className="tw-px-[20px] tw-py-[24px]">
            <TextInput
              autoComplete="off"
              label="Post Title"
              placeholder="Enter a title for your post"
              isRequired
              helperText={`${(post?.title || "").length} / 256 characters`}
              value={post?.title || ""}
              onChange={(e) => {
                const title = e.target.value;
                setPost((o) => ({ ...o, title }));
              }}
            />
          </div>
        </div>
        <CollapsiblePostCard
          title="Write Your Post"
          action={
            <AiAssistant
              isTinyMce
              onInsertClick={(text) => {
                tinyMCE.activeEditor.execCommand(
                  "mceInsertContent",
                  false,
                  (text || "").replace(/\r\n|\r|\n/g, "<br />"),
                );
              }}
            />
          }
        >
          <div className="tw-min-h-[300px] tw-flex tw-flex-col tw-gap-[10px]">
            {loading && <Loading />}
            {errors?.content && (
              <Alert
                variant="error"
                title="Post content cannot be empty"
                text="Add some text to publish your post! Need help? Ask AI to help you write."
              />
            )}
            {!loading && post && (
              <BrivityTextEditor
                id="blog-post"
                value={post.content || ""}
                settings={{
                  min_height: 740,
                  file_picker_callback: onFileUpload,
                  paste_data_images: false,
                  plugins: ["lists advlist link emoticons placeholder preview image media code paste"],
                  setup: (editor) => {
                    editor.on(
                      "input NodeChange",
                      debounce(() => setPost((p) => ({ ...p, content: editor.getContent() })), 500),
                    );
                  },
                }}
              />
            )}
          </div>
        </CollapsiblePostCard>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-[16px] tw-max-w-[300px]">
        <CollapsiblePostCard
          title="Publish"
          action={
            <>
              {!loading && !postSaved && hasChanges && (
                <span className="tw-text-[#e5941b] tw-text-12d tw-flex tw-items-center tw-gap-[4px]">
                  Unsaved changes <TriangleExclamationSolidV6 />
                </span>
              )}
              {loading && (
                <span className="tw-flex tw-items-center tw-gap-[8px]">
                  Saving...
                  <SpinnerThirdSolidV6 className="tw-animate-spin" />
                </span>
              )}
              {postSaved && (
                <span className="tw-text-semantic-green-110 tw-text-12d tw-flex tw-items-center tw-gap-[4px]">
                  Saved <CheckSolidV6 />
                </span>
              )}
            </>
          }
        >
          <div className="tw-flex tw-flex-col tw-gap-[24px]">
            <div className="tw-flex tw-flex-col tw-gap-[8px]">
              <div className="tw-flex tw-justify-between tw-items-center tw-gap-[4px]">
                <Typography variant="body-m" component="span">
                  Status
                </Typography>
                <PostStatus post={post} />
              </div>
              <div className="tw-flex tw-justify-between tw-items-center tw-gap-[4px]">
                <Typography variant="body-m" component="span">
                  Publish Date
                </Typography>
                <div className="tw-relative">
                  <TextButton
                    schema="sentence"
                    className="tw-inline-flex tw-items-center tw-gap-[8px] tw-mr-[-6px]"
                    onClick={() => {
                      if (!post?.isPublished) setIsPublishPickerOpen((o) => !o);
                    }}
                  >
                    {post?.datePublished || post?.dateToPublish
                      ? format(post.datePublished || post?.dateToPublish, "MM/dd/yyyy 'at' hh:mm a")
                      : "-"}
                    {!post?.isPublished && <PencilSolidV6 size="s" />}
                  </TextButton>
                  {isPublishPickerOpen && (
                    <div className="tw-shadow-dropdown tw-bg-white tw-absolute tw-z-1000 tw-top-full tw-right-0">
                      <DateSelector
                        enableTimePicker
                        startTimeEnabled
                        value={post?.dateToPublish ? format(post.dateToPublish, "MM/dd/yyyy hh:mm a") : ""}
                        onChange={(date) => {
                          if (date.formattedDate?.length > 4) {
                            setPost((p) => ({
                              ...p,
                              dateToPublish: new Date(date.formattedDate).toISOString(),
                            }));
                          }
                        }}
                      />
                      <div className="tw-flex tw-justify-end tw-p-4">
                        <Button onClick={() => setIsPublishPickerOpen(false)} schema="primary">
                          Done
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="tw-flex tw-justify-between tw-items-center tw-gap-[4px]">
                <Typography variant="body-m" component="span">
                  Last Modified
                </Typography>
                <Typography variant="body-s" className="!tw-text-gray-50">
                  {post?.dateUpdated ? format(post.dateUpdated, "MM/dd/yyyy") : "-"}
                </Typography>
              </div>
              {post?.dateArchived && (
                <div className="tw-flex tw-justify-between tw-items-center tw-gap-[4px]">
                  <Typography variant="body-m" component="span">
                    Archived
                  </Typography>
                  <Typography variant="body-s" className="!tw-text-gray-50">
                    {format(post.dateArchived, "MM/dd/yyyy")}
                  </Typography>
                </div>
              )}
            </div>
            <div className="tw-flex tw-gap-[4px]">
              <Popover placement="bottom-end">
                <Popover.Trigger>
                  <IconButton
                    schema="secondary"
                    size="medium"
                    className="tw-flex-none tw-w-[36px] tw-h-[36px]"
                  >
                    <EllipsisSolidV6 size="l" />
                  </IconButton>
                </Popover.Trigger>
                <Popover.Content>
                  <Popover.Item
                    icon={<LinkSolidV6 />}
                    onClick={() =>
                      window.copyTextToClipboard(`${websiteIDXUrl}/post/${post?.slug}`).then(() =>
                        dispatch(
                          setSnackbar({
                            show: true,
                            position: "top",
                            text: "Link copied",
                            schema: "executed",
                            timeout: 5000,
                            closeOnTimeout: () => dispatch(setSnackbar({ show: false })),
                          }),
                        ),
                      )
                    }
                    disabled={!post?.isPublished}
                  >
                    Copy Link
                  </Popover.Item>
                  <Popover.Item
                    icon={<EyeSolidV6 />}
                    onClick={() => window.open(`${websiteIDXUrl}/post/${post?.slug}`, "_blank")}
                    disabled={!post?.isPublished}
                  >
                    View Post
                  </Popover.Item>
                  <Popover.Item
                    icon={<ShareSolidV6 />}
                    onClick={() => dispatch(setSharePost(post))}
                    disabled={!post?.isPublished}
                  >
                    Share
                  </Popover.Item>
                  {!post?.isArchived && (
                    <Popover.Item icon={<BoxArchiveSolidV6 />} onClick={() => dispatch(setArchivePost(post))}>
                      Archive
                    </Popover.Item>
                  )}
                  {post?.isArchived && (
                    <Popover.Item icon={<BoxArchiveSolidV6 />} onClick={() => dispatch(archivePost(post))}>
                      Unarchive
                    </Popover.Item>
                  )}
                </Popover.Content>
              </Popover>
              <Button schema="secondary" size="medium" onClick={() => dispatch(updatePost(post))}>
                Save
              </Button>
              <Button
                size="medium"
                className="tw-w-full"
                disabled={post?.isArchived}
                onClick={() =>
                  dispatch(updatePost(post)).then((state) => {
                    if (!state.error) dispatch(publishPost(post));
                  })
                }
              >
                {post?.isPublished ? "Unpublish" : "Publish"}
              </Button>
            </div>
          </div>
        </CollapsiblePostCard>
        <CollapsiblePostCard
          title="Featured Image"
          action={
            <Button schema="tertiary" onClick={() => setFeaturedImageModalIsOpen(true)}>
              <CircleInfoSolidV6 size="s" /> Help
            </Button>
          }
        >
          <div className="tw-flex tw-flex-col tw-gap-[24px]">
            {post?.featuredImageUrl && (
              <div className="tw-flex tw-flex-col tw-gap-[8px]">
                <img
                  className="tw-w-full tw-h-[150px] tw-rounded-[8px] tw-object-cover"
                  src={
                    typeof post.featuredImageUrl === "string"
                      ? post.featuredImageUrl
                      : URL.createObjectURL(post.featuredImageUrl)
                  }
                  alt="Featured"
                />
                <div className="tw-flex tw-justify-between tw-items-center tw-gap-[8px]">
                  <Button
                    className="tw-flex-1"
                    schema="warning"
                    size="small"
                    onClick={() => setPost((p) => ({ ...p, featuredImageUrl: null }))}
                  >
                    Delete
                  </Button>
                  <Button
                    className="tw-flex-1"
                    size="small"
                    onClick={() => {
                      const input = document.createElement("input");
                      input.type = "file";
                      input.accept = "image/*";
                      input.onchange = (e) => {
                        const featuredImageUrl = e.target.files[0];
                        setPost((p) => ({ ...p, featuredImageUrl }));
                      };
                      input.click();
                    }}
                  >
                    Replace
                  </Button>
                </div>
              </div>
            )}
            {!post?.featuredImageUrl && (
              <ImageDropTarget onChange={(file) => setPost((p) => ({ ...p, featuredImageUrl: file }))} />
            )}
            <TextInput
              autoComplete="off"
              placeholder="Ex. Luxury home at night"
              label={
                <>
                  Alt Text{" "}
                  <Tooltip
                    innerClassName="!tw-text-left tw-max-w-[160px]"
                    trigger={<CircleInfoSolidV6 />}
                    content="Add a description to images on your posts to improve SEO and make your website more ADA compliant."
                  />
                </>
              }
              onChange={(e) => {
                const featuredImageAltText = e.target.value || null;
                setPost((p) => ({ ...p, featuredImageAltText }));
              }}
              value={post?.featuredImageAltText || ""}
            />
          </div>
        </CollapsiblePostCard>
        <CollapsiblePostCard
          title="Categories"
          action={
            <Button onClick={() => setCategoryTagModel({ category: "" })}>
              <PlusSolidV6 size="s" /> Add
            </Button>
          }
        >
          <div className="tw-flex tw-flex-col tw-gap-[8px] tw-max-h-[250px] tw-overflow-auto">
            {categories.length === 0 && (
              <Typography variant="body-m" className="!tw-text-gray-50">
                You have not created any blog categories
              </Typography>
            )}
            {categories.map((category) => (
              <Checkbox
                key={category.uuid}
                label={category.category}
                checked={post?.categories.some((c) => c.categoryUuid === category.uuid)}
                onChange={() => setCategory(category)}
              />
            ))}
          </div>
        </CollapsiblePostCard>
        <CollapsiblePostCard
          title="Tags"
          action={
            <Button onClick={() => setCategoryTagModel({ tag: "" })}>
              <PlusSolidV6 size="s" /> Add
            </Button>
          }
        >
          <div className="tw-flex tw-flex-col tw-gap-[8px] tw-max-h-[250px] tw-overflow-auto">
            {tags.length === 0 && (
              <Typography variant="body-m" className="!tw-text-gray-50">
                You have not created any blog tags
              </Typography>
            )}
            {tags.map((tag) => (
              <Checkbox
                key={tag.uuid}
                label={tag.tag}
                checked={post?.tags.some((t) => t.tagUuid === tag.uuid)}
                onChange={() => setTag(tag)}
              />
            ))}
          </div>
        </CollapsiblePostCard>
        <CollapsiblePostCard
          title="SEO"
          action={
            <Button schema="tertiary" onClick={() => setSeoModalIsOpen(true)}>
              <CircleInfoSolidV6 size="s" /> Help
            </Button>
          }
        >
          <div className="tw-flex tw-flex-col tw-gap-[24px]">
            <div className="tw-flex tw-flex-col tw-items-start tw-gap-[4px]">
              <FieldLabel label="Slug" />
              <div>{post?.slug}</div>
              <TextButton className="tw-ml-[-6px]" onClick={() => setEditSlugModalIsOpen(true)}>
                Edit <PencilSolidV6 size="s" />
              </TextButton>
            </div>
            <TextInput
              autoComplete="off"
              label="Meta Title"
              placeholder="Set your browser tab text"
              value={post?.meta.title}
              onChange={(e) => {
                const title = e.target.value;
                setPost((p) => ({ ...p, meta: { ...p.meta, title } }));
              }}
            />
            <TextInput
              label="Meta Description"
              placeholder="Brief summary of your post that appears in search results"
              multiline
              rows={5}
              value={post?.meta.description}
              onChange={(e) => {
                const description = e.target.value;
                setPost((p) => ({ ...p, meta: { ...p.meta, description } }));
              }}
            />
          </div>
        </CollapsiblePostCard>
      </div>

      <UnsavedChangesModal blocker={blocker} post={post} />

      <FeaturedImageHelpModal
        open={featuredImageModalIsOpen}
        onClose={() => setFeaturedImageModalIsOpen(false)}
      />

      <CategoryTagModal
        open={Boolean(categoryTagModel)}
        value={categoryTagModel}
        onClose={(catTag) => {
          if (catTag?.category) setCategory(catTag);
          if (catTag?.tag) setTag(catTag);
          setCategoryTagModel();
        }}
      />

      <SeoHelpModal open={seoModalIsOpen} onClose={() => setSeoModalIsOpen(false)} />

      <EditingSlugsModal open={editSlugModalIsOpen} onClose={() => setEditSlugModalIsOpen(false)} />
    </div>
  );
};

export default PostIndex;
