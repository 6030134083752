import React from "react";
import { format, formatISO, parse } from "date-fns";
import { useDispatch } from "react-redux";
import { headline2 } from "@shared/styles";
import DatePicker from "@shared/v2/DatePicker";
import { TZDate } from "@date-fns/tz";
import { containerView } from "./styles";
import { setPersonValue } from "../../reducers/contactsReducer";
import { useContactFormOptions, useContactPerson } from "../../reducers/contactsReducer/selectors";

function CustomDatesView() {
  const dispatch = useDispatch();
  const { milestones = {} } = useContactPerson();
  const { customDateOptions } = useContactFormOptions();
  return (
    <div className={containerView}>
      <div className={`tw-text-neutral-gray-75 ${headline2}`}>Custom Dates</div>
      <div className="tw-grid tw-grid-cols-2 tw-gap-[24px]">
        {customDateOptions?.map((option) => {
          let formattedDate = milestones[option.name] || null;
          if (formattedDate) {
            const hasTime = formattedDate.includes(" ");
            formattedDate = format(
              parse(formattedDate, hasTime ? "yyyy-MM-dd hh:mm a" : "yyyy-MM-dd", TZDate.tz("UTC")),
              hasTime ? "MM/dd/yyyy hh:mm a" : "MM/dd/yyyy",
            );
          }

          return (
            <DatePicker
              key={option.name}
              inputProps={{
                label: option.displayName,
                placeholder: "MM/DD/YYYY",
              }}
              value={formattedDate}
              enableTimePicker={option.includeTime}
              onChange={(value) => {
                let formattedValue = value;

                if (value?.includes(" ")) {
                  const parsedDate = parse(value, "MM/dd/yyyy hh:mm a", TZDate.tz("UTC"));
                  formattedValue = format(parsedDate, "yyyy-MM-dd hh:mm a");
                } else if (value) {
                  formattedValue = formatISO(parse(value, "MM/dd/yyyy", TZDate.tz("UTC")), {
                    representation: "date",
                  });
                }

                dispatch(
                  setPersonValue({
                    key: `milestones.${option.name}`,
                    value: formattedValue,
                  }),
                );
              }}
            />
          );
        })}
        {customDateOptions?.length % 2 !== 0 && <div className="tw-flex-1" />}
      </div>
    </div>
  );
}

export default CustomDatesView;
