/* global Turbolinks */
import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";
import TextButton from "@shared/v2/TextButton";
import { XmarkSolidV6 } from "@shared/v2/Icomoon";
import css from "../../../Dashboard/components/dashboard-styles.module.css";
import { Dropdown } from "../../../shared/v1";
import AgentDropdown from "./AgentDropdown";
import DateRangeDropdown from "./DateRangeDropdown";

const formatOptions = (agents) => {
  const allAgents = agents.map((agent) => ({ value: agent.id, label: agent.name }));
  return [
    { label: "All Active Users", value: "all_active_agents" },
    { label: "Only my transactions", value: "current_agent" },
    ...allAgents,
  ];
};

const getCookie = (name) => {
  let values = [];
  const decodedCookies = decodeURIComponent(document.cookie).split(";");
  decodedCookies.forEach((cookie) => {
    const trimmedCookie = cookie.trim();
    if (trimmedCookie.indexOf(name) === 0) {
      values = decodeURIComponent(trimmedCookie.substring(name.length + 1, trimmedCookie.length)).split(",");
    }
  });
  return values;
};

const setCookie = (name, value, exp) => {
  const date = new Date();
  date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000);
  const expires = `; expires=${date.toGMTString()}`;
  const cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}${expires}; path=/;`;
  document.cookie = cookieString;
};

const setDateRangeCookie = (range) => {
  setCookie("transaction_close_date_range", range, 365);
};

const setSourceCookie = (source) => {
  setCookie("transaction_sources", source, 365);
};

const setAgentCookie = (agents) => {
  setCookie("transaction_agent_ids", agents, 365);
};

class TransactionFilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      agents: [],
      sources: [],
      selectedAgents: ["all_active_agents"],
      selectedSources: [],
      selectedDate: null,
      selectedListingsCount: 0,
    };
  }

  componentDidMount = () => {
    const { active_assignable_agents: agents } = this.props;
    this.setState({ agents });
    axios.get(`/sources`).then((res) => {
      const { sources } = res.data;
      this.setState({ sources });
    });
    this.getDateRangeFromCookie();
    this.getSourceFromCookie();
    this.getAgentsCookie();
    document.body.addEventListener("change", this.onSelect);
  };

  componentWillUnmount() {
    document.body.removeEventListener("change", this.onSelect);
  }

  onSelect = (e) => {
    if (e.target.type === "checkbox") {
      setTimeout(() => {
        this.setState({
          selectedListingsCount: document.querySelectorAll("input.multi-select:checked").length,
        });
      }, 0);
    }
  };

  handleFilter = () => {
    this.setState((s) => ({
      isOpen: !s.isOpen,
    }));
  };

  handleFilterModal = (e) => {
    const { selectedAgents } = this.state;
    e.preventDefault();
    const agentsIdsQueryString = `?agent_ids=${selectedAgents}`;
    this.setState((s) => ({
      isOpen: !s.isOpen,
    }));
    Turbolinks.visit(window.location.pathname + agentsIdsQueryString, {
      change: "content",
      cacheRequest: false,
    });
  };

  option = (props) => {
    const {
      data: { label, value },
    } = props;
    const { selectedAgents } = this.state;

    return (
      <div className={`checkbox ${css.filterItem}`}>
        <input
          type="checkbox"
          id={label}
          value={value}
          onChange={(e) => this.handleAgentSelect(value, e.target.checked)}
          checked={selectedAgents.includes(value)}
        />
        <label htmlFor={label} className={css.checkBoxLabel}>
          {label}
        </label>
      </div>
    );
  };

  handleAgentSelect = (selectedAgentId, isChecked) => {
    const { agents, selectedAgents } = this.state;
    const currentSelectedAgents = [...selectedAgents];
    let newSelectedAgents = [];
    if (selectedAgentId === "all_active_agents" && isChecked) {
      newSelectedAgents = ["all_active_agents"];
    } else if (selectedAgentId === "current_agent" && isChecked) {
      newSelectedAgents = ["current_agent"];
    } else if (currentSelectedAgents.includes("all_active_agents" || "current_agent")) {
      newSelectedAgents = isChecked
        ? [selectedAgentId]
        : agents.filter((filter) => filter !== selectedAgentId);
    } else {
      newSelectedAgents = isChecked
        ? [...currentSelectedAgents, selectedAgentId]
        : currentSelectedAgents.filter((filter) => filter !== selectedAgentId);
    }
    this.setState({
      selectedAgents: newSelectedAgents.length ? newSelectedAgents : [],
    });
    setAgentCookie(newSelectedAgents);
  };

  handleSourceSelect = (source) => {
    const selectedSource = source == null ? null : source.value;
    this.setState(
      (s) => ({
        selectedSources: source == null ? [] : [...s.selectedSources, selectedSource],
      }),
      () => {
        const { selectedSources } = this.state;
        setSourceCookie(selectedSources);
      },
    );
  };

  handleDateSelect = (date) => {
    const selectedDate = date == null ? "" : date.value;
    this.setState({ selectedDate });
    setDateRangeCookie(selectedDate);
  };

  handleClear = (e) => {
    e.preventDefault();
    this.setState({
      selectedAgents: [],
      selectedSources: [],
      selectedDate: null,
    });
    setAgentCookie("all_active_agents");
    setSourceCookie("");
    setDateRangeCookie("");
  };

  // eslint-disable-next-line consistent-return
  getDateRangeFromCookie = () => {
    const defaultRange = "all_time";
    if (getCookie("transaction_close_date_range").length < 1) {
      setCookie("transaction_close_date_range", defaultRange, 365);
      return defaultRange;
    }
    getCookie("transaction_close_date_range");

    this.setState({
      selectedDate: getCookie("transaction_close_date_range")[0],
    });
  };

  // eslint-disable-next-line consistent-return
  getSourceFromCookie = () => {
    if (getCookie("transaction_sources").length < 1) {
      return false;
    }
    getCookie("transaction_sources");

    const sourceCookie = getCookie("transaction_sources");
    this.setState({
      selectedSources:
        sourceCookie.length > 0
          ? getCookie("transaction_sources")
              .filter((id) => id !== "")
              .map((sourceId) => parseInt(sourceId, 10))
          : [],
    });
  };

  // eslint-disable-next-line consistent-return
  getAgentsCookie = () => {
    const defaultAgent = "all_active_agents";
    if (getCookie("transaction_agent_ids").length < 1) {
      setCookie("transaction_agent_ids", defaultAgent, 365);
      return defaultAgent;
    }
    getCookie("transaction_agent_ids");

    this.setState({
      selectedAgents: getCookie("transaction_agent_ids").map((agentId) =>
        agentId === "all_active_agents" || agentId === "current_agent" ? agentId : parseInt(agentId, 10),
      ),
    });
  };

  removeSource = (sourceId) => {
    const { selectedSources } = this.state;
    // eslint-disable-next-line eqeqeq
    const newSources = selectedSources.filter((id) => id != sourceId);
    this.setState({ selectedSources: newSources });
    setSourceCookie(newSources);
  };

  findSource(sourceId) {
    const { sources } = this.state;
    // eslint-disable-next-line eqeqeq
    return sources && sources.find((source) => source.id == sourceId);
  }

  renderSelectedSource(sourceId) {
    if (sourceId === "") {
      return null;
    }
    const source = this.findSource(sourceId) || {};
    return (
      <div key={sourceId} style={{ display: "inline-block" }}>
        <input
          className="filter-bubble-checkbox"
          type="checkbox"
          name="leads_filter[selected_tag_filters][]"
          id={`leads_filter_selected_tag_filters_${source.id}`}
          value={source.id}
          defaultChecked="checked"
        />
        <div className="removable-bubble" style={{ marginRight: "3px" }}>
          <label className="info-label" htmlFor={`leads_filter_selected_tag_filters_${source.id}`}>
            {source.text}
          </label>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
          <i
            onClick={() => this.removeSource(source.id)}
            className="fa fa-remove"
            style={{ paddingLeft: "3px" }}
          />
        </div>
      </div>
    );
  }

  render() {
    const { date_ranges: dateRanges, scope } = this.props;
    const { agents, isOpen, selectedAgents, selectedDate, selectedSources, sources, selectedListingsCount } =
      this.state;
    const sourceOptions = sources.map((source) => ({
      label: source.text,
      value: source.id,
    }));
    const dateOptions = dateRanges.map((date) => ({
      label: date[0],
      value: date[1],
    }));
    const dateOptionsCopy = [...dateOptions];
    const allTime = dateOptionsCopy.pop();
    dateOptionsCopy.unshift(allTime);
    return (
      <div data-cy="transaction-filter-modal-wrapper">
        <div className="tw-flex tw-gap-[8px]">
          {scope === "archived" && selectedListingsCount > 0 && (
            <span className="multi-select-buttons">
              <Button
                size="medium"
                schema="warning"
                className="delete-all-transactions tw-flex tw-items-center tw-gap-[4px]"
              >
                <XmarkSolidV6 size="l" /> Delete
              </Button>
            </span>
          )}
          <Button className="tw-mr-[16px]" size="medium" onClick={this.handleFilter}>
            Filter
          </Button>
        </div>
        <Modal
          show={isOpen}
          onHide={this.handleFilter}
          dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
          contentClassName="tw-max-w-[600px] tw-max-h-[90vh] tw-w-full"
        >
          <form
            data-cy="filter-modal-form"
            onSubmit={this.handleFilterModal}
            className="tw-flex tw-flex-col tw-gap-[32px]"
          >
            <Modal.Header title="Filter" closeButton />
            <div>
              <AgentDropdown
                option={this.option}
                selectedAgents={selectedAgents}
                agents={agents}
                formatOptions={formatOptions}
              />

              <hr className="tw-h-[1px] tw-bg-gray-10" />

              <label
                htmlFor="transaction-source"
                style={{
                  letterSpacing: "0.2px",
                  fontSize: "14px",
                  fontFamily: "Open Sans",
                }}
              >
                <b>SOURCE</b>
              </label>
              <Dropdown
                id="transaction-source"
                options={sourceOptions}
                onChange={(source) => this.handleSourceSelect(source)}
                value={null}
                isClearable={false}
                variant="flatGray"
              />
              <div className="filter-checkboxes m-t-10">
                {selectedSources.map((source) => this.renderSelectedSource(source))}
              </div>

              {/* eslint-disable-next-line eqeqeq */}
              {scope == "pending" || scope == "sold" ? (
                <DateRangeDropdown
                  dateOptions={dateOptionsCopy}
                  handleDateSelect={this.handleDateSelect}
                  selectedDate={selectedDate}
                />
              ) : null}
            </div>
            <Modal.Footer className="tw-flex tw-justify-between tw-gap-[16px]">
              <Button size="medium" variant="tertiary" onClick={this.handleFilter}>
                Cancel
              </Button>
              <div className="tw-flex tw-gap-[8px]">
                <TextButton size="medium" variant="primary" onClick={this.handleClear}>
                  Clear All
                </TextButton>
                <Button size="medium" variant="primary" type="submit">
                  Filter
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}

TransactionFilterModal.propTypes = {
  active_assignable_agents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  date_ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  scope: PropTypes.string.isRequired,
};

export default TransactionFilterModal;
