import React, { Component } from "react";
import PropTypes from "prop-types";
import Linkify from "linkify-react";
import Button from "@shared/v2/Button/Button";
import AiCallSummary from "@shared/AiAssistant/AiCallSummary";
import { PhoneSolidV6 } from "@shared/v2/Icomoon";
import Modal from "@shared/v2/Modal";
import PhoneCallDetails from "../../../Interactions/PhoneCall/components/PhoneCallDetails";
import { updateInteractionPromise } from "../../../PersonDetail/actions/updateInteraction";
import BaseEvent from "./BaseEvent";

const mentionMap = new Map();

const calculateCallContent = (comment) =>
  comment.replace(/\[([^\]]+)\]\(([^)]+)\)/g, (_, name, id) => {
    mentionMap.set(id, name);
    return `${window.location.host}/people/${id}`;
  });

const callDuration = (durationSeconds) => {
  const hours = Math.floor(durationSeconds / 60 / 60);
  const minutes = Math.floor((durationSeconds / 60) % 60);
  const seconds = Math.floor(durationSeconds % 60);

  const hoursString = hours.toString().padStart(2, "0");
  const minutesString = minutes.toString().padStart(2, "0");
  const secondsString = seconds.toString().padStart(2, "0");

  return (
    <div className="row details-row">Duration: {`${hoursString}:${minutesString}:${secondsString}`}</div>
  );
};

class PublicActivityCallEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      editableInteraction: {
        ...props.event.object_attributes,
        comment: calculateCallContent(props.event.object_attributes.markdown_comment),
      },
      editableInteractionHasErrors: false,
      editableInteractionErrorMessages: [],
      isSaving: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState((state) => ({ ...state, ...nextProps }));
  }

  componentDidUpdate(prevProps) {
    const { event } = this.props;
    if (prevProps.event.object_attributes !== event.object_attributes) {
      this.setState({
        editableInteraction: {
          ...event.object_attributes,
          comment: calculateCallContent(event.object_attributes.markdown_comment),
        },
      });
    }
  }

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    const { event } = this.props;
    this.setState({
      showModal: false,
      editableInteraction: {
        ...event.object_attributes,
        comment: calculateCallContent(event.object_attributes.markdown_comment),
      },
      editableInteractionHasErrors: false,
      isSaving: false,
      editableInteractionErrorMessages: [],
    });
  };

  headline = () => {
    const { event } = this.props;
    const rawFrom = `${event.agent_person.first_name} ${event.agent_person.last_name}`.trim();
    const fromString = rawFrom.trim().length > 0 ? rawFrom : "An unnamed agent";

    const { duration } = event.object_attributes;
    let callContext = "";

    if (duration == null) {
      callContext = " logged a call";
    } else {
      callContext = " called this contact";
    }

    return (
      <div>
        <span className="tw-font-semibold">{fromString}</span>
        {callContext}
      </div>
    );
  };

  callResult = () => {
    const {
      event: {
        object_attributes: { outcome },
      },
    } = this.props;

    switch (outcome) {
      case "talked_to_lead":
        return "Talked";
      case "left_message":
        return "Left Voicemail";
      case "busy":
        return "Busy";
      case "failed":
        return "Call Failed";
      case "no_answer":
        return "No Answer";
      case "wrong_number":
        return "Wrong Number";
      case "canceled":
        return "Canceled";
      case "inbound_call":
        return "Inbound Call";
      default:
        return "Unknown";
    }
  };

  notes = () => {
    const { editableInteraction } = this.state;
    return (
      <div className="timeline-description-text">
        Notes:&nbsp;
        <Linkify
          onClick={(e) => e.stopPropagation()}
          className="tw-whitespace-pre-wrap tw-break-words"
          tagName="span"
          options={{
            defaultProtocol: "https",
            target: "_blank",
            render: {
              url: ({ attributes, content }) => {
                const segments = content.split("/");
                const potentialMentionId = segments[segments.length - 1];
                return (
                  <a className="tw-text-theme-primary" {...attributes}>
                    {mentionMap.has(potentialMentionId) ? `@${mentionMap.get(potentialMentionId)}` : content}
                  </a>
                );
              },
            },
          }}
        >
          {editableInteraction.comment}
        </Linkify>
      </div>
    );
  };

  details = () => {
    const {
      event: {
        object_attributes: { duration, markdown_comment: notes },
      },
    } = this.props;

    return (
      <div>
        <div>Call Result: {this.callResult()}</div>
        {duration !== null && callDuration(duration)}
        {!!notes && this.notes()}
      </div>
    );
  };

  updateEditableInteraction = (name, value) => {
    if (name === "comment") {
      this.setState((state) => ({
        editableInteraction: {
          ...state.editableInteraction,
          comment: value,
          markdown_comment: value,
        },
      }));
    } else {
      this.setState((state) => ({ editableInteraction: { ...state.editableInteraction, [name]: value } }));
    }
  };

  submitEditableInteraction = () => {
    const { editableInteraction } = this.state;
    const { submitUpdateInteraction } = this.props;
    this.setState({
      isSaving: true,
    });
    updateInteractionPromise(editableInteraction)
      .then((r) => {
        this.setState({
          showModal: false,
          editableInteractionHasErrors: false,
          isSaving: false,
          editableInteractionErrorMessages: [],
        });
        submitUpdateInteraction(r.data);
      })
      .catch((e) => {
        this.setState({
          editableInteractionHasErrors: true,
          isSaving: false,
          editableInteractionErrorMessages: e.data.errors,
        });
      });
  };

  render() {
    const {
      editableInteraction,
      editableInteractionHasErrors,
      editableInteractionErrorMessages,
      isSaving,
      showModal,
    } = this.state;
    const { event } = this.props;
    return (
      <>
        <BaseEvent
          icon={<PhoneSolidV6 />}
          title={this.headline()}
          interactedAt={event.interacted_at_absolute}
          extraContent={<AiCallSummary callSid={event.object_attributes.call_sid} />}
          onClick={this.showModal}
        >
          {this.details()}
        </BaseEvent>

        <Modal
          show={showModal}
          onHide={this.hideModal}
          dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
          contentClassName="tw-max-w-[900px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
        >
          <Modal.Header title="Edit Call" closeButton />
          <Modal.Body>
            <PhoneCallDetails
              isTimelineEvent
              interaction={editableInteraction}
              notableId={event.notable_id}
              updateValues={this.updateEditableInteraction}
              hasError={editableInteractionHasErrors}
              errorMessage={editableInteractionErrorMessages}
            />
          </Modal.Body>
          <Modal.Footer className="tw-flex tw-justify-between tw-gap-[16px]">
            <Button schema="tertiary" size="medium" onClick={this.hideModal} disabled={isSaving}>
              Cancel
            </Button>
            <Button
              schema="primary"
              size="medium"
              onClick={this.submitEditableInteraction}
              isLoading={isSaving}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

PublicActivityCallEvent.propTypes = {
  event: PropTypes.shape().isRequired,
  submitUpdateInteraction: PropTypes.func.isRequired,
};

export default PublicActivityCallEvent;
