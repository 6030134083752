import React from "react";
import PropTypes from "prop-types";
import { HeartSolidV6 } from "@shared/v2/Icomoon";
import BaseEvent from "./BaseEvent";
import HomeInfoOverlay from "./HomeInfoOverlay";

const PropertyFavoriteEvent = ({
  event: {
    event_type: eventType,
    interacted_at_absolute: interactedAt,
    timeline_fields: timelineFields = {},
  },
}) => {
  const isFavorite = eventType === "property_favorite_created";
  return (
    <div
      role="button"
      aria-label="Property Favorite Event"
      tabIndex={0}
      onClick={() => {
        if (timelineFields.url) window.open(timelineFields.url);
      }}
      onKeyDown={(e) => {
        if (["Enter", " "].includes(e.key)) {
          e.preventDefault();
          if (timelineFields.url) window.open(timelineFields.url);
        }
      }}
    >
      <BaseEvent
        icon={<HeartSolidV6 />}
        title={
          <div>
            <span className="tw-text-semibold">
              {timelineFields.first_name} {timelineFields.last_name}{" "}
            </span>
            <span className={isFavorite ? "" : "tw-text-[#f2714d]"}>
              {isFavorite ? "favorited" : "unfavorited"} a property
            </span>
          </div>
        }
        interactedAt={interactedAt}
      >
        <HomeInfoOverlay timelineFields={timelineFields} />
      </BaseEvent>
    </div>
  );
};

PropertyFavoriteEvent.propTypes = {
  event: PropTypes.shape().isRequired,
};

export default PropertyFavoriteEvent;
