import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@shared/v2/Button";
import Pagination from "@shared/v2/Pagination";
import TextComboInput from "@shared/v2/TextComboInput";
import TextLink from "@shared/v2/TextLink";
import TextToggle from "@shared/v2/TextToggle";
import Typography from "@shared/v2/Typography";
import { GearSolidV6, MagnifyingGlassSolidV6, PlusSolidV6, SpinnerSolidV6 } from "@shared/v2/Icomoon";
import BlogSettingsModal from "./BlogSettingsModal";
import {
  getBlogSettings,
  getPostPublishStatusCounts,
  getPosts,
} from "../../reducers/websiteReducer/websiteReducer";
import { Table, TBody, TD, TH, THead, TR } from "../../Reports/components/BusinessDashboard/Table/Table";
import PostRow from "./PostRow";
import CreatePostModal from "./CreatePostModal";
import {
  usePostPublishStatusCounts,
  usePosts,
  usePostsLoading,
  useTotalPages,
} from "../../reducers/websiteReducer/selectors";
import DuplicatePostModal from "../Post/DuplicatePostModal";

const STATUS_OPTIONS = [
  { label: "All", value: "all" },
  { label: "Published", value: "published" },
  { label: "Drafts", value: "drafts" },
  { label: "Archived", value: "archived" },
];

const ITEMS_PER_PAGE = 10;

const BlogIndex = () => {
  const dispatch = useDispatch();
  const posts = usePosts();
  const postsLoading = usePostsLoading();
  const postPublishStatusCounts = usePostPublishStatusCounts();
  const totalPages = useTotalPages();
  const [page, setPage] = useState(1);
  const [publishStatus, setPublishStatus] = useState("all");
  const [orderBy, setOrderBy] = useState("dateCreated");
  const [sortOrder, setSortOrder] = useState("desc");
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [createPostOpen, setCreatePostOpen] = useState(false);
  const [duplicatePost, setDuplicatePost] = useState(null);

  const statusOptions = useMemo(
    () =>
      STATUS_OPTIONS.map((option) => ({
        ...option,
        label: `${option.label} ${postPublishStatusCounts[option.value] > 0 ? `(${postPublishStatusCounts[option.value]})` : ""}`,
      })),
    [postPublishStatusCounts],
  );

  useEffect(() => {
    dispatch(getPostPublishStatusCounts());
    dispatch(getBlogSettings());
  }, []);

  useEffect(() => {
    dispatch(
      getPosts({
        page,
        itemsPerPage: ITEMS_PER_PAGE,
        params: { publishStatus, orderBy, sortOrder },
      }),
    );
  }, [page, publishStatus, orderBy, sortOrder]);

  const onSort = (field) => () => {
    if (field === orderBy) {
      setSortOrder(sortOrder === "desc" ? "asc" : "desc");
    } else {
      setOrderBy(field);
      setSortOrder("desc");
    }
  };

  return (
    <div className="tw-flex-1 tw-h-full tw-max-w-[1416px] tw-mx-auto tw-flex tw-gap-[32px]">
      <div className="tw-flex tw-flex-col tw-gap-[32px] tw-m-[16px] tw-bg-white tw-border-[1px] tw-border-solid tw-border-gray-10 tw-rounded-[8px] tw-p-[32px]">
        <div className="tw-flex tw-flex-wrap-reverse tw-gap-[32px]">
          <div className="tw-flex-1 tw-min-w-[280px]">
            <Typography variant="headline1">Team Blog</Typography>
            <Typography variant="body-m" className="tw-mt-[8px]">
              Blog posts help you showcase expertise, attract buyers and sellers, and improve your Brivity
              website’s search rankings. Share market insights, tips, and local highlights to build trust,
              nurture leads, and offer valuable advice to clients. <TextLink href="#">Learn more.</TextLink>
            </Typography>
          </div>
          <div className="tw-flex-none tw-flex tw-gap-[8px]">
            <Button
              size="medium"
              schema="tertiary"
              className="tw-whitespace-nowrap"
              onClick={() => setSettingsOpen(true)}
            >
              <GearSolidV6 /> Settings
            </Button>
            <BlogSettingsModal open={settingsOpen} onClose={() => setSettingsOpen(false)} />

            <Button size="medium" className="tw-whitespace-nowrap" onClick={() => setCreatePostOpen(true)}>
              <PlusSolidV6 /> New Post
            </Button>
            <CreatePostModal open={createPostOpen} onClose={() => setCreatePostOpen(false)} />
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-[16px]">
          <div className="tw-flex tw-flex-wrap tw-justify-between tw-items-center tw-gap-[16px]">
            <div className="tw-flex tw-items-center tw-gap-[16px]">
              <TextToggle
                options={statusOptions}
                value={publishStatus}
                onChange={(e) => setPublishStatus(e.target.value)}
              />
              {postsLoading && <SpinnerSolidV6 size="xl" className="tw-animate-spin" />}
            </div>
            <TextComboInput
              containerClassName="!tw-max-w-[400px] tw-w-full"
              placeholder="Search by post title"
              trailing={<MagnifyingGlassSolidV6 size="l" className="tw-text-gray-30 tw-align-middle" />}
            />
          </div>
        </div>
        <div className="tw-mt-[-16px] tw-flex-1 tw-overflow-auto">
          <Table className="tw-border-separate">
            <THead className="tw-sticky tw-top-0 tw-bg-white">
              <TR className="tw-font-semibold">
                <TH border="tw-border-y-[1px] tw-border-x-0" />
                <TH border="tw-border-y-[1px] tw-border-x-0">Title</TH>
                <TH border="tw-border-y-[1px] tw-border-x-0">Categories</TH>
                <TH border="tw-border-y-[1px] tw-border-x-0">Tags</TH>
                <TH
                  border="tw-border-y-[1px] tw-border-x-0"
                  className="tw-text-center"
                  sort={orderBy === "postPublishStatus" && sortOrder}
                  onSort={onSort("postPublishStatus")}
                >
                  Status
                </TH>
                <TH
                  border="tw-border-y-[1px] tw-border-x-0"
                  sort={orderBy === "date" && sortOrder}
                  onSort={onSort("date")}
                >
                  Publish Date
                </TH>
                <TH
                  border="tw-border-y-[1px] tw-border-x-0"
                  sort={orderBy === "dateCreated" && sortOrder}
                  onSort={onSort("dateCreated")}
                >
                  Created
                </TH>
                <TH className="tw-text-center" border="tw-border-y-[1px] tw-border-x-0">
                  Author
                </TH>
                <TH border="tw-border-y-[1px] tw-border-x-0" />
              </TR>
            </THead>
            <TBody>
              {posts.length === 0 && (
                <TR>
                  <TD
                    colSpan={9}
                    className="tw-text-center tw-text-gray-50 tw-py-[16px]"
                    border="tw-border-b-[1px] tw-border-r-0"
                  >
                    No posts yet
                  </TD>
                </TR>
              )}
              {posts.map((post) => (
                <PostRow key={post.uuid} post={post} setDuplicatePost={setDuplicatePost} />
              ))}
            </TBody>
          </Table>
        </div>
        <div className="tw-flex tw-justify-center">
          <Pagination currentPage={page} totalPages={totalPages} onGoToPage={(p) => setPage(p)} />
        </div>
      </div>

      <DuplicatePostModal post={duplicatePost} onClose={() => setDuplicatePost(null)} />
    </div>
  );
};

export default BlogIndex;
