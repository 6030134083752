import React from "react";
import PropTypes from "prop-types";
import { Popover, PopoverItem, PopoverContent, PopoverTrigger } from "@shared/v2/Popover";
import IconButton from "@shared/v2/IconButton";
import { VerticalDots, Trash, VisibilityVisible, Edit } from "@shared/v2/Icomoon/Icons";

const MarketReportMenu = ({ editReportFn, deleteReportFn, previewUrl, id }) => {
  const handleDelete = () => {
    deleteReportFn(id);
  };

  const handleEdit = () => {
    editReportFn(id);
  };

  const handlePreview = () => {
    if (previewUrl) {
      window.open(previewUrl, "_blank");
    }
  };

  return (
    <div className="tw-relative tw-flex tw-justify-start">
      <Popover placement="bottom-end">
        <PopoverTrigger>
          <IconButton size="small" schema="transparent">
            <VerticalDots size="l" />
          </IconButton>
        </PopoverTrigger>
        <PopoverContent className="tw-z-3000">
          <PopoverItem icon={<Edit />} onClick={handleEdit}>
            Edit
          </PopoverItem>
          <PopoverItem icon={<VisibilityVisible />} onClick={handlePreview}>
            Preview
          </PopoverItem>
          <PopoverItem icon={<Trash />} onClick={handleDelete}>
            Delete
          </PopoverItem>
        </PopoverContent>
      </Popover>
    </div>
  );
};

MarketReportMenu.propTypes = {
  id: PropTypes.string,
  editReportFn: PropTypes.func,
  deleteReportFn: PropTypes.func,
  previewUrl: PropTypes.string,
};

MarketReportMenu.defaultProps = {
  id: null,
  editReportFn: null,
  deleteReportFn: null,
  previewUrl: null,
};

export default MarketReportMenu;
