import { actionTypes } from "./actionTypes";

export const openPondModal = (payload) => (dispatch) => {
  dispatch({ type: actionTypes.openPondModal, ...payload });
};

export const closePondModal = () => (dispatch) => {
  dispatch({ type: actionTypes.closePondModal });
};

export const createPondStart = (data) => ({ type: actionTypes.createPond, ...data });
export const createPondSuccess = (data) => ({ type: actionTypes.createPondSuccess, ...data });
export const createPondError = (data) => ({ type: actionTypes.createPondError, ...data });
export const updatePondStart = (data) => ({ type: actionTypes.updatePond, ...data });
export const updatePondSuccess = (data) => ({ type: actionTypes.updatePondSuccess, ...data });
export const updatePondError = (data) => ({ type: actionTypes.updatePondError, ...data });
export const removePondSuccess = (data) => ({ type: actionTypes.removePondSuccess, ...data });
export const removePondError = (data) => ({ type: actionTypes.removePondError, ...data });
export const duplicateCheckSuccess = (data) => ({ type: actionTypes.duplicateCheckSuccess, ...data });
export const duplicateCheckError = (data) => ({ type: actionTypes.duplicateCheckError, ...data });
export const loadMorePondsSuccess = (data) => ({ type: actionTypes.loadMorePondsSuccess, ...data });
export const loadMorePondsError = (data) => ({ type: actionTypes.loadMorePondsError, ...data });
export const getAssignableUsersSuccess = (data) => ({ type: actionTypes.getAssignableUsersSuccess, ...data });
export const getAssignableUsersError = (data) => ({ type: actionTypes.getAssignableUsersError, ...data });
export const updatePondInSidebar = (pond) => {
  const count = document.getElementsByClassName(` brivity-nav-lead-count ${pond.id}-count`)[0];
  if (count) {
    const link = count.closest("a");
    link.innerText = pond.name;
    link.append(count);
  }
};
