import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { CalendarCheckSolidV6 } from "@shared/v2/Icomoon";
import Modal from "@shared/v2/Modal";
import BaseEvent from "./BaseEvent";

const Headline = ({ event }) => {
  const { agent_person: agentPerson, created_by_name: createdByName } = event;
  const from = `${agentPerson.first_name} ${agentPerson.last_name}`.trim();
  let interactionType = "updated";
  if (event.key === "interaction.create") interactionType = "scheduled";
  if (event.key === "interaction.complete") interactionType = "completed";

  if (createdByName !== null && createdByName.localeCompare(from)) {
    return (
      <div>
        <span className="tw-font-semibold">{createdByName}</span>
        {` ${interactionType} an appointment for `}
        <span className="tw-font-semibold">{from}</span>
      </div>
    );
  }
  if (createdByName === null) {
    return (
      <div>
        <span className="tw-font-semibold">an appointment was</span>
        {` ${interactionType} for ${from}`}
      </div>
    );
  }

  return (
    <div>
      <span className="tw-font-semibold">{from}</span>
      {` ${interactionType} an appointment`}
    </div>
  );
};

Headline.propTypes = {
  event: PropTypes.shape().isRequired,
};

const Details = ({ event }) => {
  const { appointment_type: appointmentType, comment, location, outcome } = event.object_attributes;
  return (
    <div>
      <div className="tw-font-semibold">Details:</div>
      <div className="details-row">
        Appointment Time: {moment(event.object_attributes.scheduled_at).format("LLL")}
      </div>
      {!!appointmentType && <div className="details-row">Appointment Type: {appointmentType}</div>}
      {!!outcome && <div className="details-row">Outcome: {outcome}</div>}
      {!!comment && <div className="details-row">Notes: {comment}</div>}
      {!!location && <div className="details-row">Location: {location}</div>}
    </div>
  );
};

Details.propTypes = {
  event: PropTypes.shape().isRequired,
};

const PublicActivityAppointmentEvent = ({ event }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => setShowModal(true)}
      onKeyDown={(e) => {
        if (["Enter", " "].includes(e.key)) {
          e.preventDefault();
          setShowModal(true);
        }
      }}
    >
      <BaseEvent
        icon={<CalendarCheckSolidV6 />}
        title={<Headline event={event} />}
        interactedAt={event.interacted_at_absolute}
      >
        <Details event={event} />
      </BaseEvent>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
        contentClassName="tw-max-w-[400px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
      >
        <Modal.Header title="Appointment" closeButton />
        <Modal.Body className="tw-flex tw-flex-col tw-gap-[12px]">
          <Headline event={event} />
          <Details event={event} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

PublicActivityAppointmentEvent.propTypes = {
  event: PropTypes.shape().isRequired,
};

export default PublicActivityAppointmentEvent;
