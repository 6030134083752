import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as creators from "../../actions/creators";
import Button from "../../../shared/v2/Button";
import Panel2Form from "./form";
import placeholderImg from "./placeholder.png";

const mapStateToProps = (state) => ({
  formData: state.addMarketReport.form2,
  report: state.addMarketReport.savedMarketReport,
});

const Panel2 = ({ formData, report, setForm2Data }) => {
  const openPreview = () => {
    window.open(report.preview_url, "_blank");
  };
  return (
    <div className="tw-flex tw-h-full tw-overflow-hidden">
      <div className="tw-flex-1 tw-bg-gray-5 tw-rounded-[8px] tw-relative tw-flex tw-flex-col tw-items-center tw-justify-center tw-overflow-auto">
        <img alt="Report Preview" className="tw-block tw-mx-auto" src={placeholderImg} />
        <h4 className="tw-my-[22px]">Your report is ready!</h4>
        <Button size="medium" schema="secondary" onClick={openPreview}>
          Preview Report
        </Button>
      </div>
      <Panel2Form
        setFormData={setForm2Data}
        formData={formData}
        report={report}
        streetAddress={report?.street_address}
      />
    </div>
  );
};

Panel2.propTypes = {
  report: PropTypes.shape({
    preview_url: PropTypes.string,
    street_address: PropTypes.string,
  }),
  formData: PropTypes.shape({}),
  setForm2Data: PropTypes.func,
};

Panel2.defaultProps = {
  report: null,
  formData: {},
  setForm2Data: null,
};

export default connect(mapStateToProps, creators)(Panel2);
