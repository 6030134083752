import React, { useState } from "react";

import Button from "@shared/v2/Button";
import Checkbox from "@shared/v2/Checkbox";
import Dropdown from "@shared/v2/Dropdown";
import { CircleInfoSolidV6 } from "@shared/v2/Icomoon";
import Modal from "@shared/v2/Modal";
import TextButton from "@shared/v2/TextButton";
import Tooltip from "@shared/v2/Tooltip";
import FilterPill from "@shared/v2/FilterPill";
import DatePicker from "@shared/v2/DatePicker";
import TextInput from "@shared/v2/TextInput";
import { formatPrice } from "../../SearchListings/components/helpers";
import {
  communicationOptions,
  communicationDateOptions,
  getDefaultFilters,
  toggleArrayValue,
  setField,
  communicationOutcomes,
} from "./filters";

const FiltersModal = ({ chosenFilters, onApply, closeModal, config }) => {
  const [filters, setFilters] = useState(mergeWithDefaults(chosenFilters, config.type));

  const onArrayValueChosen = (field, value) => {
    setFilters(toggleArrayValue(filters, field, value));
  };

  const changeField = (field, value) => {
    setFilters(setField(filters, field, value));
  };

  const applyFilters = () => {
    onApply({
      ...filters,
      communicationOutcomes: filters.communication === "call" ? filters.communicationOutcomes : [],
      communicationRange: filters.communication ? filters.communicationRange : null,
      lotSizeMin: parseFloat(filters.lotSizeMin) || "",
      lotSizeMax: parseFloat(filters.lotSizeMax) || "",
    });
  };

  const clearFilters = () => setFilters(mergeWithDefaults({}, config.type));

  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      dialogClassName="tw-w-full"
      contentClassName="tw-m-auto !tw-p-0 tw-max-h-[90vh] tw-flex tw-flex-col tw-max-w-[691px]"
      onHide={closeModal}
      show
    >
      <Modal.Header className="tw-m-32px" title={`Filter \u2014 ${config.typeName}`} closeButton />
      <Modal.Body className="tw-flex tw-flex-col tw-overflow-y-auto tw-px-32px tw-relative tw-gap-32px">
        <Section label="Exclude From Results">
          <div className="tw-flex tw-flex-col tw-gap-16px">
            <Checkbox
              label="Exclude properties with no phone numbers"
              checked={filters.excluded.includes("no_phone")}
              onChange={() => onArrayValueChosen("excluded", "no_phone")}
            />
            <Checkbox
              label="Exclude DNC numbers"
              checked={filters.excluded.includes("dnc")}
              onChange={() => onArrayValueChosen("excluded", "dnc")}
            />
          </div>
        </Section>
        <Section
          label="Contact History"
          tooltipText={`"Talked to" refers to the logged call outcome of "Talked To"`}
        >
          <div className="tw-flex tw-gap-12px">
            <Dropdown
              containerClassName="tw-w-full"
              label="Communication"
              isSearchable={false}
              options={communicationOptions}
              onChange={(e) => changeField("communication", e.value)}
              placeholder="Any"
              value={communicationOptions.find((c) => c.value === filters.communication)}
            />
            <Dropdown
              containerClassName="tw-w-full"
              label="Date Range"
              isSearchable={false}
              options={communicationDateOptions}
              onChange={(e) => changeField("communicationRange", e.value)}
              placeholder="All Time"
              value={communicationDateOptions.find((cd) => cd.value === filters.communicationRange)}
              isDisabled={!filters.communication}
            />
          </div>
          {filters.communication === "call" && (
            <div className="tw-flex tw-flex-wrap tw-gap-4px">
              {communicationOutcomes.map((co) => (
                <FilterPill
                  key={co.value}
                  selected={filters.communicationOutcomes.includes(co.value)}
                  onClick={() => onArrayValueChosen("communicationOutcomes", co.value)}
                >
                  {co.label}
                </FilterPill>
              ))}
            </div>
          )}
        </Section>
        {config.statuses.length ? (
          <Section label="Status">
            <div className="tw-flex tw-flex-wrap tw-gap-4px">
              {config.statuses.map((s) => (
                <FilterPill
                  key={s}
                  selected={filters.statuses.includes(s)}
                  onClick={() => onArrayValueChosen("statuses", s)}
                >
                  {s}
                </FilterPill>
              ))}
            </div>
          </Section>
        ) : null}
        <Section label="Property Types">
          <div className="tw-flex tw-flex-wrap tw-gap-4px">
            {config.propertyTypes.map((pt) => (
              <FilterPill
                key={pt}
                selected={filters.propertyTypes.includes(pt)}
                onClick={() => onArrayValueChosen("propertyTypes", pt)}
              >
                {pt}
              </FilterPill>
            ))}
          </div>
        </Section>
        <Section label={config.dateName} tooltipText={`Date the property ${config.dateDescription}`}>
          <div className="tw-flex tw-flex-col tw-gap-32px">
            <div className="tw-flex tw-gap-12px">
              <div className="tw-flex tw-flex-col tw-w-full tw-gap-8px">
                <span className="tw-text-14px tw-text-gray-50 tw-font-semibold">From:</span>
                <DatePicker
                  value={filters.statusDateMin}
                  onChange={(value) => changeField("statusDateMin", value)}
                  maxDate={filters.statusDateMax}
                />
              </div>
              <div className="tw-flex tw-flex-col tw-w-full tw-gap-8px">
                <span className="tw-text-14px tw-text-gray-50 tw-font-semibold">To:</span>
                <DatePicker
                  value={filters.statusDateMax}
                  onChange={(value) => changeField("statusDateMax", value)}
                  minDate={filters.statusDateMin}
                />
              </div>
            </div>
            <div className="tw-grid tw-grid-cols-2 tw-gap-y-32px tw-gap-x-12px">
              {config.typeName === "Preforeclosure" ? null : (
                <PriceRange minValue={filters.priceMin} maxValue={filters.priceMax} onChange={changeField} />
              )}
              <RangePair
                label="Bedrooms"
                name="beds"
                minValue={filters.bedsMin}
                maxValue={filters.bedsMax}
                onChange={changeField}
              />
              <RangePair
                label="Bathrooms"
                name="baths"
                minValue={filters.bathsMin}
                maxValue={filters.bathsMax}
                onChange={changeField}
              />
              <RangePair
                label="Square Feet"
                name="sqft"
                minValue={filters.sqftMin}
                maxValue={filters.sqftMax}
                onChange={changeField}
              />
              <RangePair
                label="Lot Size (Acres)"
                name="lotSize"
                minValue={filters.lotSizeMin}
                maxValue={filters.lotSizeMax}
                onChange={changeField}
                allowDecimal
              />
            </div>
          </div>
        </Section>
        <hr className="!tw-m-0" />
      </Modal.Body>
      <Modal.Footer className="tw-m-32px">
        <div className="tw-flex tw-gap-12px">
          <Button schema="tertiary" size="medium" onClick={closeModal}>
            CANCEL
          </Button>
          <TextButton className="tw-ml-auto" size="medium" onClick={clearFilters}>
            CLEAR ALL
          </TextButton>
          <Button schema="primary" size="medium" onClick={applyFilters}>
            APPLY
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default FiltersModal;

const Section = ({ label, tooltipText = "", children }) => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-12px">
      <Label label={label} tooltipText={tooltipText} />
      {children}
    </div>
  );
};

const Label = ({ label, tooltipText }) => (
  <div className="tw-flex tw-gap-6px">
    <span className="tw-text-14px tw-font-semibold tw-text-gray-75">{label}</span>
    {tooltipText && (
      <Tooltip
        trigger={
          <div className="tw-text-gray-50">
            <CircleInfoSolidV6 size="m" />
          </div>
        }
        content={<span>{tooltipText}</span>}
        innerClassName="tw-max-w-[220px]"
        placement="right"
      />
    )}
  </div>
);

const RangePair = ({ label, name, minValue, maxValue, onChange, allowDecimal = false }) => {
  const cleanValue = (name, value) => {
    const cleaned = value?.replace(allowDecimal ? /[^0-9.]/g : /\D/g, "");

    onChange(name, cleaned);
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-8px">
      <div className="tw-text-14px tw-text-gray-50 tw-font-semibold">{label}</div>
      <div className="tw-flex tw-gap-8px">
        <TextInput
          className="tw-w-full"
          placeholder="No Min"
          onChange={(e) => cleanValue(`${name}Min`, e.target?.value || null)}
          value={minValue}
        />
        <TextInput
          className="tw-w-full"
          placeholder="No Max"
          onChange={(e) => cleanValue(`${name}Max`, e.target?.value || null)}
          value={maxValue}
        />
      </div>
    </div>
  );
};

const PriceRange = ({ minValue, maxValue, onChange }) => {
  const formattedValue = (value) => formatPrice(value) || "";

  return (
    <RangePair
      label="Price Range"
      name="price"
      minValue={formattedValue(minValue)}
      maxValue={formattedValue(maxValue)}
      onChange={onChange}
    />
  );
};

const mergeWithDefaults = (existing, searchType) => {
  return {
    ...getDefaultFilters(searchType),
    ...existing,
  };
};
