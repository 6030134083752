import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Dropdown from "@shared/v2/Dropdown";

const LastViewedMarketReportSelect = ({
  isDisabled,
  lastViewedMarketReportRangeOptions,
  previousSelectedRange,
}) => {
  const [selectedOption, setSelectedOption] = useState(previousSelectedRange || null);

  useEffect(() => {
    if (isDisabled === true) {
      setSelectedOption(null);
    }
  }, [isDisabled]);

  return (
    <Dropdown
      label="Last Market Report View"
      id="last_viewed_market_report_filter"
      name="leads_filter[selected_last_viewed_market_report_filter][]"
      options={lastViewedMarketReportRangeOptions}
      placeholder="Select..."
      isClearable={false}
      onChange={setSelectedOption}
      isDisabled={isDisabled}
      value={selectedOption}
      menuShouldComeToFront
    />
  );
};

LastViewedMarketReportSelect.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  lastViewedMarketReportRangeOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  previousSelectedRange: PropTypes.string.isRequired,
};

export default LastViewedMarketReportSelect;
