import React from 'react';
import axios from 'axios';
import "chart.js/auto";
import { Bar } from 'react-chartjs-2';
import Dropdown from '@shared/v2/Dropdown';
import './LeadDNA.scss';
import countBy from 'lodash/countBy';

// TODO: make sure we don't have any potential nulls that can come back from the API and break shit
class LeadDNA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: [],
      primaryFilter: 'price',
      secondaryFilter: null,
      secondaryData: [],
      showSecondary: false,
      titleText: '',
      chartData: {
        labels: [],
        datasets: []
      }
    }
  }

  barColors = [
    ['rgba(89,196,196)', 'rgba(2,148,148,1)'], // light blue
    ['rgba(188,52,14)', 'rgba(127,35,9,1)'], // dark red
    ['rgba(244,179,80)', 'rgba(211,135,18,1)'], // yellow
    ['rgba(51,110,123)', 'rgba(19,46,51,1)'], // dark blue
    ['rgba(242,113,77)', 'rgba(190,68,34,1)'], // coral red
    ['rgba(179,179,179)', 'rgba(128,128,128,1)'] // gray
  ]

  colorClasses = [
    'light-blue',
    'dark-red',
    'yellow',
    'dark-blue',
    'coral-red',
    'gray',
  ]

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    axios.get(`/idx_data/property_stats/${this.props.uuid}`)
      .then(res => {
        this.setState({ stats: res.data.stats });
        this.buildDatasets(this.state.primaryFilter, res.data.stats, true);
      });
  };

  buildDatasets(filterKey, data, usePrimaryTitle) {
    const sortedArray = this.sortValues(filterKey, data);

    if (filterKey == 'price') {
      this.quartileRanges(filterKey, sortedArray, usePrimaryTitle);
    } else {
      this.getTopFive(filterKey, sortedArray, usePrimaryTitle);
    }
  }

  getTopFive(filterKey, sortedArray, usePrimaryTitle) {
    const quantities = countBy(sortedArray, filterKey);
    const arrayed = Object.keys(quantities).map(val => {
      return {[val]: quantities[val]};
    });
    let labelDescriptor, sorted;

    if (filterKey == 'bedrooms' || filterKey == 'bathrooms') {
      labelDescriptor = filterKey == 'bedrooms' ? 'bed' : 'bath';
      sorted = arrayed.sort((a, b) => {
        return Object.keys(a)[0] == Object.keys(b)[0] ? 0 : +(Object.keys(a)[0] > Object.keys(b)[0]) || -1;
      });
    } else {
      sorted = arrayed.sort((a, b) => {
        return Object.values(b)[0] == Object.values(a)[0] ? 0 : +(Object.values(b)[0] > Object.values(a)[0]) || -1;
      });
    }

    if (usePrimaryTitle) {
      this.setState({titleText: `Showing ${sortedArray.length} properties sorted by ${filterKey}`})
    }

    this.buildTopFive(filterKey, sorted, sortedArray, labelDescriptor);
  }

  buildTopFive(filterKey, sorted, sortedArray, labelDescriptor) {
    let chartData = {labels: [], datasets: []};
    let dataTotal = 0;
    let otherTotal = 0;
    let colors = [...this.barColors];
    let colorClasses = [...this.colorClasses];
    let color, colorClass;

    sorted.forEach((element, index) => {
      if (index < 5) {
        let filteredMeta = sortedArray.filter((el) => el[filterKey] == Object.keys(element)[0]);
        color = colors.shift();
        colorClass = colorClasses.shift();
        let labelName = filterKey == 'price' ? '$' + Math.round(Object.keys(element)[0]).toLocaleString() : Object.keys(element)[0];

        chartData.datasets.push({
          stack: '1',
          label: `<span class="color-text ${colorClass}">${labelName}${labelDescriptor == undefined ? '' : ' ' + labelDescriptor}</span> ${Object.values(element)[0]}`,
          color: colorClass,
          backgroundColor: color[0],
          hoverBackgroundColor: color[1],
          data: [Object.values(element)[0]],
          metaCollection: filteredMeta,
          secondaryTitle: `Showing ${filteredMeta.length} properties with ${Object.keys(element)[0]} ${filterKey}`,
          index: index
        })

        chartData.labels[index] = `<span class="color-text">${Object.keys(element)[0]}</span> ${Object.values(element)[0]}`;
        dataTotal += Object.values(element)[0];
      } else {
        let filteredMeta = sortedArray.filter((el) => el[filterKey] == Object.keys(element)[0]);
        color = colors.shift();
        colorClass = colorClasses.shift();
        otherTotal += Object.values(element)[0];

        if (chartData.datasets[5] === undefined) {
          chartData.datasets.push({
            stack: '1',
            label: `<span class="color-text ${colorClass}">Other</span> ${Object.values(element)[0]}`,
            color: colorClass,
            backgroundColor: color[0],
            hoverBackgroundColor: color[1],
            data: [Object.values(element)[0]],
            metaCollection: filteredMeta,
            secondaryTitle: `Showing ${filteredMeta.length} properties with other ${filterKey}`,
            index: index
          })
        } else {
          chartData.datasets[5].label = `<span class="color-text ${colorClass}">Other</span> ${otherTotal}`;
          chartData.datasets[5].data[0] += Object.values(element)[0];
          Array.prototype.push.apply(chartData.datasets[5].metaCollection, filteredMeta)
        }
        chartData.labels[5] = `<span class="color-text colorClass">Other</span> ${otherTotal}`;

        dataTotal += Object.values(element)[0];
      }
    });

    chartData.datasets.forEach((dataset, index) => {
      let percentage = Math.round((dataset.data[0] / dataTotal) * 100)

      dataset.data[0] = percentage;
      chartData.labels[index] += ` (${percentage}%)`;
      dataset.label += ` (${percentage}%)`;
    });

    this.setState({chartData});
  }

  quartileRanges(filterKey, sortedArray, usePrimaryTitle) {
    let q1, q3, iqr, maxAllowable, minAllowable, normalAndOutliers, rangeMultiplier;

    if (usePrimaryTitle) {
      this.setState({titleText: `Showing ${sortedArray.length} properties in all price ranges`})
    }

    if (sortedArray.length > 6) {
      //find quartiles
      if ((sortedArray.length / 4) % 1 === 0) {
        q1 = 1/2 * (sortedArray[(sortedArray.length / 4)][filterKey] + sortedArray[(sortedArray.length / 4) + 1][filterKey]);
        q3 = 1/2 * (sortedArray[(sortedArray.length * (3 / 4))][filterKey] + sortedArray[(sortedArray.length * (3 / 4)) + 1][filterKey]);
      } else {
        q1 = sortedArray[Math.floor(sortedArray.length / 4 + 1)][filterKey];
        q3 = sortedArray.length == 7 ? sortedArray[6][filterKey] : sortedArray[Math.ceil(sortedArray.length * (3 / 4) + 1)][filterKey];
      }

      iqr = q3 - q1;
      // High outliers are anything beyond the 3rd quartile + 1.5 * the inter-quartile range (IQR)
      // Low outliers are anything beneath the 1st quartile - 1.5 * IQR
      // (most common method according to http://mathworld.wolfram.com/Outlier.html)
      maxAllowable = q3 + iqr * 1.5;
      minAllowable = q1 - iqr * 1.5;

      // normal is the 0 element of the matrix, outliers or "other" is 1
      normalAndOutliers = [[],[]]
      sortedArray.forEach((value) => {
        if ((value[filterKey] >= minAllowable) && (value[filterKey] <= maxAllowable)) {
          normalAndOutliers[0].push(value)
        } else {
          normalAndOutliers[1].push(value)
        }
      });

      rangeMultiplier = (maxAllowable - minAllowable) / 5
    } else {
        this.getTopFive(filterKey, sortedArray, usePrimaryTitle);
        return true;
    }

    this.buildQuartileData(filterKey, normalAndOutliers, rangeMultiplier);
  }

  buildQuartileData(filterKey, normalAndOutliers, rangeMultiplier) {
    let chartData = {labels: [], datasets: []};
    let dataTotal = 0;
    let otherTotal = 0;
    let colors = [...this.barColors];
    let colorClasses = [...this.colorClasses];
    let color, colorClass;

    for (var index = 0; index <= 5; index++) {
      var dataset = {
        stack: '1',
        label: '',
        data: [0],
        metaCollection: [],
        secondaryTitle: '',
        index: index
      }

      if (index === 0) {
        dataset.data[0] = normalAndOutliers[0].reduce((acc, dat) => {
          if ((dat[filterKey] > 0) && (dat[filterKey] < rangeMultiplier)) {
            dataset.metaCollection.push(dat);
            return acc + 1;
          }
          return acc;
        }, 0);

        if (dataset.data[0] > 0) {
          color = colors.shift();
          colorClass = colorClasses.shift();
          dataset.backgroundColor = color[0];
          dataset.hoverBackgroundColor = color[1];
          dataset.color = colorClass;

          chartData.labels[index] = `<span class="color-text ${colorClass}">${Math.floor(rangeMultiplier).toLocaleString()} - ${Math.ceil(rangeMultiplier * 2).toLocaleString()}</span> ${dataset.data[0]}`
          dataset.label = `<span class="color-text ${colorClass}">$${Math.floor(rangeMultiplier).toLocaleString()} - $${Math.ceil(rangeMultiplier * 2).toLocaleString()}</span> ${dataset.data[0]}`
          dataset.secondaryTitle = `Showing ${dataset.metaCollection.length} properties in $${Math.floor(rangeMultiplier).toLocaleString()} - $${Math.ceil(rangeMultiplier * 2).toLocaleString()} range`,
          dataTotal += dataset.data[0];
        }
      } else if (index <= 3) {
        dataset.data[0] = normalAndOutliers[0].reduce((acc, dat) => {
          if ((dat[filterKey] >= rangeMultiplier * (index)) && (dat[filterKey] < rangeMultiplier * (index + 1))) {
            dataset.metaCollection.push(dat);
            return acc + 1;
          }
          return acc;
        }, 0);

        if (dataset.data[0] > 0) {
          color = colors.shift();
          colorClass = colorClasses.shift();
          dataset.backgroundColor = color[0];
          dataset.hoverBackgroundColor = color[1];
          dataset.color = colorClass;

          chartData.labels[index] = `<span class="color-text ${colorClass}">${Math.floor(rangeMultiplier * (index + 1)).toLocaleString()} - ${Math.ceil(rangeMultiplier * (index + 2)).toLocaleString()}</span> ${dataset.data[0]}`
          dataset.label = `<span class="color-text ${colorClass}">$${Math.floor(rangeMultiplier * (index + 1)).toLocaleString()} - $${Math.ceil(rangeMultiplier * (index + 2)).toLocaleString()}</span> ${dataset.data[0]}`
          dataset.secondaryTitle = `Showing ${dataset.metaCollection.length} properties in $${Math.floor(rangeMultiplier * (index + 1)).toLocaleString()} - $${Math.ceil(rangeMultiplier * (index + 2)).toLocaleString()} range`,
          dataTotal += dataset.data[0];
        }
      } else if (index == 4) {
        // here we need to include values that are equal to the top end of the multiplier
        dataset.data[0] = normalAndOutliers[0].reduce((acc, dat) => {
          if ((dat[filterKey] >= rangeMultiplier * (index)) && (dat[filterKey] <= rangeMultiplier * (index + 1))) {
            dataset.metaCollection.push(dat);
            return acc + 1;
          }
          return acc
        }, 0);

        if (dataset.data[0] > 0) {
          color = colors.shift();
          colorClass = colorClasses.shift();
          dataset.backgroundColor = color[0];
          dataset.hoverBackgroundColor = color[1];
          dataset.color = colorClass;

          chartData.labels[index] = `<span class="color-text ${colorClass}">${Math.floor(rangeMultiplier * (index + 1)).toLocaleString()} - ${Math.ceil(rangeMultiplier * (index + 2)).toLocaleString()}</span> ${dataset.data[0]}`
          dataset.label = `<span class="color-text ${colorClass}">$${Math.floor(rangeMultiplier * (index + 1)).toLocaleString()} - $${Math.ceil(rangeMultiplier * (index + 2)).toLocaleString()}</span> ${dataset.data[0]}`
          dataset.secondaryTitle = `Showing ${dataset.metaCollection.length} properties in $${Math.floor(rangeMultiplier * (index + 1)).toLocaleString()} - $${Math.ceil(rangeMultiplier * (index + 2)).toLocaleString()} range`,
          dataTotal += dataset.data[0];
        }
      } else {
        dataset.data[0] = normalAndOutliers[1].reduce((acc, dat) => {
          dataset.metaCollection.push(dat);
          return acc + 1;
        }, 0);

        if (dataset.data[0] > 0) {
          color = colors.shift();
          colorClass = colorClasses.shift();
          dataset.backgroundColor = color[0];
          dataset.hoverBackgroundColor = color[1];
          dataset.color = colorClass;

          chartData.labels[index] = `<span class="color-text ${colorClass}">Other</span> ${dataset.data[0]}`
          dataset.label = `<span class="color-text ${colorClass}">Other</span> ${dataset.data[0]}`
          dataset.secondaryTitle = `Showing ${dataset.metaCollection.length} properties with other range`,
          dataTotal += dataset.data[0];
        }
      }

      if (dataset.data[0] > 0) {
        chartData.datasets.push(dataset);
      }
    }

    chartData.datasets.forEach((dataset, index) => {
      let percentage = Math.round((dataset.data[0] / dataTotal) * 100)

      dataset.data[0] = percentage;
      chartData.labels[index] += ` (${percentage}%)`;
      dataset.label += ` (${percentage}%)`;
    });


    this.setState({chartData});
  }

  sortValues(sortKey, data) {
   const multiplier = 1;
   const sorted =  data.sort((a, b) => {
     const aVal = a[sortKey] ? a[sortKey] : 0;
     const bVal = b[sortKey] ?  b[sortKey] : 0;
     return aVal > bVal ? multiplier : (aVal < bVal ? -multiplier : 0);
   })

    return sorted;
  }

  filterPrimary(primaryKey) {
    this.setState({primaryFilter: primaryKey});
    this.buildDatasets(primaryKey, this.state.stats, this.state.titleText, true);
  }

  filterSecondary(secondaryKey) {
    this.buildSecondaryData(this.state.secondaryData, secondaryKey, this.state.titleText);
  }

  determineInitialSecondary() {
    if (this.state.primaryFilter == 'price') {
      return 'bedrooms';
    } else {
      return 'price';
    }
  }

  startSecondaryData(secondaryData, secondaryKey, title) {
    !this.state.showSecondary && this.buildSecondaryData(secondaryData, secondaryKey, title);
  }

  buildSecondaryData(secondaryData, secondaryKey, title) {
    this.setState({secondaryData, secondaryFilter: secondaryKey, showSecondary: true, titleText: title});
    this.buildDatasets(secondaryKey, secondaryData, false);
  }

  closeSecondary = () => {
    this.setState({showSecondary: false, titleText: this.state.titleText});
    this.filterPrimary(this.state.primaryFilter)
  }

  render() {
    const options = {
      indexAxis: 'y',
      maintainAspectRatio: false,
      title: {
        display: true,
        text: this.state.titleText,
        position: 'top',
        fontSize: 12,
        fontFamily: 'Open Sans',
        fontStyle: 'normal'
      },
      barPercentage: 1,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          titleFont: {
            family: 'Open Sans',
            size: 14,
            style: 'normal'
          },
          titleColor: '#808080',
          enabled: !this.state.showSecondary,
          backgroundColor: 'white',
          borderColor: 'gray',
          borderWidth: 1,
          titleMarginBottom: 0,
          cornerRadius: 0,
          callbacks: {
            label: (tooltipItem, data) => {
              return '';
            },
            title: (tooltipItem, data) => {
              return 'click for more insights';
            }
          }
        },
      },
      interaction: {
        mode: 'index'
      },
      scales: {
        x: {
          stacked: true,
          border: {
            display: false
          },
          grid: {
            display: false,
          },
          ticks: {
            display: false,
            max: 100
          },
        },
        y: {
          stacked: true,
          border: {
            display: false
          },
          grid: {
            display: false,
          },
          ticks: {
            display: false
          },
          barThickness: 40,
        }
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      }
    }

    const filterValues = [
      {label: 'Price', value: 'price', disabled: this.state.primaryFilter == 'price' && this.state.showSecondary == true},
      // {label: 'Type', value: 'type'},
      {label: 'Beds', value: 'bedrooms', disabled: this.state.primaryFilter == 'bedrooms' && this.state.showSecondary == true},
      {label: 'Baths', value: 'bathrooms', disabled: this.state.primaryFilter == 'bathrooms' && this.state.showSecondary == true},
      // {label: 'SqFt', value: 'sqft'},
      {label: 'City', value: 'city', disabled: this.state.primaryFilter == 'city' && this.state.showSecondary == true},
      // {label: 'Area', value: 'area'},
      // {label: 'Neighborhood', value: 'neighborhood'},
      {label: 'Zip Code', value: 'zip', disabled: this.state.primaryFilter == 'zip' && this.state.showSecondary == true}
    ]

    const {stats} = this.state

    return (
      <div id='lead-dna'>
        <div className="row interaction-row">
          <div className="col-xs-12">
            <div className="white-panel">
              <div className='row'>
                <div className='form-inline'>
                  <div className='form-group'>
                    <label className='inline-label'>Show distribution by: </label>
                    <Dropdown
                      containerClassName='leaddna-filter-dropdown'
                      value={this.state.primaryFilter}
                      options={filterValues}
                      isSearchable={false}
                      isClearable={false}
                      isDisabled={this.state.showSecondary}
                      menuShouldScrollIntoView
                      onChange={opt => this.filterPrimary(opt.value, stats)}
                    />
                  </div>

                  {this.state.showSecondary &&
                    <div className='form-group'>
                      <label className='inline-label'>Of that, show by: </label>
                      <div id="secondary-select-wrapper">
                        <div id="secondary-close-box" onClick={this.closeSecondary}>
                          <i className="fa fa-times-circle"></i>
                        </div>
                        <Dropdown
                          containerClassName='secondary-filter-dropdown leaddna-filter-dropdown'
                          value={this.state.secondaryFilter}
                          options={filterValues}
                          isSearchable={false}
                          isClearable={false}
                          menuShouldScrollIntoView
                          onChange={opt => this.filterSecondary(opt.value)}
                        />
                      </div>
                    </div>
                  }
                </div>
              </div>

              <div className='row'>
                <div className='col-xs-12'>
                  {stats.length > 0 ?
                  <div>
                    <div className="chart-container">
                      <Bar
                        data={this.state.chartData}
                        height={14}
                        options={options}
                        getDatasetAtEvent={dataset => this.startSecondaryData(this.state.chartData.datasets[dataset[0]._datasetIndex].metaCollection, this.determineInitialSecondary(), this.state.chartData.datasets[dataset[0]._datasetIndex].secondaryTitle)}
                      />
                    </div>
                    <ChartLegend datasets={this.state.chartData.datasets} />
                  </div>
                  : null}
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
    )
  }
};

export default LeadDNA;

class ChartLegend extends React.Component {
  render() {
    const datasets = _.map(this.props.datasets, function (ds, index) {
      return <li key={ds.label} className={`${ds.color}`} dangerouslySetInnerHTML={{__html: ds.label}}></li>;
    });

    return (
      <ul className={"leaddna-legend" }>
        { datasets }
      </ul>
    );
  }
};
