import React, { useEffect, useState } from "react";
import { bool, func, string } from "prop-types";

import Dropdown from "@shared/v2/Dropdown";
import { CheckboxGroupHeading, CheckboxOption } from "@shared/v2/Dropdown/custom/MultiComponents";
import { useEventListenerAction } from "@shared/hooks/useEventListenerAction";

// This component is used to create a dropdown with multi select and controlled removable options *REUSABLE
const DropdownWithMultiCtrlledRemovable = ({
  ctrlName,
  options,
  selectedOptions,
  removablePrefix,
  placeHolderTxt,
  dropdownClassName,
  dropdownMultiValueClassName,
  placeholderNoSelected,
  isSearchable,
  isRequired,
  onChange,
  label,
}) => {
  const [withMultiValue, setWithMultiValue] = useState([]);
  useEventListenerAction("#clear-all-modal-filters", () => setWithMultiValue([]));

  useEffect(() => {
    setWithMultiValue(selectedOptions);
  }, [selectedOptions]);

  return (
    <Dropdown
      isMulti
      placeholder={
        withMultiValue.length > 0 ? `${withMultiValue.length}${placeHolderTxt}` : placeholderNoSelected
      }
      options={options}
      value={withMultiValue}
      label={label}
      blurInputOnSelect={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      className={dropdownClassName}
      dropdownMultiValueClassName={dropdownMultiValueClassName}
      onChange={(newValue) => {
        setWithMultiValue(newValue);
        onChange(newValue);
      }}
      removablePrefix={removablePrefix}
      components={{
        GroupHeading: CheckboxGroupHeading,
        Option: CheckboxOption,
      }}
      isSearchable={isSearchable}
      name={ctrlName}
      backspaceRemovesValue={false}
      isRequired={isRequired}
      preventOptionUpdate
      menuShouldComeToFront
    />
  );
};

export default DropdownWithMultiCtrlledRemovable;

DropdownWithMultiCtrlledRemovable.propTypes = {
  removablePrefix: string,
  placeHolderTxt: string,
  label: string,
  dropdownClassName: string,
  isSearchable: bool,
  onChange: func,
  isRequired: bool,
  dropdownMultiValueClassName: string,
  placeholderNoSelected: string,
};

DropdownWithMultiCtrlledRemovable.defaultProps = {
  removablePrefix: "",
  placeHolderTxt: "",
  label: "",
  dropdownClassName: "",
  options: [],
  selectedOptions: [],
  isSearchable: true,
  onChange: () => {},
  isRequired: false,
  dropdownMultiValueClassName: "!tw-text-[12d] !tw-font-normal",
  placeholderNoSelected: "Select",
};
