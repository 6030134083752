export const MODAL_ANIMATION_DURATION_MS = 400;

export const containerAnimationStyles = (duration) => ({
  transition: `opacity ${duration}ms ease-in-out`,
});

export const modalAnimationStyles = (duration) => ({
  transition: `transform ${duration}ms ease-out`,
});

export const containerTransitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export const modalTransitionStyles = {
  entering: { transform: "translate3d(0, 0, 0)" },
  entered: { transform: "translate3d(0, 0, 0)" },
  exiting: { transform: "translate3d(0, 100vh, 0)" },
  exited: { transform: "translate3d(0, 100vh, 0)" },
};
