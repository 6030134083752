import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { format } from "date-fns";
import Avatar from "@shared/v2/Avatar";
import IconButton from "@shared/v2/IconButton";
import Tooltip from "@shared/v2/Tooltip";
import { Popover } from "@shared/v2/Popover";
import {
  BoxArchiveSolidV6,
  CopySolidV6,
  EllipsisSolidV6,
  EyeSolidV6,
  LinkSolidV6,
  PencilSolidV6,
  ShareSolidV6,
} from "@shared/v2/Icomoon";
import { TD, TR } from "../../Reports/components/BusinessDashboard/Table/Table";
import PostStatus from "./PostStatus";
import { archivePost, setArchivePost, setSharePost } from "../../reducers/websiteReducer/websiteReducer";
import { useWebsiteIDXUrl } from "../../reducers/websiteReducer/selectors";
import { setSnackbar } from "../../reducers/snackbarReducer";

const ShareTooltip = ({ content, show, trigger }) => {
  if (show) {
    return <Tooltip placement="left" trigger={trigger} content={content} />;
  }
  return trigger;
};

ShareTooltip.propTypes = {
  content: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  trigger: PropTypes.element.isRequired,
};

const PostRow = ({ post, setDuplicatePost }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const websiteIDXUrl = useWebsiteIDXUrl();

  return (
    <TR className="tw-h-[64px]">
      <TD border="tw-border-b-[1px] tw-border-r-0">
        {!post.featuredImageUrl && (
          <div className="tw-w-[52px] tw-h-[40px] tw-object-cover tw-rounded-[4px] tw-bg-gray-50" />
        )}
        {post.featuredImageUrl && (
          <img
            className="tw-w-[52px] tw-max-w-[52px] tw-h-[40px] tw-object-cover tw-rounded-[4px]"
            src={post.featuredImageUrl}
            alt="Featured"
          />
        )}
      </TD>
      <TD border="tw-border-b-[1px] tw-border-r-0">
        <div className="tw-whitespace-normal tw-line-clamp-2 tw-min-w-[200px]">{post.title}</div>
      </TD>
      <TD border="tw-border-b-[1px] tw-border-r-0">
        <div className="tw-whitespace-normal tw-line-clamp-2">
          {post.categories?.map((c) => c.category).join(", ") || (
            <span className="tw-text-gray-50">Uncategorized</span>
          )}
        </div>
      </TD>
      <TD border="tw-border-b-[1px] tw-border-r-0">
        <div className="tw-whitespace-normal tw-line-clamp-2">
          {post.tags?.map((t) => t.tag).join(", ") || <span className="tw-text-gray-50">No tags</span>}
        </div>
      </TD>
      <TD border="tw-border-b-[1px] tw-border-r-0">
        <div className="tw-flex tw-justify-center">
          <PostStatus post={post} />
        </div>
      </TD>
      <TD border="tw-border-b-[1px] tw-border-r-0">
        {post.datePublished && format(post.datePublished, "MMM. dd, yyyy")}
      </TD>
      <TD border="tw-border-b-[1px] tw-border-r-0">{format(post.dateCreated, "MMM. dd, yyyy")}</TD>
      <TD border="tw-border-b-[1px] tw-border-r-0">
        <div className="tw-flex tw-justify-center">
          <Tooltip
            trigger={
              <Avatar
                size="medium"
                src={post.author.avatar}
                alt={`${post.author.first_name} ${post.author.last_name}`}
              />
            }
            content={`${post.author.first_name} ${post.author.last_name}`}
          />
        </div>
      </TD>
      <TD border="tw-border-b-[1px] tw-border-r-0" className="tw-text-end">
        <Popover placement="bottom-end">
          <Popover.Trigger>
            <IconButton schema="tertiary">
              <EllipsisSolidV6 />
            </IconButton>
          </Popover.Trigger>
          <Popover.Content>
            <Popover.Item icon={<PencilSolidV6 />} onClick={() => navigate(`/post/${post.uuid}`)}>
              Edit
            </Popover.Item>
            {!post.isArchived && (
              <ShareTooltip
                show={!post.isPublished}
                content="Publish this post to share it"
                trigger={
                  <Popover.Item
                    className="tw-w-full"
                    icon={<ShareSolidV6 />}
                    disabled={!post.isPublished}
                    onClick={() => dispatch(setSharePost(post))}
                  >
                    Share
                  </Popover.Item>
                }
              />
            )}
            <Popover.Item icon={<CopySolidV6 />} onClick={() => setDuplicatePost(post)}>
              Duplicate
            </Popover.Item>
            {!post.isArchived && (
              <>
                <ShareTooltip
                  show={!post.isPublished}
                  content="Publish to copy post link"
                  trigger={
                    <Popover.Item
                      icon={<LinkSolidV6 />}
                      disabled={!post.isPublished}
                      onClick={() =>
                        window.copyTextToClipboard(`${websiteIDXUrl}/post/${post.slug}`).then(() =>
                          dispatch(
                            setSnackbar({
                              show: true,
                              position: "top",
                              text: "Link copied",
                              schema: "executed",
                              timeout: 5000,
                              closeOnTimeout: () => dispatch(setSnackbar({ show: false })),
                            }),
                          ),
                        )
                      }
                    >
                      Copy Link
                    </Popover.Item>
                  }
                />
                <ShareTooltip
                  show={!post.isPublished}
                  content="Publish to view your post"
                  trigger={
                    <Popover.Item
                      icon={<EyeSolidV6 />}
                      disabled={!post.isPublished}
                      onClick={() => window.open(`${websiteIDXUrl}/post/${post.slug}`, "_blank")}
                    >
                      View Post
                    </Popover.Item>
                  }
                />
                <Popover.Item icon={<BoxArchiveSolidV6 />} onClick={() => dispatch(setArchivePost(post))}>
                  Archive
                </Popover.Item>
              </>
            )}
            {post.isArchived && (
              <Popover.Item icon={<BoxArchiveSolidV6 />} onClick={() => dispatch(archivePost(post))}>
                Unarchive
              </Popover.Item>
            )}
          </Popover.Content>
        </Popover>
      </TD>
    </TR>
  );
};

PostRow.propTypes = {
  post: PropTypes.shape().isRequired,
  setDuplicatePost: PropTypes.func.isRequired,
};

export default PostRow;
