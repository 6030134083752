import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Tooltip from "@shared/v2/Tooltip";
import * as actions from "../actions/default";
import Button from "./Button";

const mapStateToProps = (state) => ({
  // notificationsOn: state.leadPonds.leadPondsNotificationsOn,
  isAdmin: state.leadPonds.isAdmin,
});

const PageHeader = ({
  title = "",
  copy = "",
  buttonTitle = "",
  isAdmin,
  openPondModal,
  isDisabled = false,
  buttonTooltipContent = null,
}) => {
  const buttonElement = (
    <Button
      data-cy="lp-header-action"
      title={buttonTitle}
      icon="fa-plus"
      onClick={() => {
        openPondModal();
      }}
      disabled={isDisabled}
    />
  );

  return (
    <section className="tw-sticky tw-left-0 tw-py-20 tw-px-16 tw-mb-8 tw-bg-white" data-cy="lp-header">
      <div className="tw-flex tw-mb-8">
        <h2 className="tw-grow">{title}</h2>
        {isAdmin && (
          <>
            {isDisabled && buttonTooltipContent ? (
              <Tooltip placement="top" multiline trigger={buttonElement} content={buttonTooltipContent} />
            ) : (
              buttonElement
            )}
          </>
        )}
      </div>
      <p className="tw-pb-8">{copy}</p>
      {/* <p>
        {notification.title}
        <a href={notification.link} className="tw-pl-4 tw-text-teal">
          Manage
          <i className="fa fa-chevron-right tw-pl-4" />
        </a>
      </p> */}
    </section>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  openPondModal: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  buttonTooltipContent: PropTypes.string,
};

PageHeader.defaultProps = {
  isDisabled: false,
  buttonTooltipContent: null,
};

export default connect(mapStateToProps, actions)(PageHeader);
