/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const EmailDeliveryInvalid = (props) => (
  <Icon {...props} name="Email Delivery Invalid">
    <path d="M14.5 2h-13A1.503 1.503 0 0 0 0 3.5v9A1.503 1.503 0 0 0 1.5 14h13a1.503 1.503 0 0 0 1.5-1.5v-9A1.503 1.503 0 0 0 14.5 2zm0 2.775c-.605.5-1.5 1.22-3.285 2.61a.247.247 0 0 0-.031.376l.705.74a.253.253 0 0 0 .335 0l2.275-1.8v5.8h-13v-5.8l2.31 1.825a.252.252 0 0 0 .335 0l.71-.725a.248.248 0 0 0-.025-.374c-1.795-1.425-2.72-2.155-3.33-2.65V3.502h13v1.275z" />
    <path d="m5.53 10.16.55.55a.295.295 0 0 0 .41 0l1.5-1.5 1.5 1.5a.302.302 0 0 0 .415 0l.55-.55a.297.297 0 0 0 0-.41l-1.5-1.5 1.5-1.5a.293.293 0 0 0 0-.415l-.55-.545c-.055-.055-.13-.085-.207-.085s-.152.031-.208.085l-1.5 1.5-1.5-1.5a.287.287 0 0 0-.316-.064.302.302 0 0 0-.094.064l-.55.545a.302.302 0 0 0 0 .415l1.5 1.5-1.5 1.5a.295.295 0 0 0 0 .41z" />
  </Icon>
);

export default EmailDeliveryInvalid;
